import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./header.scss";
import UserContext from "../../context/user/user-context";
import NotificationComponent from "./NotificationComponent";
import UserDropdown from "./UserDropdown";
import LangauageDropdown from "./LangauageDropdown";
import { useTranslation } from "react-i18next";

export default function Header({ starter, showSearch }) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [mobileToggle, setMobileToggle] = useState(false);
  const [languageToggle, setLanguageToggle] = useState(false);
  const [profileToggle, setProfileToggle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [noticeToggle, setNoticeToggle] = useState(false);
  const [searchType, setSearchType] = useState("Search here");
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useContext(UserContext);
  const handelLanguageToggle = () => {
    setLanguageToggle(!languageToggle);
    setProfileToggle(false);
    setSearchToggle(false);
    setNoticeToggle(false);
  };
  const handelProfileToggle = () => {
    setProfileToggle(!profileToggle);
    setLanguageToggle(false);
    setSearchToggle(false);
    setNoticeToggle(false);
  };
  const handelSearchToggle = () => {
    setSearchToggle(!searchToggle);
    setLanguageToggle(false);
    setProfileToggle(false);
    setNoticeToggle(false);
  };
  const handelNoticeToggle = () => {
    setNoticeToggle(!noticeToggle);
    setSearchToggle(false);
    setLanguageToggle(false);
    setProfileToggle(false);
  };

  // handle searchtype
  const handleSearchType = (type) => {
    setSearchType(type);
    handelSearchToggle();
  };

  // handle search
  const handleSearch = () => {
    const path = location.pathname;
    if (searchType === "Search here") {
      navigate(`${path}?search=${searchQuery}`);
    } else {
      navigate(`members?search=${searchQuery}`);
    }
  };

  // clear search query when go to other page
  useEffect(() => {
    if (location.pathname !== window.location.pathname) {
      setSearchQuery("");
    }
  }, [location]);

  return (
    <header className="cs-header">
      <div className="cs-header_left">
        <Link to="/" className="cs-header_logo">
          <img src="/images/logo.svg" alt="Logo" />
        </Link>
        {starter ? (
          ""
        ) : (
          <ul className="cs-nav">
            <li>
              <NavLink to="/settings/modify-membership-tiers">
                {t("header_nav_item_1")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings/earning-dashboard">
                {t("header_nav_item_2")}
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings/my-profile">{t("settings")}</NavLink>
            </li>
          </ul>
        )}
      </div>
      <div className="cs-header_right">
        {starter ? (
          <ul className="cs-nav_list forgot_pass_nav">
            <li>
              <div className="cs-account_check">
                <span>{t("dont_have_any_account")}</span>
                <Link to="/auth/register" className="cs-logout_btn">
                  {t("register_now")}
                </Link>
              </div>
            </li>
            <li>
              <LangauageDropdown
                handelLanguageToggle={handelLanguageToggle}
                languageToggle={languageToggle}
                close={setLanguageToggle}
              />
            </li>
          </ul>
        ) : (
          <ul className="cs-nav_list">
            {showSearch && (
              <li>
                <div
                  className={
                    mobileToggle
                      ? "cs-mobile_toggle_wrap active"
                      : "cs-mobile_toggle_wrap"
                  }
                >
                  <span
                    className="cs-mobile_toggle_btn"
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <Icon icon="material-symbols:search" />
                  </span>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSearch();
                    }}
                    className="cs-header_search cs-dropdown_wrap"
                  >
                    <span
                      className="cs-search_dropdown_btn"
                      onClick={handelSearchToggle}
                    >
                      <svg
                        style={{
                          transform: `${
                            searchToggle ? "rotate(180deg)" : "rotate(0deg)"
                          }`,
                        }}
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.999648 1.44758L6.10285 6.55078L11.2061 1.44758"
                          stroke="currentColor"
                          strokeWidth="1.70107"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <button className="cs-header_search_btn">
                      <Icon icon="material-symbols:search" />
                    </button>
                    <input
                      type="text"
                      placeholder={
                        searchType === "Search here"
                          ? t("header_search_here")
                          : t("header_serch_my_member")
                      }
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    {searchToggle && (
                      <div className="cs-dropdown cs-size_lg cs-notice_dropdown">
                        <ul className="cs-mp0 cs-medium">
                          <li>
                            <span
                              onClick={() => handleSearchType("Search here")}
                            >
                              <>
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_5339_24700)">
                                    <path
                                      d="M0 0C4.325 4.25 1.675 11.75 6 16C9.175 13.9 12.825 11.85 16 9.75C14.075 7.875 13.925 5.15 13.5 2.75C11.525 2 9.35 1 6.25 0H0Z"
                                      fill="#D9E3E8"
                                    />
                                    <path
                                      d="M3.25 2.24922C3.35 2.44922 3.45 2.64922 3.525 2.87422L7.575 2.12422C7.5 1.94922 7.425 1.77422 7.325 1.57422C6 1.82422 4.625 2.02422 3.25 2.24922Z"
                                      fill="#4B9DDC"
                                    />
                                    <path
                                      d="M4.10005 4.69844L8.07505 3.72344C8.02505 3.52344 8.00005 3.32344 7.92505 3.14844C6.60005 3.44844 5.25005 3.74844 3.92505 4.04844C4.00005 4.24844 4.05005 4.47344 4.10005 4.69844Z"
                                      fill="#4B9DDC"
                                    />
                                    <path
                                      d="M4.40005 6.62383C7.02505 5.79883 9.67505 4.97383 12.3 4.17383C12.275 3.99883 12.25 3.82383 12.225 3.67383C9.57505 4.42383 6.95005 5.19883 4.30005 5.94883C4.35005 6.14883 4.37505 6.37383 4.40005 6.62383Z"
                                      fill="#4B9DDC"
                                    />
                                    <path
                                      opacity="0.5"
                                      d="M6.25 0C7.475 1.2 6.975 4.475 7.25 6C9 5.525 11.75 3.225 13.5 2.75C11.525 2 9.35 1 6.25 0Z"
                                      fill="#333333"
                                    />
                                    <path
                                      d="M5.5249 12.8254C5.6249 13.0254 5.6999 13.2504 5.8249 13.4504C7.0749 12.7504 8.3249 12.0254 9.5749 11.3254C9.4749 11.1504 9.3999 10.9754 9.3249 10.7754C8.0499 11.4754 6.7999 12.1504 5.5249 12.8254Z"
                                      fill="#4B9DDC"
                                    />
                                    <path
                                      d="M6.25 0C7.475 1.2 7.9 2.65 8.175 4.175C9.925 3.7 11.75 3.225 13.5 2.75C11.525 2 9.35 1 6.25 0Z"
                                      fill="#D9E3E8"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5339_24700">
                                      <rect
                                        width={16}
                                        height={16}
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {t("you_can_search_on_current_page")}
                              </>
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() =>
                                handleSearchType("Search my member")
                              }
                            >
                              <>
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_5339_24712)">
                                    <path
                                      d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8.54883 3.4668C10.6893 3.4668 12.4238 5.20235 12.4238 7.34277C12.4238 9.48321 10.6893 11.2178 8.54883 11.2178C7.90108 11.2178 7.29059 11.0593 6.75391 10.7783C6.75445 10.7808 6.75532 10.7837 6.75585 10.7861L5.00781 12.5332L3.57617 11.1006L5.26172 9.41407C5.26451 9.41281 5.26772 9.41235 5.27051 9.41113C4.89201 8.81256 4.67285 8.10329 4.67285 7.34277C4.67285 5.20233 6.40839 3.4668 8.54883 3.4668ZM8.54883 5.18848C7.35932 5.18848 6.39453 6.15327 6.39453 7.34277C6.39453 8.53228 7.35932 9.49609 8.54883 9.49609C9.73835 9.49609 10.7021 8.53228 10.7021 7.34277C10.7021 6.15327 9.73835 5.18848 8.54883 5.18848Z"
                                      fill="#9FD4FC"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5339_24712">
                                      <rect
                                        width={16}
                                        height={16}
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                {t("search_my_member")}
                              </>
                            </span>
                          </li>
                        </ul>
                      </div>
                    )}
                  </form>
                </div>
              </li>
            )}

            <li>
              <NotificationComponent
                closeNotification={setNoticeToggle}
                unreadCount={10}
                handelNoticeToggle={handelNoticeToggle}
                noticeToggle={noticeToggle}
              />
            </li>
            <li className="cs-hide_mobile">
              <span className="cs-header_label">{t("creator")}</span>
            </li>
            <li>
              <UserDropdown
                user={user}
                handelProfileToggle={handelProfileToggle}
                profileToggle={profileToggle}
                setProfileToggle={setProfileToggle}
              />
            </li>
            <li>
              <LangauageDropdown
                handelLanguageToggle={handelLanguageToggle}
                languageToggle={languageToggle}
                close={setLanguageToggle}
              />
            </li>
          </ul>
        )}
      </div>
    </header>
  );
}
