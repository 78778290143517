import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteTradeDiaryTemplate,
  getTradeDiaryTemplateList,
  tradeDiaryTemplateUpdate,
} from '../../../api/services/tradeDiary';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import PaginateButton from '../../../components/Common/PaginateButton';
import Spacing from '../../../components/Spacing';
import { defaultHasMoreCount } from '../../../config/const';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import EmptyData from '../../../components/Common/EmptyData';
import { useTranslation } from 'react-i18next';

export default function UsualTemplates({
  setUpdatedTradeDiary,
  setModal2,
  refresh,
}) {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [modal, setModal] = useState(false);
  const [editedTemplate, setEditedTemplate] = useState({});
  const [template, setTemplate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const searchQuery = searchParams.get('search') ?? '';

  const formSchema = Yup.object().shape({
    tradeDairyTypeId: Yup.number(),
    stock_name: Yup.string().required('The ticker name field is required'),
    stock_price: Yup.string().nullable(),
    note: Yup.string().nullable(),
    stop_loss: Yup.string(),
    visibility: Yup.string(),
    tiers: Yup.mixed(),
    post_id: Yup.string(),
    template_name: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  // get template
  const getTemplate = async () => {
    setLoading(true);
    const res = await getTradeDiaryTemplateList(1, 5, searchParams);
    setTemplate(res.data.data);
    setCount(res.data.count);
    setLoading(false);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getTradeDiaryTemplateList(page, 5, searchQuery);
    setTemplate([...template, ...res.data.data]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // handle update
  const handleUpdate = async data => {
    if (!data.stock_price) {
      data.stock_price = 'Now';
    }
    setUpdateLoading(true);
    await tradeDiaryTemplateUpdate(data);
    setUpdateLoading(false);
    refresh();
    getTemplate();
    setModal(false);
  };

  // handle delete
  const handleDelete = async post_id => {
    Swal.fire({
      title: t('are_you_sure'),
      text: t('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_delete_it'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await deleteTradeDiaryTemplate(post_id);
          if (res.data.success === 1) {
            Swal.fire(t('deleted'), t('your_post_has_been_deleted'), 'success');
            setModal(false);
            getTemplate();
            refresh();
            setPage(2);
          }
        } catch (e) {}
      }
    });
  };
  useEffect(() => {
    getTemplate();
  }, [searchParams]);
  return (
    <>
      <div className="cs-container_md">
        <Spacing lg="20" md="20" />
        <h2 style={{ fontSize: '30px' }}>{t('my_usual_template')}</h2>
        <Spacing lg="20" md="20" />
        <ul
          className={`cs-card_14_list cs-mp0 ${
            loading ? 'cs-loading_wrap' : ''
          }`}
        >
          {loading && (
            <>
              <Spacing lg="50" md="50" />
              <LoadingSpinner />
            </>
          )}
          {template.map(item => (
            <li>
              <div className="cs-card cs-style14">
                <div className="cs-card_head">
                  <div className="cs-card_left">
                    <h2>{item.template_name}</h2>
                    <span
                      className="cs-card_laben"
                      style={{
                        background: item.trade_dairy_color_background,
                        color: item.trade_dairy_color_text,
                      }}
                    >
                      {item.trade_dairy_name}
                    </span>
                  </div>
                  <div className="cs-card_btns">
                    <button
                      className="cs-card_btn cs-color1"
                      type="button"
                      onClick={() => {
                        setModal(true);
                        setValue('stock_name', item.stock_name);
                        setValue('stock_price', item.stock_price);
                        setValue('note', item.note);
                        setValue('stop_loss', item.stop_loss);
                        setValue('tiers', item.tiers);
                        setValue('visibility', item.visibility);
                        setValue('tradeDairyTypeId', item.tradeDairyTypeId);
                        setValue('post_id', item.id);
                        setValue('template_name', item.template_name);
                        setEditedTemplate(item);
                      }}
                    >
                      {t('edit')}
                    </button>
                    <button
                      className="cs-card_btn cs-color2"
                      type="button"
                      onClick={() => handleDelete(item.id)}
                    >
                      {t('delete')}
                    </button>
                  </div>
                </div>
                <div
                  className="cs-card_body"
                  onClick={() => {
                    setUpdatedTradeDiary(item);
                    setModal2(false);
                  }}
                >
                  <div className="cs-row">
                    <div className="cs-col_6">
                      <label>{t('stock_name')} *</label>
                      <input
                        type="text"
                        className="cs-form_field"
                        value={item.stock_name}
                        placeholder={t('enter_stock_name')}
                        disabled
                      />
                      <Spacing lg="20" md="20" />
                    </div>
                    <div className="cs-col_6">
                      <label>{t('stock_price')}</label>
                      <input
                        type="text"
                        className="cs-form_field"
                        placeholder={t('enter_stock_price')}
                        value={item.stock_price}
                        disabled
                      />
                      <Spacing lg="20" md="20" />
                    </div>
                    <div className="cs-col_6">
                      <label>{t('note')}</label>
                      <input
                        type="text"
                        className="cs-form_field"
                        placeholder={t('enter_note')}
                        value={item.note}
                        disabled
                      />
                      <Spacing lg="20" md="20" />
                    </div>
                    <div className="cs-col_6">
                      <label>{t('stop_loss')}</label>
                      <input
                        type="text"
                        className="cs-form_field"
                        placeholder={t('enter_stop_loss')}
                        value={item.stop_loss}
                        disabled
                      />
                      <Spacing lg="20" md="20" />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
          {!template.length && !loading ? <EmptyData /> : null}
        </ul>
        <Spacing lg="20" md="20" />
        {count > defaultHasMoreCount && (
          <PaginateButton
            loading={paginateLoading}
            hasMore={hasMore}
            onClick={paginate}
          />
        )}
        <Spacing lg="20" md="20" />
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style3"
                style={{ maxWidth: '1030px' }}
              >
                <button
                  className="cs-close_modal"
                  onClick={() => setModal(false)}
                >
                  <Icon icon="mdi:close-circle-outline" />
                </button>
                <form
                  className="cs-modal_box_in"
                  onSubmit={handleSubmit(handleUpdate)}
                >
                  <Spacing lg="10" md="10" />
                  <h2 style={{ fontSize: '30px' }}>
                    {t('edit_usual_template')}
                  </h2>
                  <Spacing lg="10" md="10" />
                  <div className="cs-card cs-style14">
                    <div className="cs-card_head">
                      <div className="cs-card_left">
                        <h2>{editedTemplate.template_name}</h2>
                        <span
                          className="cs-card_laben"
                          style={{
                            background:
                              editedTemplate.trade_dairy_color_background,
                            color: editedTemplate.trade_dairy_color_text,
                          }}
                        >
                          {editedTemplate.trade_dairy_name}
                        </span>
                      </div>
                      <div className="cs-card_btns">
                        <button
                          className="cs-card_btn cs-color2"
                          type="button"
                          onClick={() => {
                            handleDelete(editedTemplate.id);
                          }}
                        >
                          {t('delete')}
                        </button>
                      </div>
                    </div>
                    <div className="cs-card_body">
                      <div className="cs-row">
                        <div className="cs-col_6">
                          <label>{t('stock_name')} *</label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_stock_name')}
                            {...register('stock_name')}
                          />
                          <Spacing lg="20" md="20" />
                        </div>
                        <div className="cs-col_6">
                          <label>{t('stock_price')}</label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_stock_price')}
                            {...register('stock_price')}
                          />
                          <Spacing lg="20" md="20" />
                        </div>
                        <div className="cs-col_6">
                          <label>{t('note')}</label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_note')}
                            {...register('note')}
                          />
                          <Spacing lg="20" md="20" />
                        </div>
                        <div className="cs-col_6">
                          <label>{t('stop_loss')}</label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_stop_loss')}
                            {...register('stop_loss')}
                          />
                          <Spacing lg="20" md="20" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="cs-flex_end_btns">
                    <button
                      type="button"
                      className="cs-btn cs-color2"
                      style={{
                        width: '100%',
                        maxWidth: '150px',
                        textTransform: 'uppercase',
                      }}
                      onClick={() => setModal(false)}
                    >
                      {t('cancel')}
                    </button>
                    <button
                      type="submit"
                      className={`cs-btn cs-color4 ${
                        updateLoading ? 'cs-loading_wrap' : ''
                      }`}
                      style={{
                        width: '100%',
                        maxWidth: '150px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {updateLoading && (
                        <span className="cs-btn_loading">
                          <Icon icon="la:spinner" />
                          {t('loading')}
                        </span>
                      )}
                      {t('save')}
                    </button>
                  </div>
                  <Spacing lg="5" md="5" />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
