import { useEffect, useState } from "react";
import axios from "../api/lib/axios";

export default function useFetch(url) {
  const [data, setData] = useState({});
  const [loading, seLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    seLoading(true);
    axios
      .get(url)
      .then((response) => setData(response.data))
      .catch((err) => setError(err))
      .finally(() => seLoading(false));
  }, [url]);

  return { data, loading, error };
}
