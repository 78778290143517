import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMonthlySubscriptionTrending } from '../../api/services/dashboard';
import LoadingSpinner from '../Common/LoadingSpinner';

export default function Statistics({ subscriber, unsubscriber }) {
  const { t } = useTranslation();
  const [stattistics, setStattistics] = useState({
    subscribe: 0,
    unsubscribe: 0,
  });
  const [loading, setLoading] = useState(false);
  const getStattistics = async () => {
    setLoading(true);
    const res = await getMonthlySubscriptionTrending();
    setStattistics(res.data.data);
    setLoading(false);
  };
  useEffect(() => {
    getStattistics();
  }, []);
  return (
    <div className="statistics">
      <h2>{t('monthly_subscriber_trending')}</h2>
      <div className={`statistics_in ${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && <LoadingSpinner />}
        <div>
          <h3>{t('new_subscriber')}</h3>
          <h4>
            {stattistics.subscribe}
            <svg
              width={17}
              height={26}
              viewBox="0 0 17 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.73152 1.08637C9.30567 0.660522 8.61523 0.660522 8.18938 1.08637L1.24973 8.02602C0.823876 8.45187 0.823876 9.14232 1.24973 9.56817C1.67558 9.99402 2.36602 9.99402 2.79187 9.56817L8.96045 3.39959L15.129 9.56817C15.5549 9.99402 16.2453 9.99402 16.6712 9.56817C17.097 9.14232 17.097 8.45187 16.6712 8.02602L9.73152 1.08637ZM10.0509 25.1206L10.0509 1.85745L7.86999 1.85745L7.86999 25.1206L10.0509 25.1206Z"
                fill="#64C680"
              />
            </svg>
          </h4>
        </div>
        <div>
          <h3>{t('unsubscriber')}</h3>
          <h4>
            {stattistics.unsubscribe}
            <svg
              width={18}
              height={26}
              viewBox="0 0 18 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.77107 24.8914C9.34522 25.3173 8.65478 25.3173 8.22893 24.8914L1.28928 17.9518C0.863427 17.5259 0.863427 16.8355 1.28928 16.4096C1.71513 15.9838 2.40557 15.9838 2.83142 16.4096L9 22.5782L15.1686 16.4096C15.5944 15.9838 16.2849 15.9838 16.7107 16.4096C17.1366 16.8355 17.1366 17.5259 16.7107 17.9518L9.77107 24.8914ZM10.0905 0.857178L10.0905 24.1203L7.90954 24.1203L7.90954 0.857178L10.0905 0.857178Z"
                fill="#FB123C"
              />
            </svg>
          </h4>
        </div>
      </div>
    </div>
  );
}
