import ReactApexChart from "react-apexcharts";
export default function TierStatus({ tierStatus }) {
  const series = tierStatus.graph_total_amount_percent;
  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    labels: tierStatus.graph_title,
    fill: {
      colors: ["rgb(100, 198, 128)", "rgb(222, 105, 68)", "rgb(111, 170, 220)"],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  );
}
