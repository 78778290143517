import { forgotPassReqUrl, resetPasswordUrl } from "../../../const";
import axios from "../../../lib/axios";

// forgot pass request
export const forgotPassReq = async (data) => {
  return await axios.post(forgotPassReqUrl, data);
};

// reset password
export const resetPassword = async (data) => {
  return await axios.post(resetPasswordUrl, data);
};
