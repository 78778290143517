import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import { useForm } from "react-hook-form";
import Spacing from "../../components/Spacing";
import { login } from "../../api/services/auth/login";
import { useContext } from "react";
import UserContext from "../../context/user/user-context";
import { pageTitle } from "../../helper";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import LangauageDropdown from "../../components/Header/LangauageDropdown";

export default function Login() {
  const { t } = useTranslation();
  const auth = useAuth();
  pageTitle("Creator Login");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, SetErrorMessage] = useState("");
  const navigate = useNavigate();
  const { setUser, setIsAuth } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [languageToggle, setLanguageToggle] = useState(false);
  const handelLanguageToggle = () => {
    setLanguageToggle(!languageToggle);
  };

  // handle login
  const handleLogin = async (data) => {
    setIsLoading(true);
    data.type = 1;
    data.device = 1010;
    const res = await login(data);
    setIsLoading(false);
    if (res.data.success === 1) {
      setUser(res.data.data);
      setIsAuth(true);
      if (!res.data.data.dob) {
        window.location = "/onboarding/personal-info";
      } else {
        window.location = "/";
      }
    } else if (res.data.success === 0) {
      SetErrorMessage(res.data.message);
    }
  };
  return !auth ? (
    <>
      <div className="cs-header_btns">
        <Link to="/" className="cs-header_btn">
          {t("home")}
        </Link>
        <Link to="/auth/register" className="cs-header_btn cs-color1">
          {t("register")}
        </Link>
        <LangauageDropdown
          handelLanguageToggle={handelLanguageToggle}
          languageToggle={languageToggle}
          close={setLanguageToggle}
        />
      </div>
      <div className="cs-login">
        <div
          className="cs-login_left"
          style={{
            backgroundImage: "url(/images/login.jpg)",
            backgroundPosition: "bottom center",
            backgroundColor: "#ffd230",
          }}
        >
          <img src="/images/logo_white.svg" alt="Logo" />
        </div>
        <div className="cs-login_right">
          <div className="cs-login_info cs-primary_font">
            <div>
              <div className="cs-text_center">
                <Link to="/">
                  <img src="/images/icon/icon4.svg" alt="Icon" />
                </Link>
              </div>
              <Spacing lg="25" md="20" />
              <h3>{t("login_title")}</h3>
              <h2>{t("login_subtitle")}</h2>
              <p className="cs-danger">{errorMessage}</p>
            </div>
            <Spacing lg="20" md="20" />
            <form onSubmit={handleSubmit(handleLogin)}>
              <label>{t("login_input_label_1")}</label>
              <input
                type="text"
                placeholder={t("login_input_placeholder_1")}
                className={`cs-form_field_1 ${
                  errors.email ? "cs-invalid-input" : ""
                }`}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className="invalid-feedback">
                  The username/email field is required
                </p>
              )}
              <Spacing lg="10" md="10" />
              <label>{t("login_input_label_2")}</label>
              <div className="cs-field_with_icon cs-type1">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder={t("login_input_placeholder_2")}
                  className={`cs-form_field_1 ${
                    errors.password ? "cs-invalid-input" : ""
                  }`}
                  {...register("password", { required: true })}
                />
                <span
                  className="cs-field_icon_btn"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <Icon icon="mdi:eye-off" />
                  ) : (
                    <Icon icon="ic:baseline-remove-red-eye" />
                  )}
                </span>
              </div>
              {errors.password && (
                <p className="invalid-feedback">
                  The password field is required
                </p>
              )}
              <Spacing lg="25" md="25" />
              <div className="cs-login_meta">
                <div>
                  <div className="cs-check">
                    <input type="checkbox" />
                    <label>{t("remember_me")}</label>
                  </div>
                </div>
                <div>
                  <Link to="/auth/fogot-password">{t("forgot_password")}</Link>
                </div>
              </div>
              <Spacing lg="20" md="20" />
              <button
                className={`cs-general_btn cs-primary_font ${
                  isLoading ? "cs-loading_wrap" : ""
                }`}
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="cs-btn_loading">
                    <Icon icon="la:spinner" />
                    {t("loading")}
                  </span>
                )}
                {t("login_now")}
              </button>
              <Spacing lg="30" md="30" />
              <div className="cs-text_center">
                {t("dont_have_an_account")}{" "}
                <Link to="/auth/register">{t("register_here")}</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/" />
  );
}
