import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header';
import OnboardingNav from '../MainSideNav/OnboardingNav';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function OnboardingLayout() {
  const { t } = useTranslation();
  return (
    <>
      <Header />

      <div className="cs-onboarding_wrap">
        <div className="cs-container_md">
          <OnboardingNav varient="cs-style1" />
          <OnboardingNav varient="cs-style2" title />
          <Outlet />
        </div>
        <div className="cs-container_md">
          <Spacing lg="40" md="40" />
          <div className="cs-bording_copy">{t('copyright_by_gorudo')}</div>
        </div>
      </div>
    </>
  );
}
