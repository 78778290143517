import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function OnboardingNav({ title, varient }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <button className="cs-side_nav_toggle" onClick={() => setOpen(!open)}>
        <Icon icon="ic:round-menu" />
      </button>
      <div
        className={open ? 'cs-side_nav_overlay active' : 'cs-side_nav_overlay'}
        onClick={() => setOpen(false)}
      ></div>
      <div
        className={`nav_wrap ${varient ? varient : ''} ${open ? 'active' : ''}`}
      >
        {title && (
          <h2 className="cs-onbording_title">
            {t('onboarding_sidenav_title')} <span>Gorudo</span>
          </h2>
        )}
        <ul>
          {/* add .complete for state complete */}
          <li>
            <NavLink
              to="/onboarding/personal-info"
              onClick={() => setOpen(false)}
            >
              <>
                <span className="icon">
                  <svg
                    width="20"
                    height="24"
                    viewBox="0 0 20 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 24V19M14 24V19M1 24V19C1 14.0294 5.02944 11 10 11M10 11C14.9706 11 19 14.0294 19 19V24M10 11C12.7614 11 15 8.76142 15 6C15 3.23858 12.7614 1 10 1C7.23858 1 5 3.23858 5 6C5 8.76142 7.23858 11 10 11Z"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                  </svg>
                </span>
                <span className="nav_item">{t('personal_info')}</span>
              </>
            </NavLink>
            {/* <span className='complete_icon'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='24' height='24' rx='12' fill='white' />
              <path d='M6.16699 13.1673L10.2503 16.6673L17.8337 7.33398' stroke='#4B9DDC' strokeWidth='2.5'/>
            </svg>
          </span> */}
          </li>
          <li>
            <NavLink
              to="/onboarding/choose-plan"
              onClick={() => setOpen(false)}
            >
              <>
                <span className="icon">
                  <svg
                    width="35"
                    height="16"
                    viewBox="0 0 35 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.95897 1.74461C4.52188 1.74461 1.73562 4.54527 1.73562 8C1.73562 11.4547 4.52188 14.2554 7.95897 14.2554H26.6173C30.0544 14.2554 32.8407 11.4547 32.8407 8C32.8407 4.54527 30.0544 1.74461 26.6173 1.74461H7.95897ZM0 8C0 3.58169 3.56338 0 7.95897 0H26.6173C31.0129 0 34.5763 3.58169 34.5763 8C34.5763 12.4183 31.0129 16 26.6173 16H7.95897C3.56338 16 0 12.4183 0 8Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span className="nav_item">{t('gorudo_plans')}</span>
              </>
            </NavLink>
            {/* <span className='complete_icon'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='24' height='24' rx='12' fill='white' />
              <path d='M6.16699 13.1673L10.2503 16.6673L17.8337 7.33398' stroke='#4B9DDC' strokeWidth='2.5'/>
            </svg>
          </span> */}
          </li>
          <li>
            <NavLink
              to="/onboarding/membership-tiers"
              onClick={() => setOpen(false)}
            >
              <>
                <span className="icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 13V16H13V19H16V21L18 23H23V19L12.74 8.74C12.91 8.19 13 7.6 13 7C13 3.69 10.31 1 7 1C3.69 1 1 3.69 1 7C1 10.31 3.69 13 7 13C7.88 13 8.72 12.81 9.47 12.47L10 13ZM6 7C5.4475 7 5 6.5525 5 6C5 5.4475 5.4475 5 6 5C6.5525 5 7 5.4475 7 6C7 6.5525 6.5525 7 6 7Z"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                  </svg>
                </span>
                <span className="nav_item">{t('membership_tiers')}</span>
              </>
            </NavLink>
            {/* <span className='complete_icon'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='24' height='24' rx='12' fill='white' />
              <path d='M6.16699 13.1673L10.2503 16.6673L17.8337 7.33398' stroke='#4B9DDC' strokeWidth='2.5'/>
            </svg>
          </span> */}
          </li>
          <li>
            <NavLink
              to="/onboarding/withdrawal-method"
              onClick={() => setOpen(false)}
            >
              <>
                <span className="icon">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="1.25"
                      y="1.25"
                      width="25.5"
                      height="25.5"
                      rx="12.75"
                      fill="transparent"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M8.1665 15.1673L12.2498 18.6673L19.8332 9.33398"
                      stroke="currentColor"
                      strokeWidth="2.5"
                    />
                  </svg>
                </span>
                <span className="nav_item">{t('withdrawal_method')}</span>
              </>
            </NavLink>
            {/* <span className='complete_icon'>
            <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <rect width='24' height='24' rx='12' fill='white' />
              <path d='M6.16699 13.1673L10.2503 16.6673L17.8337 7.33398' stroke='#4B9DDC' strokeWidth='2.5'/>
            </svg>
          </span> */}
          </li>
        </ul>
      </div>
    </>
  );
}
