import {
  createTierUrl,
  deleteTierUrl,
  membershipTierUrl,
  myTierUpdateUrl,
} from "../../const";
import axios from "../../lib/axios";

// create paid tier
export const createTier = async (data) => {
  return await axios.post(createTierUrl, data);
};

// get tier
export const getMembershipTiers = async () => {
  return await axios.get(membershipTierUrl);
};

// delete tier
export const deleteMyTier = async (tierId) => {
  return await axios.get(deleteTierUrl, { params: { post_id: tierId } });
};

// my tier update
export const myTierUpdate = async (data) => {
  return await axios.put(myTierUpdateUrl, data);
};
