import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { getRecentlySubscribedMember } from '../../api/services/dashboard';
import LoadingSpinner from '../Common/LoadingSpinner';
import Spacing from '../Spacing';
import { v4 as uuidv4 } from 'uuid';
import PaginateButton from '../Common/PaginateButton';
import { defaultHasMoreCount } from '../../config/const';
import EmptyData from '../Common/EmptyData';
import { useTranslation } from 'react-i18next';

export default function RecentlySubscribed() {
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  // get member
  const getmember = async () => {
    setLoading(true);
    const res = await getRecentlySubscribedMember();
    setMembers(res.data.data);
    setCount(res.data.count);
    setLoading(false);
  };
  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getRecentlySubscribedMember(page, 5);
    setMembers([...members, ...res.data.data]);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
    setPaginateLoading(false);
  };
  useEffect(() => {
    getmember();
  }, []);
  return (
    <div className="cs-list_1_wrap">
      <h2 className="cs-semibold" style={{ fontSize: '18px' }}>
        {t('recently_subscribed')}
      </h2>
      <ul
        className={`cs-list cs-style1 cs-mp0 ${
          loading ? 'cs-loading_wrap' : ''
        }`}
      >
        {loading && (
          <>
            <Spacing lg="20" md="20" />
            <LoadingSpinner />
          </>
        )}
        {members.map(item => (
          <li key={uuidv4()}>
            <b>{item.userName}</b>
            <span>{moment(item.created_at).format('D MMM Y')}</span>
          </li>
        ))}
      </ul>
      {!members.length && !loading ? <EmptyData /> : null}
      {count > defaultHasMoreCount && (
        <>
          <Spacing lg="10" md="10" />
          <PaginateButton
            loading={paginateLoading}
            hasMore={hasMore}
            onClick={paginate}
          />
        </>
      )}
    </div>
  );
}
