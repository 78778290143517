import {
  createOpenPositionUrl,
  deleteOpenPositionUrl,
  multipleOpenPositionUrl,
  openPositionListUrl,
  updateOpenPositionUrl,
} from "../../const";
import axios from "../../lib/axios";
// create open position
export const createOpenPosition = async (data) => {
  return await axios.post(createOpenPositionUrl, data);
};

// get open position
export const getOpenPosition = async (is_draft = "", search = "") => {
  return await axios.get(openPositionListUrl, { params: { is_draft, search } });
};

// delete open position
export const deleteOpenPosition = async (post_id) => {
  return await axios.delete(deleteOpenPositionUrl, { params: { post_id } });
};

// update open position
export const updateOpenPosition = async (data) => {
  return axios.put(updateOpenPositionUrl, data);
};

// get mutiple open position
export const getMultipleOpenPossition = async (ids) => {
  return axios.get(multipleOpenPositionUrl, { params: { ids } });
};
