import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./sass/index.scss";
import UserState from "./context/user/UserState";
import ReactDOM from "react-dom";
import TierState from "./context/tier/TierState";
import PlanState from "./context/plan/PlanState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotificationState from "./context/notification/NotificationState";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <UserState>
      <TierState>
        <PlanState>
          <NotificationState>
            <ToastContainer position="top-center" />
            <App />
          </NotificationState>
        </PlanState>
      </TierState>
    </UserState>
  </BrowserRouter>
  // </React.StrictMode>
);
