import Pusher from "pusher-js";
import { useState, useEffect, useContext } from "react";
import { unreadNotification } from "../../api/services/notification";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

import UserContext from "../user/user-context";
import NotificationContext from "./notification-context";
import useAuth from "../../hooks/useAuth";

const NotificationState = (props) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const { user } = useContext(UserContext);
  const auth = useAuth();
  // get unread count
  const getUnreadCount = async () => {
    const res = await unreadNotification();
    setUnreadCount(res.data.data.length);
  };

  useEffect(() => {
    getUnreadCount();

    if (user?.email) {
      if (navigator?.userAgentData) {
        const platform = navigator?.userAgentData?.platform;
        if (
          platform !== "macOS" &&
          platform !== "iOS" &&
          platform !== "ipados"
        ) {
          // pusher channel
          var pusher = new Pusher("67765c9d1c4c787596ae", {
            cluster: "ap2",
          });
          var channel = pusher.subscribe("gorudo-channel");
          channel.bind("gorudo-event", (data) => {
            if (
              data.email_list.find((item) => {
                return item === user.email;
              })
            ) {
              setUnreadCount((prevCount) => prevCount + 1);
            }
          });

          // pusher beem
          const beamsClient = new PusherPushNotifications.Client({
            instanceId: "3a60d68b-f34b-4ddb-93f7-8146f9b32e62",
          });
          beamsClient
            .start()
            .then((beamsClient) => beamsClient.getDeviceId())
            .then((deviceId) => {})
            .then(() => beamsClient.addDeviceInterest(`${user.email}`))
            .then(() => beamsClient.getDeviceInterests())
            .catch(console.error);
        }
      }
    }
  }, [user]);

  return (
    <NotificationContext.Provider
      value={{
        unreadCount,
        getUnreadCount,
        setUnreadCount,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
