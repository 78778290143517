import React, { useState } from "react";
// import DotOption from '../../../components/DotOption';
import Spacing from "../../../components/Spacing";
import PendingForPublish from "./components/PendingForPublish";
import Published from "./components/Published";
import Draft from "./components/Draft";
import OpenPositionGraph from "./components/OpenPositionGraph";
import { getOpenPosition } from "../../../api/services/openPosition";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function OpenPosition() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [selected, setSelected] = useState(0);
  const [openPosition, setOpenPosition] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchQuery = searchParams.get("search") ?? "";

  // get open position
  const getAllOpenPostion = async () => {
    setLoading(true);
    const res = await getOpenPosition("", searchQuery);
    setOpenPosition(res.data.data);
    setLoading(false);
  };

  const handelTab = (index) => {
    setSelected(index);
  };

  useEffect(() => {
    getAllOpenPostion();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
        {t("your_open_position")}
      </h2>
      <Spacing lg="30" md="30" />
      <ul className="cs-secondary_nav">
        <li>
          <button
            className={selected === 0 ? "active" : ""}
            onClick={() => handelTab(0)}
          >
            {t("published")}
          </button>
        </li>
        <li>
          <button
            className={selected === 1 ? "active" : ""}
            onClick={() => handelTab(1)}
          >
            {t("pending_for_publish")}
          </button>
        </li>
        <li>
          <button
            className={selected === 2 ? "active" : ""}
            onClick={() => handelTab(2)}
          >
            {t("draft")}
          </button>
        </li>
      </ul>
      {selected === 0 && (
        <Published
          handelTab={handelTab}
          openPosition={openPosition}
          loading={loading}
        />
      )}
      {selected === 1 && <PendingForPublish refresh={getAllOpenPostion} />}
      {selected === 2 && <Draft refresh={getAllOpenPostion} />}

      <Spacing lg="50" md="30" />
      <OpenPositionGraph openPosition={openPosition} />
      <Spacing lg="60" md="60" />
    </div>
  );
}
