import React, { useContext, useEffect, useRef, useState } from "react";
import Dropdown2 from "../Dropdown/Dropdown2";
import TextareaAutosize from "react-textarea-autosize";
import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { createBroadcast, updateBroadcast } from "../../api/services/broadcast";
import { useTranslation } from "react-i18next";
import { getUserTierPermission } from "../../api/services/auth/user";

export default function BroadcastsEdit({
  title,
  updatedBroadcast,
  setUpdateBroadcast,
  closePopUp,
  refresh,
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState({
    name: "",
    url: "",
  });
  const [modal, setModal] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectText, setSelectText] = useState("1");
  const [showTierWarning, setShowTierWarning] = useState(false);
  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const [selectedTiers, setSelectedTiers] = useState([]);

  const [creatorTier, setCreatorTier] = useState([]);

  const formSchema = Yup.object().shape({
    title: Yup.string(),
    description: Yup.string().required(
      "The broadcast message field is required"
    ),
    image: Yup.mixed().nullable(),
    tag: Yup.mixed().nullable(),
    visibility: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const getUserTier = async () => {
    const res = await getUserTierPermission(1);
    setCreatorTier(res.data.data);
  };

  const handleTierCheckboxChange = (e, tierId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedTiers([...selectedTiers, tierId]); // Add the selected tier to the array
    } else {
      setSelectedTiers(selectedTiers.filter((id) => id !== tierId)); // Remove the deselected tier from the array
    }
  };

  const handelChange = (e) => {
    setFile({
      name: e.target.files[0].name,
      url: URL.createObjectURL(e.target.files[0]),
    });
    setIsImageRemoved(false);
    setValue("image", e.target.files[0]);
    setModal(false);
  };

  const handelUploadCross = () => {
    setFile({ name: "", url: "" });
    setIsImageRemoved(true);
  };

  const handelTagSubmit = (event) => {
    if (event.key === "Enter" && tagInput) {
      setTags([...tags, tagInput]);
      setTagInput("");
      event.preventDefault();
    }
  };
  const removeTag = (id) => {
    const newTags = tags.filter((tag, i) => i !== id);
    setTags(newTags);
  };
  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  // handle publish
  const handlePublish = async (data) => {
    if (isImageRemoved) {
      delete data.image;
    }
    data.tag = tags ? tags.join(", ") : "";
    data.tiers = selectedTiers ? selectedTiers.join(", ") : "";
    let formData = new FormData();
    for (let item in data) {
      formData.append(item, data[item]);
    }

    if (selectText === "3" && selectedTiers.length === 0) {
      setShowTierWarning(true);
    } else {
      setShowTierWarning(false);
      setLoading(true);
      if (updatedBroadcast) {
        formData.append("post_id", updatedBroadcast.id);
        await updateBroadcast(formData);
        setUpdateBroadcast({});
        closePopUp(false);
      } else {
        await createBroadcast(formData);
      }
      reset();
      refresh();
      setTags([]);
      setSelectText("1");
      setValue("visibility", "1");
      handelUploadCross();
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserTier();
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    setValue("title", "");
    setValue("description", updatedBroadcast?.description);
    setFile({
      name: "",
      url: updatedBroadcast ? updatedBroadcast.image : "",
    });
    setValue(
      "visibility",
      updatedBroadcast ? updatedBroadcast.visibility : "1"
    );
    setSelectText(updatedBroadcast ? updatedBroadcast.visibility : "1");
    setValue("tag", updatedBroadcast ? updatedBroadcast.tag.join(", ") : "");
    setTags(updatedBroadcast ? updatedBroadcast.tag : []);

    setSelectedTiers(
      updatedBroadcast
        ? updatedBroadcast?.tiers.split(", ")?.map((str) => parseInt(str))
        : []
    );

    setCharCount(updatedBroadcast ? updatedBroadcast.description.length : 0);
  }, [updatedBroadcast]);
  return (
    <form onSubmit={handleSubmit(handlePublish)}>
      <h2 style={{ fontSize: "24px", marginBottom: "15px" }}>{title}</h2>
      <div className="cs-post_input cs-style1">
        <div className="cs-post_input_in">
          <div className="cs-post_input_in_in">
            <div style={{ position: "absolute", right: "0", top: "-27px" }}>
              <label></label>
              <span style={{ color: "#A6A6A6" }}>{charCount}/500</span>
            </div>
            <TextareaAutosize
              style={{ boxSizing: "border-box" }}
              minRows={1}
              maxRows={60}
              maxLength="500"
              placeholder={t("enter_your_broadcast_message")}
              {...register("description")}
              onChange={(e) => {
                setCharCount(e.target.value.length);
              }}
            />
            {file.url && (
              <div style={{ position: "relative" }}>
                <img src={file.url} alt="Thumb" />
                <span
                  className="upload_output_close"
                  onClick={handelUploadCross}
                >
                  <Icon icon="gg:close-o" />
                </span>
              </div>
            )}
          </div>
          <div className="cs-post_input_bottom">
            {loading && <span>{t("loading")}</span>}
            <button
              type="button"
              className="cs-post_input_btn cs-size_md"
              onClick={() => setModal(true)}
            >
              <svg
                width={17}
                height={17}
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4313_16288)">
                  <path
                    d="M15.6193 1.1438H1.58184C1.22871 1.1438 0.944336 1.42817 0.944336 1.7813V15.8188C0.944336 16.1719 1.22871 16.4563 1.58184 16.4563H15.6193C15.9725 16.4563 16.2568 16.1719 16.2568 15.8188V1.7813C16.2568 1.42817 15.9725 1.1438 15.6193 1.1438ZM14.9818 2.4188V6.60005C12.8787 6.8563 11.2506 7.57505 9.99121 8.50317C6.26309 8.16255 3.62871 10.2375 2.21934 11.7625V2.4188H14.9818ZM2.21934 13.8032C2.68809 13.1094 4.93809 10.0844 8.58809 9.7688C6.77559 11.7594 6.10684 14.1157 5.88496 15.1782H2.21934V13.8032ZM7.17871 15.1813C7.57559 13.3657 9.16309 8.67192 14.9818 7.88755V15.1813H7.17871Z"
                    fill="#424941"
                  />
                  <path
                    d="M5.40685 7.46885C6.50685 7.46885 7.40372 6.57197 7.40372 5.47197C7.40372 4.37197 6.50997 3.4751 5.40685 3.4751C4.30685 3.4751 3.40997 4.37197 3.40997 5.47197C3.4131 6.57197 4.30685 7.46885 5.40685 7.46885ZM5.40685 4.75322C5.80372 4.75322 6.1256 5.0751 6.1256 5.47197C6.1256 5.86885 5.80372 6.19072 5.40685 6.19072C5.00997 6.19072 4.6881 5.86885 4.6881 5.47197C4.6881 5.0751 5.00997 4.75322 5.40685 4.75322Z"
                    fill="#424941"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4313_16288">
                    <rect
                      width={16}
                      height={16}
                      fill="white"
                      transform="translate(0.600586 0.800049)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button disabled={loading} className="cs-post_input_btn">
              <svg
                width={25}
                height={24}
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.720703 11.0118L8.90892 15.8118L13.7089 24L24.7207 0L0.720703 11.0118ZM13.6296 22.4138L9.6368 15.6027L12.0789 13.1606L11.5601 12.6418L9.11798 15.0839L2.30691 11.0912L22.2704 1.93148L14.4667 9.73509L14.9856 10.2539L22.7892 2.45034L13.6296 22.4138Z"
                  fill="#424941"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="cs-tag_input_wrap">
          <div className="cs-tag_input_left">
            <input
              type="text"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              className="cs-tag_input"
              placeholder={t("hashtag_optional")}
              onKeyDown={handelTagSubmit}
            />
            {tags?.map((tag, index) => (
              <span className="cs-tag_output" key={index}>
                {tag}
                <span
                  className="cs-tag_remove"
                  onClick={() => removeTag(index)}
                >
                  <Icon icon="mingcute:close-line" />
                </span>
              </span>
            ))}
          </div>
          <div className="cs-tag_input_right">
            <div className="cs-dropdown_wrap_2" ref={refOne}>
              <div className="cs-dropdown_btn_2" onClick={() => setOpen(!open)}>
                <span>
                  {selectText === "1"
                    ? `${t("all_tiers")}`
                    : selectText === "2"
                    ? `${t("all_paid_members")}`
                    : selectText === "3"
                    ? `${t("select_tiers_to_share")}`
                    : ""}
                </span>
                <button type="button" className="cs-dropdown_btn_arrow">
                  <Icon icon="ic:round-keyboard-arrow-down" />
                </button>
              </div>
              {open && (
                <ul className="cs-dropdown_2 cs-mp0">
                  <li className={`${selectText === "1" ? "active" : ""}`}>
                    <span
                      onClick={() => {
                        setSelectText("1");
                        setValue("visibility", "1");
                      }}
                    >
                      {t("all_tiers")} <br />({t("included_free_tier")})
                    </span>
                  </li>
                  <li className={`${selectText === "2" ? "active" : ""}`}>
                    <span
                      onClick={() => {
                        setSelectText("2");
                        setValue("visibility", "2");
                      }}
                    >
                      {t("all_paid_members")}
                    </span>
                  </li>
                  <li className={`${selectText === "3" ? "active" : ""}`}>
                    <span
                      onClick={() => {
                        setSelectText("3");
                        setValue("visibility", "3");
                      }}
                    >
                      {t("select_tiers_to_share")}
                    </span>
                    <ul className="cs-mp0">
                      {creatorTier.map((item, index) => (
                        <li key={index}>
                          <div className="cs-check">
                            <input
                              type="checkbox"
                              value={item.id}
                              checked={selectedTiers.includes(item.id)} // Check if the item's id is in the selectedTiers array
                              onChange={(e) =>
                                handleTierCheckboxChange(e, item.id)
                              } // Call a function to update the selected tiers
                            />
                            <label>{item.title}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              )}
            </div>
            {showTierWarning && (
              <span className="cs-danger">{t("tier_warning_text")}</span>
            )}
          </div>
        </div>
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <div className="cs-upload_image_modal">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <h2
                      style={{ fontSize: "16px", fontWeight: "600", margin: 0 }}
                    >
                      {t("upload_photo")}
                    </h2>
                    <Icon
                      icon="gg:close-o"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                      onClick={() => setModal(false)}
                    />
                  </div>
                  <div className="upload_input">
                    <svg
                      width={32}
                      height={43}
                      viewBox="0 0 32 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.2126 17.5305L7.91846 17.5522"
                        stroke="white"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                      <path
                        d="M0.197754 2.85498C0.197754 1.75041 1.09318 0.85498 2.19775 0.85498H19.9502C20.4851 0.85498 20.9976 1.0692 21.3734 1.44978L25.9332 6.06785L30.5035 10.6965C30.873 11.0708 31.0803 11.5757 31.0803 12.1017V40.558C31.0803 41.6625 30.1849 42.558 29.0803 42.558H2.19775C1.09318 42.558 0.197754 41.6625 0.197754 40.558V2.85498Z"
                        fill="#092C4C"
                      />
                      <path
                        d="M23.3597 34.2173H7.91846"
                        stroke="white"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                      <path
                        d="M23.3597 25.877H7.91846"
                        stroke="white"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                    <div className="upload_output_right">
                      <h3 className="cs-m0">
                        Drag and drop here <br /> Any JPG, PNG or GIF up to
                        512MB <br /> or
                      </h3>
                      <p className="cs-m0">Browse</p>
                    </div>
                    <input
                      onChange={handelChange}
                      type="file"
                      // accept="image/*"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {errors.description && (
        <p className="invalid-feedback">{errors.description?.message}</p>
      )}
    </form>
  );
}
