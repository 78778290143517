import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getWithdrawalHistory } from "../../../../api/services/settings/earningDashboard";
import LoadingSpinner from "../../../../components/Common/LoadingSpinner";
import PaginateButton from "../../../../components/Common/PaginateButton";
import Spacing from "../../../../components/Spacing";
import { defaultHasMoreCount } from "../../../../config/const";

export default function Withdrawal() {
  const { t } = useTranslation();
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [startingAfter, setStartingAfter] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);

  //  get Withdrawal history
  const getWithdrawal = async () => {
    setLoading(true);
    const res = await getWithdrawalHistory();
    setLoading(false);
    setWithdrawalHistory(res.data.data);
    setCount(res.data.total_count);
    setHasMore(res.data.has_more);
    if (res.data.data.length) {
      const lastArray = res.data.data[res.data.data.length - 1];
      setStartingAfter(lastArray.id);
    }
  };

  // handle paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getWithdrawalHistory(startingAfter);
    setWithdrawalHistory([...withdrawalHistory, ...res.data.data]);
    setPaginateLoading(false);
    const lastArray = res.data.data[res.data.data.length - 1];
    setStartingAfter(lastArray.id);
    setHasMore(res.data.has_more);
  };
  useEffect(() => {
    getWithdrawal();
  }, []);
  return (
    <>
      <div className="cs-table_responsive">
        <table className="cs-table cs-medium" style={{ fontSize: "14px" }}>
          <thead>
            <tr>
              <th>{t("withdrawal_table_heade_1")}</th>
              <th>{t("withdrawal_table_heade_2")}</th>
              <th>{t("withdrawal_table_heade_3")}</th>
              <th>{t("withdrawal_table_heade_4")}</th>
              <th>{t("withdrawal_table_heade_5")}</th>
            </tr>
          </thead>
          <tbody className={`${loading ? "cs-loading_wrap" : ""}`}>
            {loading && (
              <>
                <Spacing lg="40" md="40" />
                <LoadingSpinner />
              </>
            )}
            {withdrawalHistory.map((item) => (
              <tr key={item.id}>
                <td>
                  {moment(new Date(item.arrival_date) * 1000).format(
                    "DD MMM Y"
                  )}
                </td>
                <td>{t("withdrawal")}</td>
                <td style={{ textTransform: "capitalize" }}>
                  {item.reconciliation_status}
                </td>
                <td>
                  {item.amount}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {item.currency}
                  </span>
                </td>
                <td>{item.balance_transaction}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {count > defaultHasMoreCount && (
        <PaginateButton
          loading={paginateLoading}
          hasMore={hasMore}
          onClick={paginate}
        />
      )}
    </>
  );
}
