import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Spacing from '../Spacing';
import { useTranslation } from 'react-i18next';

export default function RelatedPages() {
  const { t } = useTranslation();
  return (
    <div className="cs-container_md">
      <Spacing lg="50" md="50" />
      <h4 className="cs-m0">Basic Page</h4>
      <ol>
        <li>
          <Link to="/auth/login">Login</Link>
        </li>
        <li>
          <Link to="/auth/register">Register</Link>
        </li>
        <li>
          <Link to="/auth/email-verification">Email Verification</Link>
        </li>
        <li>
          <Link to="/auth/fogot-password">Fogot Password</Link>
        </li>
        <li>
          <Link to="/auth/email-confirmation">Email Confirmation</Link>
        </li>
        <li>
          <Link to="/auth/update-password">Update Password</Link>
        </li>
        <li>
          <Link to="/creator-public-view/saf">Creator Public View</Link>
        </li>
        <li>
          <Link to="/all-notification">All Notification</Link>
        </li>
      </ol>
      <h4 className="cs-m0">Onboarding</h4>
      <ol>
        <li>
          <Link to="/onboarding/personal-info">Personal info</Link>
        </li>
        <li>
          <Link to="/onboarding/choose-plan">{t('gorudo_plans')}</Link>
        </li>
        <li>
          <Link to="/onboarding/membership-tiers-empty">
            Membership Tiers Empty
          </Link>
        </li>
        <li>
          <Link to="/onboarding/membership-tiers">{t('membership_tiers')}</Link>
        </li>
        <li>
          <Link to="/onboarding/withdrawal-method">
            {t('withdrawal_method')}
          </Link>
        </li>
        <li>
          <Link to="/onboarding/onboarding-success">Success Message</Link>
        </li>
      </ol>
      <h4 className="cs-m0">Dashboard</h4>
      <ol>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/members">{t('members')}</Link>
        </li>
        <li>
          <Link to="/broadcasts">{t('broadcasts')}</Link>
        </li>
        <li>
          <Link to="/trade-diary">{t('trade_diary')}</Link>
        </li>
        <li>
          <Link to="/watchlists">{t('watchlists')}</Link>
        </li>
        <li>
          <Link to="/open-position">{t('open_position')}</Link>
        </li>
        <li>
          <Link to="/newsletter">{t('newsletter')}</Link>
        </li>
        <li>
          <Link to="/newsletter/id">Newsletter Details</Link>
        </li>
        <li>
          <Link to="/webinar-video">Webinar Video (Working)</Link>
        </li>
        <li>
          <Link to="/webinar-video/id">Webinar Video Details</Link>
        </li>
        <li>
          <Link to="/resources-faq">Resources faq (Working)</Link>
        </li>
        <li>
          <Link to="/resources-faq/id">Resources faq Details</Link>
        </li>
      </ol>
      <h4 className="cs-m0">{t('settings')}</h4>
      <ol>
        <li>
          <Link to="/settings/my-profile">{t('my_profile')}</Link>
        </li>
        <li>
          <Link to="/settings/update-withdrawal-details">
            {t('update_withdrawal_details')}
          </Link>
        </li>
        <li>
          <Link to="/settings/earning-dashboard">{t('earning_dashboard')}</Link>
        </li>
        <li>
          <Link to="/settings/modify-membership-tiers">
            {t('modify_membership_tiers')}
          </Link>
        </li>
        <li>
          <Link to="/settings/nitification-settings">
            Nitification Settings
          </Link>
        </li>
        <li>
          <Link to="/settings/change-password">{t('change_password')}</Link>
        </li>
        <li>
          <Link to="/settings/my-profile">{t('my_profile')}</Link>
        </li>
        <li>
          <Link to="/settings/my-profile">{t('my_profile')}</Link>
        </li>
      </ol>
      <Spacing lg="50" md="50" />
      <Spacing lg="50" md="50" />
      <Spacing lg="50" md="50" />
      <button className="cs-logout_btn cs-primary_font cs-loading_wrap">
        <span className="cs-btn_loading">
          <Icon icon="la:spinner" />
          {t('loading')}
        </span>
        Submit Now
      </button>
      <Spacing lg="50" md="50" />
      <div className="cs-loading_wrap">
        <span className="cs-section_loading">
          <Icon icon="la:spinner" />
          {t('loading')}
        </span>

        <h1>Content</h1>
        <h1>Content</h1>
        <h1>Content</h1>
        <h1>Content</h1>
      </div>
    </div>
  );
}
