import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { updateGorudoPlan } from '../../api/services/onboarding/gorudo-plans';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import PricingPlan from '../../components/PricingPlan';
import Spacing from '../../components/Spacing';
import PlanContext from '../../context/plan/plan-context';
import { useTranslation } from 'react-i18next';

export default function ChoosePlan() {
  const { t } = useTranslation();
  const { getPlan } = useContext(PlanContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const updatePlan = async plan => {
    setIsLoading(true);
    await updateGorudoPlan(plan);
    getPlan();
    setIsLoading(false);
    navigate('/onboarding/membership-tiers');
  };

  return (
    <>
      <h3 className="cs-medium" style={{ fontSize: '21px' }}>
        {t('choose_plans')}
      </h3>
      <Spacing lg="10" md="10" />
      <div className={`${isLoading ? 'cs-loading_wrap' : ''}`}>
        {isLoading && <LoadingSpinner />}
        <PricingPlan setPlan={updatePlan} />
      </div>

      <Spacing lg="30" md="30" />
      <div className="cs-row">
        <div className="cs-col_6"></div>
        <div className="cs-col_6">
          <Link
            to="/onboarding/membership-tiers"
            className={`cs-logout_btn cs-type1`}
          >
            {t('next')}
          </Link>
        </div>
      </div>
    </>
  );
}
