import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Spacing from '../../../components/Spacing';
import TextEditor from '../../../components/TextEditor';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  getSingleResource,
  updateResource,
  uploadResourceImage,
} from '../../../api/services/resource';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import SvgIcon from '../../../components/Common/SvgIcon';
import { checkFileExt } from '../../../utils/checkFileExt';
import { getFileName } from '../../../utils/getFileName';
import TagInput from '../../../components/Common/TagInput';
import { useRef } from 'react';
import { useEffect } from 'react';
import { textLimit } from '../../../utils/TextLimit';
import { removeHtml } from '../../../utils/removeHtml';
import { useBeforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';

export default function ResourcesFaqEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id: post_id } = useParams();
  const submitRef = useRef();
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState('');
  const [attchLoading, setAttachLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [redirectPreview, setRedirectPreview] = useState(false);
  const [file, setFile] = useState({ name: '', url: '' });
  const formSchema = Yup.object().shape({
    post_id: Yup.string(),
    title: Yup.string(),
    sub_title: Yup.string(),
    short_desc: Yup.string(),
    detail: Yup.string(),
    image: Yup.string(),
    files: Yup.string(),
    tag: Yup.string(),
    is_draft: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm(formOptions);
  const handelChange = async e => {
    let formData = new FormData();
    formData.append('image', e.target.files[0]);
    const res = await uploadResourceImage(formData);
    setFile({ name: res.data.data?.split('/').pop(), url: res.data.data });
    setValue('image', res.data.data);
  };
  const handelUploadCross = () => {
    setFile({
      url: '',
      name: '',
    });
    setValue('image', '');
  };
  // handle Attachments
  const handleAttachments = async e => {
    const selectedFiles = e.target.files;
    let attachmentsList = [];
    setAttachLoading(true);
    for (let i = 0; i < selectedFiles.length; i++) {
      let formdata = new FormData();
      formdata.append('image', selectedFiles[i]);
      const res = await uploadResourceImage(formdata);
      attachmentsList.push(res.data.data);
      setAttachments([...attachments, ...attachmentsList]);
    }
    setAttachLoading(false);
  };

  // remove single attachments
  const removeAttachment = e => {
    const newAttachments = attachments.filter(item => item !== e);
    setAttachments(newAttachments);
  };

  // handle update
  const handleUpdate = async data => {
    if (isChanged) {
      data.files = attachments ? attachments.join(', ') : '';
      data.short_desc = textLimit(removeHtml(description), 100);
      data.tag = tags.join(', ');
      setSaveLoading(true);
      const res = await updateResource(data);
      setSaveLoading(false);
      // if (res.data.success === 1) {
      //   setLastUpdated(res.data.data.updated_at);
      // }
      setIsChanged(false);
    }
    if (redirectPreview) {
      navigate(`/resources-faq/${post_id}/view`);
    }
  };

  // handle blur
  const handleBlur = () => {
    submitRef.current && submitRef.current.click();
  };

  // get single resource
  const singleResource = async () => {
    setLoading(true);
    const res = await getSingleResource(post_id);
    setValue('post_id', res.data.data.id);
    setValue('title', res.data.data.title);
    setValue('sub_title', res.data.data.sub_title);
    setValue('short_desc', res.data.data.short_desc);
    setValue('detail', res.data.data.detail);
    setValue('image', res.data.data.image);
    setValue('files', res.data.data.files);
    setValue('tag', res.data.data.tag);
    setValue('is_draft', res.data.data.is_draft);
    setTags(res.data.data.tag ? res.data.data.tag.split(', ') : []);
    setAttachments(res.data.data.files ? res.data.data.files.split(', ') : []);
    setDescription(res.data.data.detail);
    setFile({
      name: res.data.data.image?.split('/').pop(),
      url: res.data.data.image,
    });
    setLoading(false);
  };

  // preview post
  const preview = async () => {
    setRedirectPreview(true);
    submitRef.current && submitRef.current.click();
  };

  // handle browser close
  useBeforeunload(event => {
    if (isChanged) {
      event.preventDefault();
    }
  });
  useEffect(() => {
    singleResource();
  }, []);

  // track any data change or not
  useEffect(() => {
    setIsChanged(true);
  }, [
    watch('title'),
    watch('short_desc'),
    watch('detail'),
    watch('tag'),
    watch('image'),
    watch('files'),
    attachments,
    tags,
  ]);
  return (
    <>
      {loading ? (
        <div className={`cs-container_md ${loading ? 'cs-loading_wrap' : ''}`}>
          {loading && (
            <>
              <Spacing lg="40" md="40" />
              <LoadingSpinner />
            </>
          )}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(handleUpdate)}
          onClick={handleBlur}
          className="cs-container_md"
        >
          <Spacing lg="40" md="40" />
          <Link to="/resources-faq" className="cs-bak_list_btn cs-semibold">
            <>
              <Icon icon="ph:arrow-left-duotone" />
              <span>{t('back')}</span>
            </>
          </Link>
          <Spacing lg="10" md="10" />
          <div className="cs-section_heading_6">
            <h2 style={{ fontSize: '24px', marginBottom: '0px' }}>
              {t('post_your_resources_faq')}
            </h2>
            <div className="cs-section_btns">
              <button
                className={`cs-btn cs-color2 ${
                  saveLoading ? 'cs-loading_wrap' : ''
                }`}
                disabled={saveLoading}
                style={{ pointerEvents: isChanged ? '' : 'none' }}
              >
                {saveLoading && (
                  <span className="cs-btn_loading">
                    <Icon icon="la:spinner" />
                    {t('loading')}
                  </span>
                )}
                {t('save_and_draft')}
              </button>
              <button className="cs-btn cs-color4" onClick={preview}>
                {t('preview')}
              </button>
            </div>
          </div>
          <Spacing lg="30" md="30" />
          <div className="newsletter_up_thumb_wrap">
            <h2
              className="cs-semibold"
              style={{ fontSize: '16px', marginBottom: '10px' }}
            >
              {t('upload_feature_image')}
            </h2>
            <div className="upload_input">
              <svg
                width={32}
                height={43}
                viewBox="0 0 32 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2126 17.5305L7.91846 17.5522"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                <path
                  d="M0.197754 2.85498C0.197754 1.75041 1.09318 0.85498 2.19775 0.85498H19.9502C20.4851 0.85498 20.9976 1.0692 21.3734 1.44978L25.9332 6.06785L30.5035 10.6965C30.873 11.0708 31.0803 11.5757 31.0803 12.1017V40.558C31.0803 41.6625 30.1849 42.558 29.0803 42.558H2.19775C1.09318 42.558 0.197754 41.6625 0.197754 40.558V2.85498Z"
                  fill="#092C4C"
                />
                <path
                  d="M23.3597 34.2173H7.91846"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
                <path
                  d="M23.3597 25.877H7.91846"
                  stroke="white"
                  strokeWidth={2}
                  strokeLinecap="round"
                />
              </svg>
              <div className="upload_output_right">
                <h3 className="cs-m0">
                  Drag and drop here <br /> Any JPG, PNG or GIF up to 512MB{' '}
                  <br /> or
                </h3>
                <p className="cs-m0">Browse</p>
              </div>
              <input onChange={handelChange} type="file" accept="image/*" />
            </div>
            {file.name && (
              <div className="upload_output">
                <div className="upload_output_img">
                  <img src={file.url} alt="Thumb" />
                </div>
                <h4 className="m0 f18">{file.name && file.name}</h4>
                <span
                  className="upload_output_close"
                  onClick={handelUploadCross}
                >
                  <Icon icon="gg:close-o" />
                </span>
              </div>
            )}
          </div>
          <Spacing lg="60" md="60" />
          <TextEditor
            register={register}
            value={description}
            setValue={e => {
              setDescription(e);
              setValue('detail', e);
            }}
            ReactQuillPlaceholder={t('enter_your_resources_faq')}
          />{' '}
          <Spacing lg="20" md="20" />
          <h2
            className="cs-semibold"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '20px',
              marginBottom: '10px',
              textTransform: 'uppercase',
            }}
          >
            <Icon icon="material-symbols:attach-file" />
            {t('attachments')}
          </h2>
          <div className={`cs-file ${attchLoading ? 'cs-loading_wrap' : ''}`}>
            {attchLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            <input type="file" onChange={handleAttachments} multiple />
            <label className="cs-file_label">Browser</label>
          </div>
          <Spacing lg="20" md="20" />
          <ul className="cs-attachment_list cs-mp0">
            {attachments.map((item, index) => (
              <li key={item}>
                <SvgIcon name={checkFileExt(item)} />
                {getFileName(item)}
                <span onClick={() => removeAttachment(item)}>
                  <Icon icon="material-symbols:delete-rounded" />
                </span>
              </li>
            ))}
          </ul>
          <Spacing lg="25" md="25" />
          <hr />
          <TagInput tags={tags} setTags={setTags} />
          <Spacing lg="60" md="60" />
          <button
            type="submit"
            ref={submitRef}
            style={{ display: 'none' }}
          ></button>
        </form>
      )}
    </>
  );
}
