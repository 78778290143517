import { signUpUrl, checkUserNameUrl, userVerifyUrl } from "../../../const";
import axios from "../../../lib/axios";

// sign up
export const signUp = async (data) => {
  delete data.confirmPwd;
  delete data.acceptTerms;
  const res = await axios.post(signUpUrl, data);

  // save login token to localstoarge
  if (res.data.success === 1) {
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("isAuth", true);
  }
  return res;
};

// check username
export const checkUserName = async (username) => {
  return await axios.get(checkUserNameUrl + `?username=${username}`);
};

// user email verify
export const verifyUserEmail = async (data) => {
  return await axios.post(userVerifyUrl, data);
};
