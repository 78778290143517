import React from 'react';
import { useTranslation } from 'react-i18next';
import Spacing from '../Spacing';

export default function PricingPlan({ setPlan }) {
  const { t } = useTranslation();
  return (
    <div className="pricing_row">
      <div className="pricing_col">
        <div className="pricing_box">
          <div className="pricing_box_top">
            <h2 className="f25">{t('basic')}</h2>
            <Spacing lg="25" md="25" />
            <ul className="pricing_feature">
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('newsletter')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('webinar_video')}
              </li>

              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('resources_faq')}
              </li>
            </ul>
          </div>
          <div className="pricing_box_bottom">
            <Spacing lg="40" md="40" />
            <button
              className="pricing_btn w_100 bordered_btn"
              onClick={() => setPlan(2)}
            >
              {t('5_Commission')}
            </button>
            <div className="cs-priding_note">
              {t('plus_payment_processing_fee')}
            </div>
          </div>
        </div>
      </div>
      <div className="pricing_col focus">
        <div className="pricing_box">
          <div className="pricing_box_top">
            <h2 className="f25">{t('standard')}</h2>
            <Spacing lg="10" md="10" />
            <div className="pricing_note">{t('popular_plan')}</div>
            <Spacing lg="20" md="20" />
            <ul className="pricing_feature">
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('broadcast')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('trade_diary')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('watchlist')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('open_position')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('webinar_video')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('resources_faq')}
              </li>
            </ul>
          </div>
          <div className="pricing_box_bottom">
            <Spacing lg="40" md="40" />
            <button className="pricing_btn w_100" onClick={() => setPlan(3)}>
              {t('10_Commission')}
            </button>
            <div className="cs-priding_note">
              {t('plus_payment_processing_fee')}
            </div>
            <Spacing lg="20" md="40" />
          </div>
        </div>
      </div>
      <div className="pricing_col">
        <div className="pricing_box">
          <div className="pricing_box_top">
            <h2 className="f25">{t('premium')}</h2>
            <Spacing lg="25" md="25" />
            <ul className="pricing_feature">
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('broadcast')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('trade_diary')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('watchlist')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('open_position')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('newsletter')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('webinar_video')}
              </li>
              <li>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {t('resources_faq')}
              </li>
            </ul>
          </div>
          <div className="pricing_box_bottom">
            <Spacing lg="40" md="40" />
            <button
              className="pricing_btn w_100 bordered_btn"
              onClick={() => setPlan(4)}
            >
              {t('12_Commission')}
            </button>
            <div className="cs-priding_note">
              {t('plus_payment_processing_fee')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
