import React, { useContext, useEffect, useRef, useState } from "react";
import Spacing from "../../components/Spacing";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getEmailSettings,
  getPushSettings,
  sendTestEmailNotification,
  sendTestNotification,
  updateEmailNotificationSettings,
  updatePushNotificationSettings,
} from "../../api/services/settings";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import UserContext from "../../context/user/user-context";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function NitificationSettings() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const submitEmailRef = useRef();
  const submitPushRef = useRef();

  // email notification schema
  const formSchema = Yup.object().shape({
    email: Yup.bool(),
    email_new_member: Yup.bool(),
    email_subscribe: Yup.bool(),
    email_resubscribe: Yup.bool(),
    email_post_publish: Yup.bool(),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register: registerEmailSettings,
    handleSubmit: handleEmailSubmit,
    watch: emailSettingsWatch,
    setValue: setEmailValue,
    getValues: getEmailValue,
    formState: { errors },
  } = useForm(formOptions);

  // push notification schema
  const formSchemaPush = Yup.object().shape({
    push: Yup.bool(),
    push_new_member: Yup.bool(),
    push_subscribe: Yup.bool(),
    push_resubscribe: Yup.bool(),
    push_post_publish: Yup.bool(),
  });
  const formOptionsPush = { resolver: yupResolver(formSchemaPush) };
  const {
    register: registerPushSettings,
    handleSubmit: handlePushSubmit,
    watch: pushSettingsWatch,
    setValue: setPushValue,
    getValues: getPushValue,
    formState: {},
  } = useForm(formOptionsPush);

  // get push settings
  const getPushSetting = async () => {
    const res = await getPushSettings();
    const {
      push,
      push_new_member,
      push_subscribe,
      push_resubscribe,
      push_post_publish,
    } = res.data.data;
    setPushValue("push", push === 1 ? true : false);
    setPushValue("push_new_member", push_new_member === 1 ? true : false);
    setPushValue("push_subscribe", push_subscribe === 1 ? true : false);
    setPushValue("push_resubscribe", push_resubscribe === 1 ? true : false);
    setPushValue("push_post_publish", push_post_publish === 1 ? true : false);
  };

  // get email settings
  const getEmailSetting = async () => {
    setLoading(true);
    const res = await getEmailSettings();
    const {
      email,
      email_new_member,
      email_subscribe,
      email_resubscribe,
      email_post_publish,
    } = res.data.data;
    setEmailValue("email", email === 1 ? true : false);
    setEmailValue("email_new_member", email_new_member === 1 ? true : false);
    setEmailValue("email_subscribe", email_subscribe === 1 ? true : false);
    setEmailValue("email_resubscribe", email_resubscribe === 1 ? true : false);
    setEmailValue(
      "email_post_publish",
      email_post_publish === 1 ? true : false
    );
    setLoading(false);
  };

  // swith push
  const switchPush = () => {
    const push = getPushValue("push");
    setPushValue("push_new_member", push ? true : false);
    setPushValue("push_subscribe", push ? true : false);
    setPushValue("push_resubscribe", push ? true : false);
    setPushValue("push_post_publish", push ? true : false);
  };

  // swith email
  const switchEmail = () => {
    const email = getEmailValue("email");
    setEmailValue("email_new_member", email ? true : false);
    setEmailValue("email_subscribe", email ? true : false);
    setEmailValue("email_resubscribe", email ? true : false);
    setEmailValue("email_post_publish", email ? true : false);
  };

  // update email settings
  const updateEmailSettings = async (data) => {
    await updateEmailNotificationSettings(data);
  };

  // update push settings
  const updatePushSettings = async (data) => {
    await updatePushNotificationSettings(data);
  };

  // send test email
  const sendTestEmail = async () => {
    await toast.promise(sendTestEmailNotification(user.email), {
      pending: "Test email sending...",
      success: "Test email successfully send",
      error: "Something went wrong 🤯",
    });
  };

  // send test notification
  const sendTestPushNotification = async () => {
    const title = `Test Notification`;
    const body = `Dear Influencers, We are excited to announce the launch of Gorudo's new influencer platform`;
    await toast.promise(sendTestNotification(title, body, user.email), {
      pending: "Test push notification sending...",
      success: "Test push notification successfully send",
      error: "Something went wrong 🤯",
    });
  };

  useEffect(() => {
    getEmailSetting();
    getPushSetting();
  }, []);

  useEffect(() => {
    const subscription = emailSettingsWatch(async (value) => {
      submitEmailRef.current.click();
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = pushSettingsWatch(async (value) => {
      submitPushRef.current.click();
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className={`cs-container_md ${loading ? "cs-loading_wrap" : ""}`}>
      {loading && <LoadingSpinner />}
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: "22px", marginBottom: "0", fontWeight: "600" }}>
        {t("notification_settings")}
      </h2>
      <Spacing lg="30" md="30" />
      <div className="cs-notice_section_heading">
        <h3>{t("nitification_settings_title_1")}</h3>
        <div>
          <label className="cs-switch" onChange={switchEmail}>
            <input type="checkbox" {...registerEmailSettings("email")} />
            <span></span>
          </label>
        </div>
      </div>
      <form
        onSubmit={handleEmailSubmit(updateEmailSettings)}
        className="cs-row"
      >
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerEmailSettings("email_new_member")}
            />
            <label>{t("nitification_settings_item_1")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerEmailSettings("email_subscribe")}
            />
            <label>{t("nitification_settings_item_2")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerEmailSettings("email_resubscribe")}
            />
            <label>{t("nitification_settings_item_3")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerEmailSettings("email_post_publish")}
            />
            <label>{t("nitification_settings_item_4")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <button
          ref={submitEmailRef}
          style={{ display: "none" }}
          type="submit"
        ></button>
      </form>
      <Spacing lg="35" md="15" />
      <div className="cs-section_heading_3">
        <div className="cs-section_heading_left">
          <h2
            className="cs-section_title"
            style={{ fontSize: "16px", fontWeight: "500" }}
          >
            {t("check_email_notification")}
          </h2>
        </div>
        <div className="cs-section_heading_right">
          <button onClick={sendTestEmail} className="cs-section_btn">
            {t("send_a_test_mail")}
          </button>
        </div>
      </div>

      <Spacing lg="65" md="40" />
      <div className="cs-notice_section_heading">
        <h3>{t("nitification_settings_title_2")}</h3>
        <div>
          <label className="cs-switch" onChange={switchPush}>
            <input type="checkbox" {...registerPushSettings("push")} />
            <span></span>
          </label>
        </div>
      </div>
      <form onSubmit={handlePushSubmit(updatePushSettings)} className="cs-row">
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerPushSettings("push_new_member")}
            />
            <label>{t("nitification_settings_item_1")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerPushSettings("push_subscribe")}
            />
            <label>{t("nitification_settings_item_2")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerPushSettings("push_resubscribe")}
            />
            <label>{t("nitification_settings_item_3")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <div className="cs-col_4">
          <div className="cs-check">
            <input
              type="checkbox"
              {...registerPushSettings("push_post_publish")}
            />
            <label>{t("nitification_settings_item_4")}</label>
          </div>
          <Spacing lg="15" md="15" />
        </div>
        <button
          ref={submitPushRef}
          style={{ display: "none" }}
          type="submit"
        ></button>
      </form>
      <Spacing lg="35" md="15" />
      <div className="cs-section_heading_3">
        <div className="cs-section_heading_left">
          <h2
            className="cs-section_title"
            style={{ fontSize: "16px", fontWeight: "500" }}
          >
            {t("check_push_notifications")}
          </h2>
        </div>
        <div className="cs-section_heading_right">
          <button
            onClick={sendTestPushNotification}
            className="cs-section_btn"
            style={{ textTransform: "uppercase" }}
          >
            {t("check")}
          </button>
        </div>
      </div>
      <Spacing lg="45" md="45" />
    </div>
  );
}
