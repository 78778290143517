import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function TemplateType({ type, seType }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="cs-catd cs-style12">
        <h2 style={{ fontSize: '20px', marginBottom: '0' }}>
          {t('post_your_newsletter')}
        </h2>
        <p
          style={{
            fontSize: '14px',
            lineHeight: '1.6em',
            marginBottom: '25px',
          }}
        >
          {t('choose_template')}
        </p>
        <div className="cs-card_in">
          <div>
            <span
              onClick={() => seType('text-only')}
              className={
                type === 'text-only' ? 'cs-card_item active' : 'cs-card_item'
              }
            >
              <div className="cs-card_icon">
                <b>A</b>a
              </div>
              <div className="cs-card_title">{t('article')}</div>
            </span>
          </div>
          <div>
            <span
              onClick={() => seType('image')}
              className={
                type === 'image' ? 'cs-card_item active' : 'cs-card_item'
              }
            >
              <div className="cs-card_icon" style={{ fontSize: '26px' }}>
                <Icon icon="mdi:image-area" />
              </div>
              <div className="cs-card_title">{t('image')}</div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
