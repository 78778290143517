import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser, switchRole } from '../../api/services/auth/user';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import LoadingSpinner from '../Common/LoadingSpinner';

export default function UserDropdown({
  user,
  handelProfileToggle,
  profileToggle,
  setProfileToggle,
}) {
  const { t } = useTranslation();
  const refOne = useRef(null);
  const navigate = useNavigate();
  const [roleSwitchLoading, setRoleSwitchLoading] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);

  // handle logout
  const handleLogout = async () => {
    setLogoutLoading(true);
    await logoutUser({ type: '1', device: 3 });
    setLogoutLoading(false);
    localStorage.removeItem('token');
    localStorage.removeItem('isAuth');
    navigate('/auth/login');
  };

  // handle switch role
  const handleSwitchRole = async () => {
    setRoleSwitchLoading(true);
    await switchRole();
    // setRoleSwitchLoading(false);
    const token = localStorage.getItem('token');
    const redirectUrl = `https://member.gorudo.io/role-switch?token=${token}`;
    window.location.href = redirectUrl;
  };

  // Hide on outside click
  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setProfileToggle(false);
    }
  };
  useEffect(() => {
    // event listeners
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);
  return (
    <div className="cs-dropdown_wrap cs-user" ref={refOne}>
      <button className="cs-user_btn" onClick={handelProfileToggle}>
        <img src={user.image ?? '/images/user1.png'} alt="User" />
        <svg
          style={{
            transform: `${profileToggle ? 'rotate(180deg)' : 'rotate(0deg)'}`,
          }}
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.0752 1L6.40853 6.33333L11.7419 1"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {profileToggle && (
        <div className="cs-dropdown cs-user_dropdown">
          <div className="cs-dropdown_info">
            <div className="cs-dropdown_info_img">
              <img src={user.image ?? '/images/user1.png'} alt="User" />
            </div>
            <div className="cs-dropdown_info_right">
              <h3>{user.name_show}</h3>
              <span class="cs-header_label">{t('creator')}</span>
            </div>
          </div>
          <ul className="cs-mp0 cs-medium">
            <li>
              <Link to="/settings/my-profile">{t('my_profile')}</Link>
            </li>
            <li
              onClick={handleSwitchRole}
              style={{ cursor: 'pointer' }}
              className={roleSwitchLoading ? 'cs-loading_wrap' : ''}
            >
              {roleSwitchLoading && (
                <>
                  <LoadingSpinner />
                </>
              )}
              <a>{t('switch_to_member')}</a>
              <label className="cs-switch">
                <input type="checkbox" />
                <span></span>
              </label>
            </li>
            <li>
              <Link to="/settings/earning-dashboard">
                {t('transaction_history')}
              </Link>
            </li>
          </ul>
          <div className="cs-dropdown_footer">
            <button onClick={handleLogout} className="cs-dropdown_footer_btn">
              {logoutLoading ? `${t('logging_out')}` : `${t('logout')}`}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
