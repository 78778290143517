import React, { useContext, useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewsletterBar from "../../../components/NewsletterComp/NewsletterBar";
import Spacing from "../../../components/Spacing";
import TextEditor from "../../../components/TextEditor";
import TagInput from "../../../components/Common/TagInput";
import { removeHtml } from "../../../utils/removeHtml";
import { textLimit } from "../../../utils/TextLimit";
import { useBeforeunload } from "react-beforeunload";

import {
  deleteNewsletter,
  getNewsletter,
  updateNewsletter,
  uploadNewsletterImage,
} from "../../../api/services/newsletter";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getFileName } from "../../../utils/getFileName";
import SvgIcon from "../../../components/Common/SvgIcon";
import { checkFileExt } from "../../../utils/checkFileExt";
import TemplateType from "./components/TemplateType";
import { useTranslation } from "react-i18next";
import { getUserTierPermission } from "../../../api/services/auth/user";

export default function NewsletterEdit() {
  const { t } = useTranslation();
  let { id: post_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showTierWarning, setShowTierWarning] = useState(false);
  const [file, setFile] = useState({
    name: "",
    url: "",
  });
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState("1");
  const [attachments, setAttachments] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isTeaserChanged, setIsTeaserChanged] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [attchLoading, setAttachLoading] = useState(false);
  const [templateType, setTemplateType] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [isSchedule, setisSchedule] = useState("0");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [redirectPreview, setRedirectPreview] = useState(false);
  const [selectedTiers, setSelectedTiers] = useState([]);
  const submitRef = useRef();
  const [tiers, setTiers] = useState([]);

  const formSchema = Yup.object().shape({
    post_id: Yup.string(),
    title: Yup.string(),
    sub_title: Yup.string(),
    short_desc: Yup.string(),
    detail: Yup.string(),
    image: Yup.string(),
    file: Yup.string(),
    tag: Yup.string(),
    visibility: Yup.string(),
    tiers: Yup.mixed(),
    teaser: Yup.string(),
    template_type: Yup.string(),
    schedule_date: Yup.string(),
    schedule_time: Yup.string(),
    is_schedule: Yup.string(),
    is_draft: Yup.string(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm(formOptions);
  const handelChange = async (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    const res = await uploadNewsletterImage(formData);
    setFile({ name: res.data.data?.split("/").pop(), url: res.data.data });
    setValue("image", res.data.data);
  };
  const getUserTier = async () => {
    const res = await getUserTierPermission(6);
    setTiers(res.data.data);
  };
  // handle browser close
  useBeforeunload((event) => {
    if (isChanged) {
      event.preventDefault();
    }
  });

  const handelUploadCross = () => {
    setFile({
      url: "",
      name: "",
    });
    setValue("image", "");
  };

  const getSingleNewsletter = async () => {
    setLoading(true);
    const res = await getNewsletter(post_id);
    setTemplateType(res.data.data.template_type);
    const {
      id,
      title,
      sub_title,
      short_desc,
      detail,
      image,
      files,
      tag,
      visibility,
      tiers,
      teaser,
      schedule_date,
      schedule_time,
      schedule_datetime,
      is_schedule,
      is_draft,
      template_type,
    } = res.data.data;
    setValue("post_id", id);
    setValue("title", title);
    setValue("sub_title", sub_title);
    setValue("short_desc", short_desc);
    setValue("detail", detail);
    setDescription(detail);
    setValue("image", image);
    setValue("files", files);
    setValue("tag", tag);
    setTags(tag ? tag.split(", ") : []);
    setValue("visibility", visibility);
    setValue("tiers", tiers ? tiers.split(", ") : []);

    setSelectedTiers(
      tiers ? tiers?.split(", ")?.map((str) => parseInt(str)) : []
    );
    setValue("teaser", teaser);
    setValue("schedule_date", schedule_date);
    setValue("schedule_time", schedule_time);
    setValue("is_schedule", is_schedule);
    setValue("schedule_datetime", schedule_datetime);
    setisSchedule(is_schedule.toString());
    setValue("is_draft", is_draft);
    setValue("template_type", template_type);
    setFile({ name: image?.split("/").pop(), url: image });
    setSelected(visibility);
    setAttachments(files ? files.split(", ") : []);
    setLoading(false);
  };

  // handle Attachments
  const handleAttachments = async (e) => {
    const selectedFiles = e.target.files;
    let attachmentsList = [];
    setAttachLoading(true);
    for (let i = 0; i < selectedFiles.length; i++) {
      let formdata = new FormData();
      formdata.append("image", selectedFiles[i]);
      const res = await uploadNewsletterImage(formdata);
      attachmentsList.push(res.data.data);
      setAttachments([...attachments, ...attachmentsList]);
    }
    setAttachLoading(false);
  };

  // remove single attachments
  const removeAttachment = (e) => {
    const newAttachments = attachments.filter((item) => item !== e);
    setAttachments(newAttachments);
  };

  // handle update
  const handleUpdate = async (data) => {
    if (isChanged) {
      data.tiers = selectedTiers ? selectedTiers.join(", ") : "";
      data.files = attachments ? attachments.join(", ") : "";
      data.short_desc = textLimit(removeHtml(description), 100);
      data.template_type = templateType;
      if (
        data.is_schedule === "1" &&
        data.schedule_date !== "" &&
        data.schedule_time
      ) {
        const scheduleTime = new Date(
          `${data.schedule_date}:${data.schedule_time}`
        ).toUTCString();

        const dateObj = new Date(scheduleTime);

        // Extract the date components
        const year = dateObj.getUTCFullYear();
        const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getUTCDate()).padStart(2, "0");

        // Extract the time components
        const hours = String(dateObj.getUTCHours()).padStart(2, "0");
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

        // Form the date and time strings
        data.schedule_datetime = `${year}-${month}-${day} ${hours}:${minutes}`;
      }
      setSaveLoading(true);
      const res = await updateNewsletter(data);
      setSaveLoading(false);
      if (res.data.success === 1) {
        setLastUpdated(res.data.data.updated_at);
      }
      setIsChanged(false);
    }
    if (redirectPreview) {
      navigate(`/newsletter/${post_id}`);
    }
  };

  // handle blur
  const handleBlur = () => {
    submitRef.current && submitRef.current.click();
    if (!isTeaserChanged) {
      setValue("teaser", textLimit(removeHtml(description), 180));
    }
  };

  const handleTierCheckboxChange = (e, tierId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedTiers([...selectedTiers, tierId]); // Add the selected tier to the array
    } else {
      setSelectedTiers(selectedTiers.filter((id) => id !== tierId)); // Remove the deselected tier from the array
    }
  };

  // delete post
  const handleDelete = async () => {
    setDeleteLoading(true);
    const res = await deleteNewsletter(post_id);
    setDeleteLoading(false);
    if (res.data.success === 1) {
      navigate("/newsletter");
    }
  };

  // preview post
  const preview = async () => {
    if (selected === "3" && selectedTiers.length === 0) {
      setShowTierWarning(true);
    } else {
      setShowTierWarning(false);
      setRedirectPreview(true);
      submitRef.current && submitRef.current.click();
    }
  };

  // set tag
  useEffect(() => {
    setValue("tag", tags.join(", "));
  }, [tags]);

  useEffect(() => {
    getUserTier();
    getSingleNewsletter();
  }, []);

  // track any data change or not
  useEffect(() => {
    setIsChanged(true);
  }, [
    watch("title"),
    watch("detail"),
    watch("tag"),
    watch("image"),
    watch("files"),
    watch("visibility"),
    watch("teaser"),
    watch("schedule_date"),
    watch("schedule_time"),
    watch("is_schedule"),
    attachments,
    selected,
    templateType,
    selectedTiers,
  ]);

  return (
    <>
      {loading ? (
        <div className={`cs-container_md ${loading ? "cs-loading_wrap" : ""}`}>
          {loading && (
            <>
              <Spacing lg="40" md="40" />
              <LoadingSpinner />
            </>
          )}
        </div>
      ) : (
        <div className="cs-container_md">
          <Spacing lg="40" md="40" />
          <Link to="/newsletter" className="cs-bak_list_btn cs-semibold">
            <>
              <Icon icon="ph:arrow-left-duotone" />
              <span>{t("back")}</span>
            </>
          </Link>
          <Spacing lg="10" md="10" />
          <form
            onSubmit={handleSubmit(handleUpdate)}
            onClick={handleBlur}
            className="cs-layout_1"
          >
            <div className="cs-layout_left">
              <TemplateType
                type={templateType}
                seType={(e) => setTemplateType(e)}
              />
              <Spacing lg="60" md="60" />
              {templateType === "image" && (
                <>
                  <div className="newsletter_up_thumb_wrap">
                    <h2
                      className="cs-semibold"
                      style={{ fontSize: "16px", marginBottom: "10px" }}
                    >
                      {t("upload_feature_image")}
                    </h2>
                    <div className="upload_input">
                      <svg
                        width={32}
                        height={43}
                        viewBox="0 0 32 43"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.2126 17.5305L7.91846 17.5522"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                        <path
                          d="M0.197754 2.85498C0.197754 1.75041 1.09318 0.85498 2.19775 0.85498H19.9502C20.4851 0.85498 20.9976 1.0692 21.3734 1.44978L25.9332 6.06785L30.5035 10.6965C30.873 11.0708 31.0803 11.5757 31.0803 12.1017V40.558C31.0803 41.6625 30.1849 42.558 29.0803 42.558H2.19775C1.09318 42.558 0.197754 41.6625 0.197754 40.558V2.85498Z"
                          fill="#092C4C"
                        />
                        <path
                          d="M23.3597 34.2173H7.91846"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                        <path
                          d="M23.3597 25.877H7.91846"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                      <div className="upload_output_right">
                        <h3 className="cs-m0">
                          Drag and drop here <br /> Any JPG, PNG or GIF up to
                          512MB <br /> or
                        </h3>
                        <p className="cs-m0">Browse</p>
                      </div>
                      <input
                        onChange={handelChange}
                        type="file"
                        accept="image/*"
                      />
                    </div>
                    {file.name && (
                      <div className="upload_output">
                        <div className="upload_output_img">
                          <img src={file.url} alt="Thumb" />
                        </div>
                        <h4 className="m0 f18">{file.name && file.name}</h4>
                        <span
                          className="upload_output_close"
                          onClick={handelUploadCross}
                        >
                          <Icon icon="gg:close-o" />
                        </span>
                      </div>
                    )}
                  </div>
                  <Spacing lg="60" md="60" />
                </>
              )}
              <TextEditor
                value={description}
                setValue={(e) => {
                  setDescription(e);
                  setValue("detail", e);
                }}
                register={register}
                ReactQuillPlaceholder={t("enter_your_newsletter")}
              />
              <Spacing lg="20" md="20" />
              <h2
                className="cs-semibold"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "20px",
                  marginBottom: "10px",
                  textTransform: "uppercase",
                }}
              >
                <Icon icon="material-symbols:attach-file" />
                {t("attachments")}
              </h2>
              <div
                className={`cs-file ${attchLoading ? "cs-loading_wrap" : ""}`}
              >
                {attchLoading && (
                  <>
                    <Spacing lg="50" md="50" />
                    <LoadingSpinner />
                  </>
                )}
                <input type="file" onChange={handleAttachments} multiple />
                <label className="cs-file_label">Browser</label>
              </div>
              <Spacing lg="20" md="20" />
              <ul className="cs-attachment_list cs-mp0">
                {attachments.map((item, index) => (
                  <li key={item}>
                    <SvgIcon name={checkFileExt(item)} />
                    {getFileName(item)}
                    <span onClick={() => removeAttachment(item)}>
                      <Icon icon="material-symbols:delete-rounded" />
                    </span>
                  </li>
                ))}
              </ul>
              <Spacing lg="25" md="25" />
              <hr />
              <TagInput tags={tags} setTags={setTags} />
            </div>
            <div className="cs-layout_right">
              <div className="cs-newsletter_sidebar">
                <div>
                  <NewsletterBar />
                  <Spacing lg="30" md="30" />
                  <h3
                    className="cs-semibold"
                    style={{ fontSize: "20px", marginBottom: "15px" }}
                  >
                    {t("tiers_who_can_see_this_newsletter")}:
                  </h3>
                  <ul className="cs-list cs-style2 cs-mp0">
                    <li>
                      <div
                        className={
                          selected === "1"
                            ? "cs-list_item active"
                            : "cs-list_item"
                        }
                        onClick={() => {
                          setSelected("1");
                          setValue("visibility", "1");
                          setShowTierWarning(false);
                        }}
                      >
                        <span></span>
                        {t("public_profile")}
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          selected === "4"
                            ? "cs-list_item active"
                            : "cs-list_item"
                        }
                        onClick={() => {
                          setSelected("4");
                          setValue("visibility", "4");
                          setShowTierWarning(false);
                        }}
                      >
                        <span></span>
                        {t("all_tiers_including_free_tier")}
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          selected === "3"
                            ? "cs-list_item active"
                            : "cs-list_item"
                        }
                        onClick={() => {
                          setSelected("3");
                          setValue("visibility", "3");
                          setShowTierWarning(false);
                        }}
                      >
                        <span></span>
                        {t("select_tier")}
                      </div>
                      {selected === "3" && (
                        <div style={{ paddingLeft: "30px" }}>
                          <Spacing lg="10" md="10" />
                          <h2
                            className="cs-medium"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "18px",
                              marginBottom: "10px",
                            }}
                          >
                            {t("select_which_tiers_have_access")}
                          </h2>
                          <ul className="cs-mp0">
                            {tiers.map((item, index) => (
                              <li key={`tier-${index}`}>
                                <div className="cs-check">
                                  <input
                                    type="checkbox"
                                    value={item.id}
                                    checked={selectedTiers?.includes(item.id)} // Check if the item's id is in the selectedTiers array
                                    onChange={(e) =>
                                      handleTierCheckboxChange(e, item.id)
                                    } // Call a function to update the selected tiers
                                  />
                                  <label>{item.title}</label>
                                </div>
                              </li>
                            ))}
                            {showTierWarning && (
                              <span className="cs-danger">
                                {t("tier_warning_text")}
                              </span>
                            )}
                          </ul>
                          {/* <Spacing lg="15" md="15" />
                          <h2
                            className="cs-medium"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "18px",
                              marginBottom: "10px",
                            }}
                          >
                            <Icon
                              icon="gala:file-document"
                              style={{ marginRight: "5px" }}
                            />
                            Teaser Text
                          </h2>
                          <textarea
                            cols="30"
                            rows="7"
                            maxLength="180"
                            className="cs-teaser_input"
                            placeholder="Add text preview for public profile..."
                            {...register("teaser")}
                            onChange={() => setIsTeaserChanged(true)}
                          ></textarea> */}
                          <Spacing lg="5" md="10" />
                        </div>
                      )}
                    </li>
                  </ul>
                  {/* <Spacing lg="30" md="30" /> */}
                </div>
                <div>
                  {(selected === "4" || selected === "3") && (
                    <div>
                      <Spacing lg="10" md="10" />
                      <h2
                        className="cs-medium"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "18px",
                          marginBottom: "10px",
                        }}
                      >
                        <Icon
                          icon="gala:file-document"
                          style={{ marginRight: "5px" }}
                        />
                        {t("teaser_text")}
                      </h2>
                      <textarea
                        cols="30"
                        rows="7"
                        maxLength="180"
                        className="cs-teaser_input"
                        placeholder={t("add_text_preview_for_public_profile")}
                        {...register("teaser")}
                        onChange={() => setIsTeaserChanged(true)}
                      ></textarea>
                      <Spacing lg="5" md="10" />
                    </div>
                  )}
                  <ul
                    className="cs-list cs-style2 cs-mp0"
                    style={{ marginTop: "60px" }}
                  >
                    <li>
                      <div
                        className={
                          isSchedule === "1"
                            ? "cs-list_item active"
                            : "cs-list_item"
                        }
                        onClick={() => {
                          if (isSchedule === "0") {
                            setisSchedule("1");
                            setValue("is_schedule", "1");
                          } else {
                            setValue("is_schedule", "0");
                            setisSchedule("0");
                          }
                        }}
                      >
                        <span></span>
                        {t("schedule_post")}
                      </div>
                      {isSchedule === "1" && (
                        <>
                          <Spacing lg="10" md="10" />
                          <div className="cs-date_time">
                            <input
                              type="date"
                              {...register("schedule_date")}
                              className="cs-form_field_1"
                            />
                            <input
                              {...register("schedule_time")}
                              type="time"
                              className="cs-form_field_1"
                            />
                          </div>
                        </>
                      )}
                    </li>
                  </ul>
                  <Spacing lg="30" md="30" />
                  <div className="cs-save_preview_btns">
                    <button
                      className={`cs-btn cs-color4 ${
                        saveLoading ? "cs-loading_wrap" : ""
                      }`}
                      disabled={saveLoading}
                      style={{ pointerEvents: isChanged ? "" : "none" }}
                    >
                      {saveLoading && (
                        <span className="cs-btn_loading">
                          <Icon icon="la:spinner" />
                          {t("loading")}
                        </span>
                      )}
                      {t("save_as_draft")}
                    </button>
                    <button onClick={preview} className="cs-btn cs-color1">
                      {t("preview")}
                    </button>
                  </div>
                  <Spacing lg="15" md="15" />
                  <div className="cs-text_center">
                    <button
                      className={`cs-btn cs-color2 ${
                        deleteLoading ? "cs-loading_wrap" : ""
                      }`}
                      style={{ maxWidth: "170px", width: "100%" }}
                      onClick={() => handleDelete()}
                    >
                      {deleteLoading && (
                        <span className="cs-btn_loading">
                          <Icon icon="la:spinner" />
                          {t("loading")}
                        </span>
                      )}
                      {t("delete_post")}
                    </button>
                    <button
                      type="submit"
                      ref={submitRef}
                      style={{ display: "none" }}
                    ></button>
                  </div>
                </div>

                {/* {lastUpdated && (
                  <>
                    <Spacing lg="15" md="15" />
                    <div style={{ textAlign: "center" }}>
                      <span className="cs-success">
                        Last saved {moment(lastUpdated).fromNow()}
                      </span>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </form>
          <Spacing lg="50" md="50" />
        </div>
      )}
    </>
  );
}
