import { loginUrl } from "../../../const";
import axios from "../../../lib/axios";

export const login = async (data) => {
  const res = await axios.post(loginUrl, data);

  // save login token to localstoarge
  if (res.data.success === 1) {
    localStorage.setItem("token", res.data.token);
    localStorage.setItem("isAuth", true);
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuth");
  }
  return res;
};
