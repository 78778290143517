import {
  createTradeDiaryTemplateUrl,
  createTradeDiaryUrl,
  getTradeDiaryListUrl,
  tradeDiaryDeleteUrl,
  tradeDiaryListUrl,
  tradeDiaryPinListUrl,
  tradeDiaryTemplateDeleteUrl,
  tradeDiaryTemplateListUrl,
  tradeDiaryTemplateUpdateUrl,
  tradeDiaryUpdateUrl,
  updateTradeDiaryPinUrl,
} from "../../const";
import axios from "../../lib/axios";

// get trade diary type
export const getTradeDiaryType = async () => {
  return await axios.get(tradeDiaryListUrl);
};

// create tradediary
export const createTradeDiary = async (data) => {
  return await axios.post(createTradeDiaryUrl, data);
};

// create trade diary template
export const createTradeDiaryTemplate = async (data) => {
  return await axios.post(createTradeDiaryTemplateUrl, data);
};

// get trade diary list
export const getTradeDiaryList = async (
  page = 1,
  limit = 5,
  search = "",
  filter = "",
  post_id = ""
) => {
  return await axios.get(getTradeDiaryListUrl, {
    params: {
      page,
      limit,
      search,
      filter,
      post_id,
    },
  });
};

// update trade diary pin
export const updateTradeTradeDiaryPin = async (data) => {
  return await axios.put(updateTradeDiaryPinUrl, data);
};

// get trade diary pin list
export const getTradeDiaryPinList = async (page = 1, limit = 5) => {
  return await axios.get(tradeDiaryPinListUrl, { params: { page, limit } });
};

// delete trade diary
export const deleteTradeDiary = async (post_id) => {
  return await axios.delete(tradeDiaryDeleteUrl, { params: { post_id } });
};

// update trade diary
export const updateTradeDiary = async (data) => {
  return await axios.put(tradeDiaryUpdateUrl, data);
};

// get tradediary template list
export const getTradeDiaryTemplateList = async (
  page = 1,
  limit = 5,
  search
) => {
  return await axios.get(tradeDiaryTemplateListUrl, {
    params: { page, limit, search },
  });
};

// delete trade diary template
export const deleteTradeDiaryTemplate = async (post_id) => {
  return await axios.delete(tradeDiaryTemplateDeleteUrl, {
    params: { post_id },
  });
};

// trade diary template update
export const tradeDiaryTemplateUpdate = async (data) => {
  return await axios.put(tradeDiaryTemplateUpdateUrl, data);
};
