import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../api/services/auth/user';
import { getMissingInfo } from '../../api/services/dashboard';
import PlanContext from '../../context/plan/plan-context';
import Card3 from '../Card/Card3';
import Spacing from '../Spacing';
import { useTranslation } from 'react-i18next';

export default function DashboardSideNav() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const navigate = useNavigate();
  const { features } = useContext(PlanContext);
  const [missingInfo, setMissingInfo] = useState([]);
  // get mission info
  const getOnboardingMissingInfo = async () => {
    const res = await getMissingInfo();
    setMissingInfo(res.data.data);
  };

  // handle logout
  const handleLogout = async () => {
    setLogoutLoading(true);
    await logoutUser({ type: '2', device: 3 });
    setLogoutLoading(false);
    localStorage.removeItem('token');
    localStorage.removeItem('isAuth');
    navigate('/auth/login');
  };

  useEffect(() => {
    getOnboardingMissingInfo();
  }, []);
  return (
    <>
      <button className="cs-side_nav_toggle" onClick={() => setOpen(!open)}>
        <Icon icon="ic:round-menu" />
      </button>
      <div
        className={open ? 'cs-side_nav_overlay active' : 'cs-side_nav_overlay'}
        onClick={() => setOpen(false)}
      ></div>
      <div className={open ? 'cs-side_nav active' : 'cs-side_nav'}>
        <div className="cs-side_nav_top">
          <Card3 />
          <Spacing lg="20" md="20" />
          {missingInfo[1]?.status === 0 && (
            <>
              <div className="cs-update_card">
                <p>{t('please_update_your_membership_tier')}</p>
                <Link
                  to="/settings/modify-membership-tiers"
                  href=""
                  style={{ textTransform: 'uppercase' }}
                >
                  {t('update')}
                </Link>
              </div>
              {/* <div className="cs-update_bulet">
            <button></button>
            <button className="active"></button>
          </div> */}
              <Spacing lg="30" md="30" />
            </>
          )}
          <ul className="cs-side_nav_list">
            <li>
              <NavLink to="/" onClick={() => setOpen(false)}>
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={19}
                      height={20}
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.23077 1.5H2.30769C1.58547 1.5 1 2.08547 1 2.80769V6.73077C1 7.45299 1.58547 8.03846 2.30769 8.03846H6.23077C6.95299 8.03846 7.53846 7.45299 7.53846 6.73077V2.80769C7.53846 2.08547 6.95299 1.5 6.23077 1.5Z"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.6922 1.5H12.7691C12.0469 1.5 11.4614 2.08547 11.4614 2.80769V6.73077C11.4614 7.45299 12.0469 8.03846 12.7691 8.03846H16.6922C17.4144 8.03846 17.9999 7.45299 17.9999 6.73077V2.80769C17.9999 2.08547 17.4144 1.5 16.6922 1.5Z"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.23077 11.9614H2.30769C1.58547 11.9614 1 12.5469 1 13.2691V17.1922C1 17.9144 1.58547 18.4999 2.30769 18.4999H6.23077C6.95299 18.4999 7.53846 17.9144 7.53846 17.1922V13.2691C7.53846 12.5469 6.95299 11.9614 6.23077 11.9614Z"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.6922 11.9614H12.7691C12.0469 11.9614 11.4614 12.5469 11.4614 13.2691V17.1922C11.4614 17.9144 12.0469 18.4999 12.7691 18.4999H16.6922C17.4144 18.4999 17.9999 17.9144 17.9999 17.1922V13.2691C17.9999 12.5469 17.4144 11.9614 16.6922 11.9614Z"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">{t('dashboard')}</span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink to="/members" onClick={() => setOpen(false)}>
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={20}
                      height={17}
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 7C8.65685 7 10 5.65685 10 4C10 2.34315 8.65685 1 7 1C5.34315 1 4 2.34315 4 4C4 5.65685 5.34315 7 7 7Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 16H7.5H14V15.2418C13.9885 14.1757 13.6981 13.13 13.1561 12.202C12.614 11.2742 11.8378 10.4944 10.9001 9.93568C9.96242 9.37695 8.89376 9.05747 7.79397 9.00705C7.69592 9.00256 7.5979 9.00021 7.5 9C7.4021 9.00021 7.30408 9.00256 7.20603 9.00705C6.10624 9.05747 5.03758 9.37695 4.09988 9.93568C3.16219 10.4944 2.38605 11.2742 1.84395 12.202C1.30186 13.13 1.0115 14.1757 1 15.2418V16Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13 7C14.6568 7 16 5.65685 16 4C16 2.34315 14.6568 1 13 1"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.1429 15H19V14.4458C18.9886 13.6664 18.7014 12.902 18.1653 12.2236C17.6291 11.5454 16.8616 10.9754 15.9341 10.5669C15.3347 10.3029 14.681 10.112 14 10"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">{t('members')}</span>
                </>
              </NavLink>
            </li>
            {features.indexOf('Broadcast') !== -1 && (
              <li>
                <NavLink to="/broadcasts" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.9999 10.1538C13.786 11.3677 12.1396 12.0497 10.4229 12.0497C8.70626 12.0497 7.05989 11.3677 5.84602 10.1538C4.63214 8.93997 3.9502 7.2936 3.9502 5.57692C3.9502 3.86024 4.63214 2.21388 5.84602 1L14.9999 10.1538Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.4336 5.56625L13.3844 2.61548"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.72615 8.61426L1 15.0004H8L6.83692 10.9404"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">{t('broadcasts')}</span>
                  </>
                </NavLink>
              </li>
            )}
            {features.indexOf('Trade Diary') !== -1 && (
              <li>
                <NavLink to="/trade-diary" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={15}
                        height={15}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.96978 7.39764C7.82146 7.46205 7.66148 7.49528 7.49978 7.49528C7.33808 7.49528 7.1781 7.46205 7.02978 7.39764L1.32978 4.75764C1.23722 4.71096 1.15944 4.6395 1.10509 4.55122C1.05075 4.46294 1.02197 4.36131 1.02197 4.25764C1.02197 4.15398 1.05075 4.05234 1.10509 3.96407C1.15944 3.87579 1.23722 3.80433 1.32978 3.75764L7.02978 1.09764C7.1781 1.03323 7.33808 1 7.49978 1C7.66148 1 7.82146 1.03323 7.96978 1.09764L13.6698 3.73764C13.7623 3.78433 13.8401 3.85579 13.8945 3.94407C13.9488 4.03234 13.9776 4.13398 13.9776 4.23764C13.9776 4.34131 13.9488 4.44294 13.8945 4.53122C13.8401 4.6195 13.7623 4.69096 13.6698 4.73764L7.96978 7.39764Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 7.84766L7.9 10.6577C7.76972 10.7171 7.62819 10.7478 7.485 10.7478C7.34181 10.7478 7.20028 10.7171 7.07 10.6577L1 7.84766"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 11.0977L7.9 13.9077C7.76972 13.9671 7.62819 13.9978 7.485 13.9978C7.34181 13.9978 7.20028 13.9671 7.07 13.9077L1 11.0977"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">{t('trade_diary')}</span>
                  </>
                </NavLink>
              </li>
            )}
            {features.indexOf('Watchlist') !== -1 && (
              <li>
                <NavLink to="/watchlists" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={15}
                        height={20}
                        viewBox="0 0 15 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.1 4.1001H2.3C1.58203 4.1001 1 4.68213 1 5.4001V17.1001C1 17.8181 1.58203 18.4001 2.3 18.4001H10.1C10.818 18.4001 11.4 17.8181 11.4 17.1001V5.4001C11.4 4.68213 10.818 4.1001 10.1 4.1001Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.6001 7.3501H8.8001"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.6001 10.6001H8.8001"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.6001 13.8501H6.2001"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.25 1.5H12.7C13.0448 1.5 13.3754 1.63696 13.6192 1.88076C13.863 2.12456 14 2.45522 14 2.8V15.15"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">{t('watchlists')}</span>
                  </>
                </NavLink>
              </li>
            )}
            {features.indexOf('Open Position') !== -1 && (
              <li>
                <NavLink to="/open-position" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={17}
                        height={17}
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16 16H1"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.231 6.76932V11.9616C10.231 12.5989 10.7476 13.1155 11.3848 13.1155H13.6925C14.3297 13.1155 14.8463 12.5989 14.8463 11.9616V6.76932C14.8463 6.13207 14.3297 5.61548 13.6925 5.61548H11.3848C10.7476 5.61548 10.231 6.13207 10.231 6.76932Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.15381 2.15394L2.15381 11.9616C2.15381 12.5989 2.6704 13.1155 3.30765 13.1155H5.61535C6.2526 13.1155 6.76919 12.5989 6.76919 11.9616L6.76919 2.15394C6.76919 1.51669 6.2526 1.00009 5.61535 1.00009H3.30765C2.6704 1.00009 2.15381 1.51669 2.15381 2.15394Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">
                      {t('open_position')}
                    </span>
                  </>
                </NavLink>
              </li>
            )}
            {features.indexOf('Newsletter') !== -1 && (
              <li>
                <NavLink to="/newsletter" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={17}
                        height={15}
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 13.9999C6.8099 12.3872 4.51785 11.3427 2.02694 11.0499C1.74342 11.0227 1.48157 10.9054 1.29193 10.7208C1.10228 10.5361 0.998291 10.2971 1.00002 10.0499V1.99988C1.00001 1.85544 1.0361 1.71271 1.10582 1.58151C1.17553 1.45032 1.27721 1.33377 1.40387 1.23988C1.52829 1.1477 1.67391 1.07952 1.83108 1.03986C1.98824 1.00021 2.15333 0.989988 2.3154 1.00988C4.69727 1.35249 6.87622 2.38126 8.5 3.92988V13.9999Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.5 13.9999C10.1901 12.3872 12.4821 11.3427 14.9731 11.0499C15.2566 11.0227 15.5184 10.9054 15.7081 10.7208C15.8977 10.5361 16.0017 10.2971 16 10.0499V1.99988C16 1.85544 15.9639 1.71271 15.8942 1.58151C15.8245 1.45032 15.7228 1.33377 15.5961 1.23988C15.4717 1.1477 15.3261 1.07952 15.1689 1.03986C15.0118 1.00021 14.8467 0.989988 14.6846 1.00988C12.3027 1.35249 10.1238 2.38126 8.5 3.92988V13.9999Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">{t('newsletter')}</span>
                  </>
                </NavLink>
              </li>
            )}
            {features.indexOf('Webinar / Video') !== -1 && (
              <li>
                <NavLink to="/webinar-video" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={17}
                        height={13}
                        viewBox="0 0 17 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="0.75"
                          width="15.5"
                          height="11.5"
                          rx="3.25"
                          stroke="currentColor"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M12 6.5L6.75 9.53109L6.75 3.46891L12 6.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">
                      {t('webinar_video')}
                    </span>
                  </>
                </NavLink>
              </li>
            )}

            {features.indexOf('Resources + FAQ') !== -1 && (
              <li>
                <NavLink to="/resources-faq" onClick={() => setOpen(false)}>
                  <>
                    <span className="cs-side_nav_icon">
                      <svg
                        width={15}
                        height={15}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 7.5C6 8.12132 5.49632 8.625 4.875 8.625C4.25368 8.625 3.75 8.12132 3.75 7.5C3.75 6.87868 4.25368 6.375 4.875 6.375C5.49632 6.375 6 6.87868 6 7.5Z"
                          fill="currentColor"
                        />
                        <path
                          d="M10.125 8.625C10.7463 8.625 11.25 8.12132 11.25 7.5C11.25 6.87868 10.7463 6.375 10.125 6.375C9.50368 6.375 9 6.87868 9 7.5C9 8.12132 9.50368 8.625 10.125 8.625Z"
                          fill="currentColor"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C6.08279 15 4.75544 14.6062 3.62378 13.9218L1.34982 14.5906C0.774632 14.7598 0.240171 14.2254 0.409356 13.6502L1.07817 11.3762C0.393787 10.2446 0 8.91721 0 7.5ZM7.5 1.5C4.18629 1.5 1.5 4.18629 1.5 7.5C1.5 8.68265 1.8414 9.78327 2.43074 10.7114C2.60077 10.9791 2.65914 11.3169 2.56374 11.6413L2.2325 12.7675L3.3587 12.4363C3.68306 12.3409 4.02085 12.3992 4.28863 12.5693C5.21673 13.1586 6.31735 13.5 7.5 13.5C10.8137 13.5 13.5 10.8137 13.5 7.5C13.5 4.18629 10.8137 1.5 7.5 1.5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="cs-side_nav_text">
                      {t('resources_and_faq')}
                    </span>
                  </>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
        <div className="cs-side_nav_bottom">
          <button
            onClick={handleLogout}
            className={`cs-logout_btn cs-primary_font ${
              logoutLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.655 2.75781C17.6828 2.75781 20.1522 5.18492 20.1522 8.17306V14.0152H12.7067C12.1731 14.0152 11.7512 14.4299 11.7512 14.9543C11.7512 15.4666 12.1731 15.8934 12.7067 15.8934H20.1522V21.7234C20.1522 24.7115 17.6828 27.1508 14.6301 27.1508H8.58685C5.54658 27.1508 3.07715 24.7237 3.07715 21.7356V8.18525C3.07715 5.18492 5.55899 2.75781 8.59926 2.75781H14.655ZM23.2504 10.7468C23.6163 10.3687 24.2139 10.3687 24.5798 10.7346L28.1412 14.2837C28.3241 14.4667 28.4217 14.6984 28.4217 14.9546C28.4217 15.1985 28.3241 15.4424 28.1412 15.6132L24.5798 19.1623C24.3969 19.3453 24.1529 19.4429 23.9212 19.4429C23.6773 19.4429 23.4333 19.3453 23.2504 19.1623C22.8845 18.7965 22.8845 18.1988 23.2504 17.8329L25.2018 15.8937H20.1525V14.0154H25.2018L23.2504 12.0762C22.8845 11.7103 22.8845 11.1127 23.2504 10.7468Z"
                fill="white"
              />
            </svg>
            {logoutLoading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t('loading')}
              </span>
            )}
            <span>{t('logout')}</span>
          </button>
        </div>
      </div>
    </>
  );
}
