import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NewsletterBar({ preview, content }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="cs-card cs-style13">
        <div className="cs-card_item">
          <div className="cs-card_title">{t('template')}</div>
          <span className="cs-card_icon active">
            <span></span>
          </span>
        </div>
        <div className="cs-card_item">
          <div className="cs-card_title">{t('content')}</div>
          <span className={`cs-card_icon ${content ? 'active' : ''}`}>
            <span></span>
          </span>
        </div>
        <div className="cs-card_item">
          <div className="cs-card_title">{t('preview')}</div>
          <span className={`cs-card_icon ${preview ? 'active' : ''}`}>
            <span></span>
          </span>
        </div>
      </div>
    </>
  );
}
