import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import PlanContext from "../../context/plan/plan-context";
import UserContext from "../../context/user/user-context";
import useAuth from "../../hooks/useAuth";
import LoadingSpinner from "../Common/LoadingSpinner";
import Header from "../Header";
import DashboardSideNav from "../MainSideNav/DashboardSideNav";
import { conncetAccountRetrive } from "../../api/services/settings";
import ConnectAccountErrorModal from "../Common/ConnectAccountErrorModal";

export default function DashboardLayout({ showSearch }) {
  const { user, isLoading } = useContext(UserContext);
  const { features, isLoading: planLoading } = useContext(PlanContext);
  const [accountError, setAccountError] = useState([]);
  const [accountPendingInfo, setAccountPendingInfo] = useState([]);

  const getConnectAcountStatus = async () => {
    try {
      const res = await conncetAccountRetrive();
      setAccountError(res.data.data.requirements.errors);
      setAccountPendingInfo(res.data.data.requirements.currently_due);
    } catch {}
  };
  // deside what to render
  let content = "";
  if (isLoading)
    content = (
      <div
        className="cs-loading_wrap"
        style={{ width: "100%", height: "100vh" }}
      >
        <LoadingSpinner />
      </div>
    );
  if (!isLoading && user.varified === 0) {
    content = <Navigate to="/auth/email-verification" />;
  }
  if (!isLoading && user.varified && user.dob === null) {
    content = <Navigate to="/onboarding/personal-info" />;
  }
  if (
    !isLoading &&
    user.varified &&
    user.dob &&
    !planLoading &&
    !features.length
  ) {
    content = <Navigate to="/onboarding/choose-plan" />;
  }
  if (
    !isLoading &&
    user.varified &&
    user.dob &&
    features.length &&
    user.stripe_acc === ""
  ) {
    content = <Navigate to="/onboarding/withdrawal-method" />;
  }
  if (
    !isLoading &&
    user.varified &&
    user.dob &&
    features.length &&
    user.stripe_acc !== ""
  ) {
    content = (
      <>
        <Header showSearch={showSearch} />
        <DashboardSideNav />
        <div className="cs-with_sidebar_main">
          <Outlet />
        </div>
      </>
    );
  }

  const auth = useAuth();

  useEffect(() => {
    if (auth) {
      getConnectAcountStatus();
    }
  }, []);

  return auth ? (
    <>
      {(!isLoading && user.stripe_acc && accountError.length) ||
        (accountPendingInfo.length ? <ConnectAccountErrorModal /> : "")}
      {content}
    </>
  ) : (
    <Navigate to="/auth/login" />
  );
}
