import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import Spacing from '../Spacing';

export default function TagInput({ tags, setTags }) {
  const { t } = useTranslation();
  const [tagInput, setTagInput] = useState('');

  const removeTag = id => {
    const newTags = tags.filter((tag, i) => i !== id);
    setTags(newTags);
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter' && tagInput) {
      setTags([...tags, tagInput]);
      setTagInput('');
    }
  };
  return (
    <>
      <Spacing lg="20" md="20" />
      <h2
        className="cs-semibold"
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '20px',
          marginBottom: '10px',
          textTransform: 'uppercase',
        }}
      >
        <Icon icon="ph:hash-straight-bold" style={{ marginRight: '3px' }} />
        {t('hashtag')}
      </h2>
      <div className="cs-hashtag_wrap">
        {tags?.map((tag, index) => (
          <span className="cs-hashtag" key={index}>
            {tag}
            <span className="cs-tag_remove" onClick={() => removeTag(index)}>
              <Icon icon="mingcute:close-line" />
            </span>
          </span>
        ))}
        <input
          type="text"
          value={tagInput}
          onChange={e => setTagInput(e.target.value)}
          className="cs-hashtag_input"
          placeholder={t('add_tags')}
          onKeyDown={handleKeyPress}
        />
      </div>
      <Spacing lg="25" md="25" />
      <hr />
    </>
  );
}
