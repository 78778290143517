import React, { useContext } from 'react';
import Spacing from '../../components/Spacing';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import useFetch from '../../hooks/useFetch';
import { getCountryListUrl } from '../../api/const';
import UserContext from '../../context/user/user-context';
import { useEffect } from 'react';
import { dateConvart } from '../../utils/dateConvart';
import {
  updateProfilePicture,
  updateUserInfo,
} from '../../api/services/auth/user';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import { useRef } from 'react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function MyProfile() {
  const { t } = useTranslation();
  const [file, setFile] = useState({
    path: '',
    name: '',
    url: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { data: countries, loading: fetchLoading } =
    useFetch(getCountryListUrl);
  const { getUserInformation, user } = useContext(UserContext);
  const submitRef = useRef();
  const [charCount, setCharCount] = useState(0);
  const formSchema = Yup.object().shape({
    name_show: Yup.string(),
    whatsapp: Yup.string(),
    telegram: Yup.string(),
    discord: Yup.string(),
    fullname: Yup.string().required('The full name field required'),
    about_yourself: Yup.string().required('The about yourself field required'),
    dob: Yup.string().required('The date of birth field required'),
    country: Yup.string().required('The loaction field is required'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  const handelChange = e => {
    setFile({
      path: e.target.files[0],
      name: e.target.files[0].name,
      url: URL.createObjectURL(e.target.files[0]),
    });
  };
  const handelUploadCross = () => {
    setFile({
      url: '',
      path: '',
      name: '',
    });
  };

  // handle update
  const handleUpdate = async data => {
    setIsLoading(true);
    await uploadProfileImage();
    await updateUserInfo(data);
    await getUserInformation();
    setIsLoading(false);
  };

  // upload profile image
  const uploadProfileImage = async () => {
    const formData = new FormData();
    formData.append('profile_image', file.path);
    if (file.path) {
      const res = await updateProfilePicture(formData);
      if (res.data.success === 1) {
        return res.data.image;
      }
    }
  };

  useEffect(() => {
    setValue('whatsapp', user.whatsapp);
    setValue('telegram', user.telegram);
    setValue('discord', user.discord);
    setValue('fullname', user.fullname ?? 'Name');
    setValue('about_yourself', user.about_yourself);
    setValue('country', user.country);
    setValue('dob', user.dob ? dateConvart(user.dob) : '');
    setCharCount(user.about_yourself?.length);
    setFile({
      name: user.image?.split('/').pop(),
      url: user.image,
    });
  }, [countries, user]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-section_heading_3">
        <div className="cs-section_heading_left">
          <h2 className="cs-section_title">{t('my_profile_title')}</h2>
        </div>
        <div className="cs-section_heading_right">
          <button
            className={`cs-section_btn cs-filed ${
              isLoading ? 'cs-loading_wrap' : ''
            }`}
            onClick={() => submitRef.current.click()}
            disabled={isLoading}
          >
            {isLoading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t('loading')}
              </span>
            )}
            {t('save')}
          </button>
        </div>
      </div>
      <Spacing lg="60" md="40" />
      <form
        onSubmit={handleSubmit(handleUpdate)}
        className={`cs-row ${fetchLoading ? 'cs-loading_wrap' : ''}`}
      >
        {fetchLoading && <LoadingSpinner />}
        <div className="cs-col_12">
          <div className="avatar_upload">
            <div className="avatar_upload_output">
              {file.url ? (
                <img src={file.url} alt="" />
              ) : (
                <svg
                  width={56}
                  height={56}
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.0003 4.66699C21.8364 4.7456 15.947 7.22916 11.5881 11.5881C7.22916 15.947 4.7456 21.8364 4.66699 28.0003C4.69581 31.5911 5.55301 35.1268 7.17185 38.3321C8.7907 41.5375 11.1275 44.3259 14.0003 46.4803V46.667H14.2337C18.1844 49.6945 23.023 51.3352 28.0003 51.3352C32.9777 51.3352 37.8162 49.6945 41.767 46.667H42.0003V46.4803C44.8731 44.3259 47.21 41.5375 48.8288 38.3321C50.4476 35.1268 51.3048 31.5911 51.3337 28.0003C51.2551 21.8364 48.7715 15.947 44.4126 11.5881C40.0536 7.22916 34.1643 4.7456 28.0003 4.66699ZM18.8303 44.1703C19.171 42.6104 20.0347 41.2137 21.2782 40.2121C22.5217 39.2105 24.0703 38.6642 25.667 38.6637H30.3337C31.9304 38.6642 33.479 39.2105 34.7225 40.2121C35.966 41.2137 36.8297 42.6104 37.1703 44.1703C34.391 45.8055 31.225 46.6678 28.0003 46.6678C24.7757 46.6678 21.6096 45.8055 18.8303 44.1703ZM41.0903 41.1603C40.2028 39.0392 38.7083 37.2276 36.7945 35.9532C34.8807 34.6787 32.633 33.9981 30.3337 33.997H25.667C23.3677 33.9981 21.12 34.6787 19.2062 35.9532C17.2924 37.2276 15.7979 39.0392 14.9103 41.1603C13.1619 39.4406 11.7694 37.3933 10.8126 35.1352C9.85569 32.8772 9.35315 30.4527 9.33366 28.0003C9.39418 23.0684 11.3803 18.3556 14.8679 14.8679C18.3556 11.3803 23.0684 9.39418 28.0003 9.33366C32.9322 9.39418 37.6451 11.3803 41.1327 14.8679C44.6204 18.3556 46.6065 23.0684 46.667 28.0003C46.6475 30.4527 46.145 32.8772 45.1881 35.1352C44.2312 37.3933 42.8388 39.4406 41.0903 41.1603Z"
                    fill="#D7D7D7"
                  />
                  <path
                    d="M27.9999 13.9995C26.7663 13.9708 25.5398 14.1926 24.3944 14.6515C23.2491 15.1103 22.2087 15.7968 21.3362 16.6692C20.4638 17.5417 19.7773 18.5821 19.3184 19.7274C18.8596 20.8728 18.6378 22.0994 18.6665 23.3329C18.6378 24.5664 18.8596 25.7929 19.3184 26.9383C19.7773 28.0837 20.4638 29.1241 21.3362 29.9965C22.2087 30.869 23.2491 31.5554 24.3944 32.0143C25.5398 32.4732 26.7663 32.6949 27.9999 32.6662C29.2334 32.6949 30.4599 32.4732 31.6053 32.0143C32.7507 31.5554 33.791 30.869 34.6635 29.9965C35.536 29.1241 36.2224 28.0837 36.6813 26.9383C37.1402 25.7929 37.3619 24.5664 37.3332 23.3329C37.3619 22.0994 37.1402 20.8728 36.6813 19.7274C36.2224 18.5821 35.536 17.5417 34.6635 16.6692C33.791 15.7968 32.7507 15.1103 31.6053 14.6515C30.4599 14.1926 29.2334 13.9708 27.9999 13.9995ZM27.9999 27.9995C27.3791 28.0296 26.7589 27.9294 26.1791 27.7055C25.5993 27.4816 25.0728 27.1389 24.6333 26.6994C24.1939 26.2599 23.8512 25.7334 23.6273 25.1536C23.4034 24.5739 23.3032 23.9537 23.3332 23.3329C23.3032 22.7121 23.4034 22.0919 23.6273 21.5121C23.8512 20.9323 24.1939 20.4058 24.6333 19.9663C25.0728 19.5269 25.5993 19.1842 26.1791 18.9603C26.7589 18.7364 27.3791 18.6362 27.9999 18.6662C28.6207 18.6362 29.2409 18.7364 29.8206 18.9603C30.4004 19.1842 30.9269 19.5269 31.3664 19.9663C31.8059 20.4058 32.1486 20.9323 32.3725 21.5121C32.5964 22.0919 32.6965 22.7121 32.6665 23.3329C32.6965 23.9537 32.5964 24.5739 32.3725 25.1536C32.1486 25.7334 31.8059 26.2599 31.3664 26.6994C30.9269 27.1389 30.4004 27.4816 29.8206 27.7055C29.2409 27.9294 28.6207 28.0296 27.9999 27.9995Z"
                    fill="#D7D7D7"
                  />
                </svg>
              )}
            </div>
            <div className="avatar_upload_right">
              {file.name && <p>{file.name}</p>}
              <div className="avatar_upload_btn">
                <input type="file" onChange={handelChange} />
                UPLOAD NEW
              </div>
            </div>
          </div>
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_1')}</label>
          <input
            type="text"
            className="cs-form_field"
            {...register('fullname')}
          />
          <span className="invalid-feedback">{errors.fullname?.message}</span>
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_2')}</label>
          <input
            type="text"
            className="cs-form_field"
            value={user.name_show}
            disabled
          />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_3')}</label>
          <input type="date" className="cs-form_field" {...register('dob')} />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_4')}</label>
          <select
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('country')}
          >
            <option value="">Select Location</option>
            {countries.data?.map(country => {
              return (
                <option key={country.id} value={country.nicename}>
                  {country.nicename}
                </option>
              );
            })}
          </select>
          <span className="invalid-feedback">{errors.country?.message}</span>
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_5')}</label>
          <input
            type="text"
            className="cs-form_field"
            {...register('whatsapp')}
          />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_6')}</label>
          <input
            type="text"
            className="cs-form_field"
            {...register('telegram')}
          />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_7')}</label>
          <input
            type="text"
            className="cs-form_field"
            {...register('discord')}
          />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_6">
          <label>{t('my_profile_input_label_8')}</label>
          <input
            type="text"
            className="cs-form_field"
            value={user.email}
            disabled
          />
          <Spacing lg="35" md="35" />
        </div>
        <div className="cs-col_12">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label>{t('my_profile_input_label_9')}</label>
            <span style={{ color: '#A6A6A6' }}>{charCount}/150</span>
          </div>
          <textarea
            className="cs-form_field"
            cols="30"
            rows="4"
            maxLength="150"
            placeholder="Tell people about yourself"
            style={{ fontSize: '14px' }}
            {...register('about_yourself')}
            onChange={e => {
              setCharCount(e.target.value.length);
            }}
          ></textarea>
          <span className="invalid-feedback">
            {errors.about_yourself?.message}
          </span>
          <Spacing lg="35" md="35" />
        </div>
        <button
          type="submit"
          style={{ display: 'none' }}
          ref={submitRef}
        ></button>
      </form>
      <Spacing lg="25" md="25" />
    </div>
  );
}
