import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Spacing from "../Spacing";
import { useTranslation } from "react-i18next";
import { logoutUser } from "../../api/services/auth/user";

export default function SettingsSideNav() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const navigate = useNavigate();

  // handle logout
  const handleLogout = async () => {
    setLogoutLoading(true);
    await logoutUser({ type: "2", device: 3 });
    setLogoutLoading(false);
    localStorage.removeItem("token");
    localStorage.removeItem("isAuth");
    navigate("/auth/login");
  };
  return (
    <>
      <button className="cs-side_nav_toggle" onClick={() => setOpen(!open)}>
        <Icon icon="ic:round-menu" />
      </button>
      <div
        className={open ? "cs-side_nav_overlay active" : "cs-side_nav_overlay"}
        onClick={() => setOpen(false)}
      ></div>
      <div className={open ? "cs-side_nav active" : "cs-side_nav"}>
        <div className="cs-side_nav_top">
          <Link to="/" className="cs-bak_list_btn cs-semibold">
            <>
              <Icon icon="ph:arrow-left-duotone" />
              <span>{t("back_to_home")}</span>
            </>
          </Link>
          <Spacing lg="30" md="30" />
          <h2 className="cs-section_heading">
            <img src="/images/icon/settings.svg" alt="Icon" />
            <span>{t("settings")}</span>
          </h2>
          <ul className="cs-side_nav_list">
            <li>
              <NavLink to="/settings/my-profile" onClick={() => setOpen(false)}>
                <>
                  <span className="cs-side_nav_icon">
                    <Icon icon="material-symbols:info-outline" />
                  </span>
                  <span className="cs-side_nav_text">{t("my_profile")}</span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/update-withdrawal-details"
                onClick={() => setOpen(false)}
              >
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={15}
                      height={14}
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.04688 2.84375V11.1562H9.84375V2.84375H8.67188V10.0625H4.21875V2.84375H3.04688ZM6.28828 7.41147C5.88352 7.41147 5.51438 7.28919 5.24625 7.16691L5.04984 7.93953C5.28187 8.05044 5.68688 8.15609 6.10406 8.17294V8.68962H6.78258V8.12306C7.49719 8.00603 7.89023 7.57816 7.89023 7.06147C7.89023 6.55572 7.61648 6.23897 6.86602 6.01103C6.36586 5.84434 6.16359 5.73869 6.16359 5.59978C6.16359 5.47728 6.2707 5.36069 6.62203 5.36069C7.07438 5.36069 7.36031 5.48866 7.52109 5.54969L7.71164 4.81053C7.50305 4.72719 7.22344 4.64931 6.81234 4.63269V4.16588H6.13383V4.67709C5.45484 4.80506 5.07984 5.20516 5.07984 5.71091C5.07984 6.27222 5.53828 6.55572 6.18141 6.74997C6.62789 6.87794 6.80039 6.98359 6.80039 7.15575C6.80039 7.33359 6.59227 7.41169 6.28852 7.41169L6.28828 7.41147ZM12.5391 0.875H2.46094C2.05705 0.875463 1.66984 1.02542 1.38425 1.29197C1.09866 1.55852 0.937996 1.91991 0.9375 2.29688V4.70312C0.9375 5.37403 1.43859 5.93644 2.10938 6.08519V2.29688C2.10938 2.11597 2.26711 1.96875 2.46094 1.96875H12.5391C12.7329 1.96875 12.8906 2.11597 12.8906 2.29688V6.08519C13.5614 5.93644 14.0625 5.37403 14.0625 4.70312V2.29688C14.0625 1.51287 13.3791 0.875 12.5391 0.875ZM10.7812 12.0312H4.92188V13.125H11.9531V2.84375H10.7812V12.0312Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">
                    {t("update_withdrawal_details")}
                  </span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/earning-dashboard"
                onClick={() => setOpen(false)}
              >
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={15}
                      height={15}
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 11.7682V12.7257H0V11.7682H1.03737V11.0415C0.480628 10.8434 0.0794664 10.3163 0.0794664 9.69349C0.0794664 9.06877 0.480628 8.54267 1.03737 8.34544V7.37317C0.480628 7.17546 0.0794664 6.64888 0.0794664 6.02511C0.0794664 5.39991 0.480628 4.87477 1.03737 4.67658V2.27439H1.9948V4.6761C2.55154 4.87429 2.9527 5.40087 2.9527 6.02463C2.9527 6.6484 2.55154 7.17498 1.9948 7.37269V8.34496C2.55154 8.54219 2.9527 9.06877 2.9527 9.69301C2.9527 10.3173 2.55154 10.8429 1.9948 11.0411V11.7678H5.02649V7.37173C4.46975 7.17355 4.06858 6.64648 4.06858 6.02368C4.06858 5.39896 4.46975 4.87285 5.02649 4.67562V2.27344H5.98392V4.67562C6.54066 4.87285 6.94182 5.39991 6.94182 6.02368C6.94182 6.6484 6.54066 7.17355 5.98392 7.37173V11.7682H9.01608V11.0415C8.45934 10.8434 8.05818 10.3163 8.05818 9.69349C8.05818 9.06877 8.45934 8.54267 9.01608 8.34544V2.27344H9.97351V8.34544C10.5303 8.54267 10.9314 9.06925 10.9314 9.69349C10.9314 10.3177 10.5303 10.8434 9.97351 11.0415V11.7682H13.0052V11.0415C12.4485 10.8434 12.0473 10.3163 12.0473 9.69349C12.0473 9.06877 12.4485 8.54267 13.0052 8.34544V7.37317C12.4485 7.17546 12.0473 6.64888 12.0473 6.02511C12.0473 5.39991 12.4485 4.87477 13.0052 4.67658V2.27439H13.9626V4.6761C14.5194 4.87429 14.9205 5.40087 14.9205 6.02463C14.9205 6.6484 14.5194 7.17498 13.9626 7.37269V8.34496C14.5194 8.54219 14.9205 9.06877 14.9205 9.69301C14.9205 10.3173 14.5194 10.8429 13.9626 11.0411V11.7678H15V11.7682Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">
                    {t("earning_dashboard")}
                  </span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/change-plan"
                onClick={() => setOpen(false)}
              >
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={11}
                      height={11}
                      viewBox="0 0 11 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.4998 7C10.776 7 10.9998 7.22375 10.9998 7.5V10.5C10.9998 10.776 10.776 11 10.4998 11H7.49976C7.22351 11 6.99976 10.776 6.99976 10.5V9.5H3.99976V10.5C3.99976 10.776 3.77601 11 3.49976 11H0.499756C0.223506 11 -0.000244141 10.776 -0.000244141 10.5V7.5C-0.000244141 7.22375 0.223506 7 0.499756 7H1.49976V4H0.499756C0.223506 4 -0.000244141 3.77625 -0.000244141 3.5V0.5C-0.000244141 0.22375 0.223506 0 0.499756 0H3.49976C3.77601 0 3.99976 0.22375 3.99976 0.5V1.5H6.99976V0.5C6.99976 0.22375 7.22351 0 7.49976 0H10.4998C10.776 0 10.9998 0.22375 10.9998 0.5V3.5C10.9998 3.77625 10.776 4 10.4998 4H9.49976V7H10.4998ZM0.999756 8.5V9.5C0.999756 9.77625 1.22351 10 1.49976 10H2.49976C2.77601 10 2.99976 9.77625 2.99976 9.5V8.5C2.99976 8.22375 2.77601 8 2.49976 8H1.49976C1.22351 8 0.999756 8.22375 0.999756 8.5ZM2.99976 1.5C2.99976 1.22375 2.77601 1 2.49976 1H1.49976C1.22351 1 0.999756 1.22375 0.999756 1.5V2.5C0.999756 2.776 1.22351 3 1.49976 3H2.49976C2.77601 3 2.99976 2.776 2.99976 2.5V1.5ZM6.99976 3.5V2.5H3.99976V3.5C3.99976 3.77625 3.77601 4 3.49976 4H2.49976V7H3.49976C3.77601 7 3.99976 7.22375 3.99976 7.5V8.5H6.99976V7.5C6.99976 7.22375 7.22351 7 7.49976 7H8.49976V4H7.49976C7.22351 4 6.99976 3.77625 6.99976 3.5ZM9.99976 2.5V1.5C9.99976 1.22375 9.77601 1 9.49976 1H8.49976C8.22351 1 7.99976 1.22375 7.99976 1.5V2.5C7.99976 2.776 8.22351 3 8.49976 3H9.49976C9.77601 3 9.99976 2.776 9.99976 2.5ZM8.49976 8C8.22351 8 7.99976 8.22375 7.99976 8.5V9.5C7.99976 9.77625 8.22351 10 8.49976 10H9.49976C9.77601 10 9.99976 9.77625 9.99976 9.5V8.5C9.99976 8.22375 9.77601 8 9.49976 8H8.49976Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">
                    {t("change_gorudo_plan")}{" "}
                  </span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/modify-membership-tiers"
                onClick={() => setOpen(false)}
              >
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={19}
                      height={13}
                      viewBox="0 0 19 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.4295 2.7937C13.4295 3.45064 13.9621 3.9832 14.619 3.9832C15.276 3.9832 15.8085 3.45064 15.8085 2.7937C15.8085 2.13676 15.276 1.6042 14.619 1.6042C13.9621 1.6042 13.4295 2.13676 13.4295 2.7937ZM12.2107 6.77594V5.84695C12.2107 5.0305 12.8772 4.36079 13.6968 4.36079H15.6426C16.459 4.36079 17.1287 5.02729 17.1287 5.84695V6.77594H12.2107ZM6.99079 0.176758V1.89619C6.78111 1.89619 6.38928 1.94111 6.10708 2.02648C5.35323 2.25457 4.71718 2.76485 4.32732 3.61759L0.145996 12.8239H5.8974L6.26761 11.5901C7.88845 11.1967 9.10197 9.95079 9.59137 8.38436L9.59751 8.38443C9.6097 8.3298 9.62592 8.27612 9.6433 8.22274H18.5505V0.176758H6.99079ZM17.9753 7.64759H8.4286C8.21264 8.75216 7.39547 9.7571 6.29427 10.2069C6.2587 10.2214 6.22182 10.2283 6.18559 10.2283C6.07224 10.2283 5.96481 10.1608 5.91924 10.0495C5.8592 9.90244 5.92968 9.73461 6.0767 9.6745C7.15526 9.23396 7.90875 8.16562 7.91021 7.07574V6.78499H10.8597C11.2955 6.78492 11.6487 6.43173 11.6487 5.99601C11.6487 5.56058 11.296 5.20747 10.8605 5.2071L5.68166 5.20323C5.52288 5.20323 5.39405 5.07447 5.39405 4.91562C5.39405 4.75677 5.52281 4.62802 5.68166 4.62802H7.56586V0.751898H17.9753V7.64759H17.9753ZM8.4286 1.89619H11.6488V2.47133H8.4286V1.89619ZM8.4286 3.33408H11.6488V3.90922H8.4286V3.33408Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">
                    {t("modify_membership_tiers")}
                  </span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/nitification-settings"
                onClick={() => setOpen(false)}
              >
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={15}
                      height={16}
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.07021 14.1764C9.07021 14.1829 9.07192 14.189 9.07192 14.1955C9.07192 14.9479 8.4634 15.5578 7.71271 15.5578C6.96203 15.5578 6.35351 14.9479 6.35351 14.1955C6.35351 14.189 6.35521 14.1829 6.35521 14.1768C2.00828 14.0571 0.894531 13.3487 0.894531 12.491C0.894531 12.222 1.0118 11.969 1.29714 11.7427C3.35214 9.45346 3.96271 10.0875 3.96271 6.69652C3.96271 4.43527 4.0551 2.36289 6.77794 1.98925C6.72374 1.86482 6.69305 1.72777 6.69305 1.58357C6.69305 1.01902 7.14953 0.561523 7.71271 0.561523C8.2759 0.561523 8.73237 1.01902 8.73237 1.58357C8.73237 1.72777 8.70169 1.86482 8.64783 1.98925C11.3703 2.36289 11.4627 4.43527 11.4627 6.69652C11.4627 10.0974 12.0763 9.44868 14.1463 11.7624C14.417 11.9837 14.5309 12.2298 14.5309 12.491C14.5309 13.3483 13.3878 14.0561 9.07021 14.1764ZM7.71271 14.8773C8.08805 14.8773 8.39249 14.5722 8.39249 14.1958C8.39249 14.1938 8.3918 14.1921 8.3918 14.1904C8.16987 14.1931 7.94999 14.1965 7.71271 14.1965C7.47544 14.1965 7.25555 14.1931 7.03362 14.1904C7.03362 14.1921 7.03294 14.1941 7.03294 14.1958C7.03294 14.5722 7.33737 14.8773 7.71271 14.8773ZM7.71271 1.24232C7.52487 1.24232 7.37283 1.39471 7.37283 1.58289C7.37283 1.77107 7.52487 1.9238 7.71271 1.9238C7.90055 1.9238 8.0526 1.77107 8.0526 1.58289C8.0526 1.39471 7.90055 1.24232 7.71271 1.24232ZM13.7148 12.2902C13.7148 12.2902 13.1956 11.7062 12.345 10.9316C11.1828 9.87346 10.7809 9.3713 10.7809 6.69652C10.7809 4.0838 10.6384 2.60561 7.71271 2.60561C4.78703 2.60561 4.64453 4.0838 4.64453 6.69652C4.64453 9.37164 4.24226 9.87414 3.07976 10.9327C2.24521 11.6929 1.72089 12.2769 1.72089 12.2769C1.57635 12.3918 1.57635 12.4586 1.57635 12.491C1.57635 12.5329 1.64624 13.5144 7.71271 13.5144C13.7792 13.5144 13.8491 12.5329 13.8491 12.491C13.8491 12.4637 13.8491 12.3999 13.7148 12.2902Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">
                    {t("notification_settings")}
                  </span>
                </>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings/change-password"
                onClick={() => setOpen(false)}
              >
                <>
                  <span className="cs-side_nav_icon">
                    <svg
                      width={13}
                      height={15}
                      viewBox="0 0 13 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.14485 4.73653V3.89061C3.14485 2.06614 4.64959 0.591797 6.50012 0.591797C8.35064 0.591797 9.85538 2.06614 9.85538 3.89061H8.67117C8.67117 2.72535 7.70169 1.77601 6.50012 1.77601C5.29854 1.77601 4.32906 2.72535 4.32906 3.89061V4.73653H9.26327V5.92074H1.36854V13.026H11.6317V7.89443H12.8159V14.2102H0.184326V4.73653H3.14485ZM6.77406 11.151L5.93564 10.3142L11.9499 4.28772L12.7883 5.12417L6.77406 11.151Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="cs-side_nav_text">
                    {t("change_password")}
                  </span>
                </>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="cs-side_nav_bottom">
          <button
            onClick={handleLogout}
            className={`cs-logout_btn cs-primary_font ${
              logoutLoading ? "cs-loading_wrap" : ""
            }`}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.655 2.75781C17.6828 2.75781 20.1522 5.18492 20.1522 8.17306V14.0152H12.7067C12.1731 14.0152 11.7512 14.4299 11.7512 14.9543C11.7512 15.4666 12.1731 15.8934 12.7067 15.8934H20.1522V21.7234C20.1522 24.7115 17.6828 27.1508 14.6301 27.1508H8.58685C5.54658 27.1508 3.07715 24.7237 3.07715 21.7356V8.18525C3.07715 5.18492 5.55899 2.75781 8.59926 2.75781H14.655ZM23.2504 10.7468C23.6163 10.3687 24.2139 10.3687 24.5798 10.7346L28.1412 14.2837C28.3241 14.4667 28.4217 14.6984 28.4217 14.9546C28.4217 15.1985 28.3241 15.4424 28.1412 15.6132L24.5798 19.1623C24.3969 19.3453 24.1529 19.4429 23.9212 19.4429C23.6773 19.4429 23.4333 19.3453 23.2504 19.1623C22.8845 18.7965 22.8845 18.1988 23.2504 17.8329L25.2018 15.8937H20.1525V14.0154H25.2018L23.2504 12.0762C22.8845 11.7103 22.8845 11.1127 23.2504 10.7468Z"
                fill="white"
              />
            </svg>
            {logoutLoading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t("loading")}
              </span>
            )}
            <span>{t("logout")}</span>
          </button>
        </div>
      </div>
    </>
  );
}
