import moment from "moment";
import { useContext, useEffect, useState } from "react";
import {
  allNotification,
  notificationSeenUpdate,
} from "../../api/services/notification";
import { notificationDetailsStringToArray } from "../../utils/notificationDetailsStringToArray";
import NotificationContext from "../../context/notification/notification-context";
import LoadingSpinner from "../Common/LoadingSpinner";
import Spacing from "../Spacing";
import Notification from "./index";

export function AllNotification({ closeNotification }) {
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getUnreadCount } = useContext(NotificationContext);

  const getAllNotification = async () => {
    setLoading(true);
    const res = await allNotification();
    setNotification(notificationDetailsStringToArray(res.data.data));
    setLoading(false);
  };

  // make notification seen
  const makeNotificationSeen = async (id) => {
    closeNotification(false);
    await notificationSeenUpdate(id);
    getUnreadCount();
  };

  // update seen
  const updateSeen = async (id) => {
    let notificationCopy = [...notification];
    for (let i = 0; i < notificationCopy.length; i++) {
      if (notificationCopy[i].id === id) {
        notificationCopy[i].seen = 1;
      }
    }
    setNotification(notificationCopy);
  };

  // delete notification
  const deleteNotification = async (id) => {
    let notificationCopy = [...notification];
    let index;
    for (let i = 0; i < notificationCopy.length; i++) {
      if (notificationCopy[i].id === id) {
        index = i;
      }
    }
    notificationCopy.splice(index, 1);
    setNotification(notificationCopy);
  };

  useEffect(() => {
    getAllNotification();
  }, []);
  return (
    <ul
      className={`cs-notification_list cs-mp0 ${
        loading ? "cs-loading_wrap" : ""
      }`}
    >
      {loading && (
        <>
          <Spacing lg="50" md="50" />
          <LoadingSpinner />
        </>
      )}
      {notification.map((item, index) => (
        <li key={index}>
          <Notification
            makeNotificationSeen={makeNotificationSeen}
            title={item.title}
            id={item.id}
            name={item.name}
            tier={item.tier}
            seen={item.seen}
            postId={item.postId}
            type={item.type}
            detail={item.detail}
            date={`${moment(item.created_at).format("LT")} ; ${moment(
              item.created_at
            ).format("D MMM Y")}`}
            updateSeen={updateSeen}
            deleteNotification={deleteNotification}
          />
        </li>
      ))}
    </ul>
  );
}
