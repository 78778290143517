import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEarningHistory } from '../../../../api/services/settings/earningDashboard';
import LoadingSpinner from '../../../../components/Common/LoadingSpinner';
import PaginateButton from '../../../../components/Common/PaginateButton';
import Spacing from '../../../../components/Spacing';
import { defaultHasMoreCount } from '../../../../config/const';

export default function Earning() {
  const { t } = useTranslation();
  const [earningHistory, setEarningHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);

  // get earning history
  const getEarning = async () => {
    setLoading(true);
    const res = await getEarningHistory();
    setEarningHistory(res.data.result);
    setCount(res.data.count);
    setLoading(false);
  };

  // handle paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getEarningHistory(page);
    setEarningHistory([...earningHistory, ...res.data.result]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.result.length) {
      setHasMore(false);
    }
  };
  useEffect(() => {
    getEarning();
  }, []);
  return (
    <>
      <div className="cs-table_responsive">
        <table className="cs-table cs-medium">
          <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
            <tr>
              <th>{t('earning_table_heade_1')}</th>
              <th>{t('earning_table_heade_2')}</th>
              <th>{t('earning_table_heade_3')}</th>
              <th>
                {t('earning_table_heade_4_1')} <br />
                <div style={{ fontSize: '10px', lineHeight: '1.2em' }}>
                  ({t('earning_table_heade_4_2')})
                </div>
              </th>
              <th style={{ width: '150px' }}>{t('earning_table_heade_5')}</th>
              <th>{t('earning_table_heade_6')}</th>
            </tr>
          </thead>
          <tbody
            className={`cs-word_break ${loading ? 'cs-loading_wrap' : ''}`}
            style={{ fontSize: '12px', verticalAlign: 'baseline' }}
          >
            {loading && (
              <>
                <Spacing lg="40" md="40" />
                <LoadingSpinner />
              </>
            )}
            {earningHistory.map(item => (
              <tr key={item.id}>
                <td>{moment(item.created_at).format('DD MMM Y')}</td>
                <td className="cs-success">{item.memberName}</td>
                <td>
                  Subscribed to
                  <p className="cs-accent_color" style={{ margin: 0 }}>
                    {item.tireTitle}
                  </p>
                </td>
                <td>
                  {item.amount}{' '}
                  <span style={{ textTransform: 'uppercase' }}>
                    {item.currency}
                  </span>
                </td>
                <td style={{ width: '150px' }}>{item.invoiceId}</td>
                <td>
                  <a href={item.hosted_invoice_url} target="_blank">
                    {t('view')}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {count > defaultHasMoreCount && (
        <PaginateButton
          loading={paginateLoading}
          hasMore={hasMore}
          onClick={paginate}
        />
      )}
    </>
  );
}
