import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import Spacing from '../../../../components/Spacing';
import { v4 as uuidv4 } from 'uuid';
import { Calendar } from 'react-calendar';
import { dateConvart } from '../../../../utils/dateConvart';
import { updateWatchlist } from '../../../../api/services/watchlist';
import { useTranslation } from 'react-i18next';

export default function EditModal({
  setEditModal,
  setModal,
  editedWatchlist,
  setEditedWatchlist,
  modal,
  refresh,
  setPage,
}) {
  const { t } = useTranslation();
  const [value, onChange] = useState(new Date());
  const submitRef = useRef();
  const resetRef = useRef();
  const noteRef = useRef();
  const [dateError, setDateError] = useState('');
  const [noteError, setNoteError] = useState('');
  const [tickerError, setTickerError] = useState('');
  const [pubLoading, setPubLoading] = useState(false);
  const tickerBindSubmit = useRef();
  const [tickerTitle, setTickerTitle] = useState('');

  // handle add new
  const handleAddNew = e => {
    e.preventDefault();
    setEditedWatchlist({
      ...editedWatchlist,
      ticker: [...editedWatchlist.ticker, { title: tickerTitle }],
    });
    resetRef.current.click();
  };
  // remove ticker
  const removeTicker = e => {
    const removedWatchList = editedWatchlist.ticker.filter(item => item !== e);
    setEditedWatchlist({
      ...editedWatchlist,
      ticker: [...removedWatchList],
    });
  };

  // set update ticker
  const setUpdateTicker = index => {
    let ticker = editedWatchlist.ticker;
    ticker[index] = { isUpdate: true, title: ticker[index].title };
    setEditedWatchlist({ ...editedWatchlist, ticker: ticker });
  };

  // bind update ticker
  const bindUpdateTicker = e => {
    e.preventDefault();
    let ticker = editedWatchlist.ticker;
    ticker[e.target.elements.index.value] = {
      title: e.target.elements.title.value,
    };
    setEditedWatchlist({ ...editedWatchlist, ticker: ticker });
  };

  const handlePublish = async () => {
    setPubLoading(true);
    const res = await updateWatchlist({
      ...editedWatchlist,
      note_date: dateConvart(editedWatchlist.note_date),
    });
    setPubLoading(false);
    if (res.data.success === 1) {
      noteRef.current.value = '';
      refresh();
      setPage(2);
      setEditModal(false);
    }
  };

  useEffect(() => {
    noteRef.current.value = editedWatchlist.note;
    onChange(new Date(editedWatchlist.note_date));
  }, []);
  return (
    <>
      <div className="cs-modal">
        <div
          className="cs-modal_overlay"
          onClick={() => setEditModal(false)}
        ></div>
        <div className="cs-modal_in">
          <div className="cs-modal_container">
            <div
              className="cs-modal_box cs-style3"
              style={{ maxWidth: '1030px' }}
            >
              <button
                className="cs-close_modal"
                onClick={() => setEditModal(false)}
              >
                <Icon icon="mdi:close-circle-outline" />
              </button>
              <div className="cs-modal_box_in">
                <Spacing lg="10" md="10" />
                <h2 style={{ fontSize: '30px' }}>{t('edit_watchlist')}</h2>
                <Spacing lg="10" md="10" />
                <div className="cs-watchlist">
                  <div className="cs-watchlist_left">
                    <div
                      className="cs-add_date_heading"
                      style={{ cursor: 'pointer' }}
                      onClick={() => setModal('addData')}
                    >
                      <div className="cs-add_date_in">
                        <span>
                          {moment(editedWatchlist.note_date).format('dddd')}
                        </span>
                        <span>
                          {moment(editedWatchlist.note_date).format('D MMM')}
                        </span>
                      </div>
                      <span
                        style={{
                          display: 'flex',
                          color: '#fff',
                          paddingRight: '2px',
                        }}
                      >
                        <Icon icon="material-symbols:edit-square-outline-rounded" />
                      </span>
                    </div>
                    <Spacing lg="25" md="25" />
                  </div>
                  <div className="cs-watchlist_right">
                    <div className="cs-todo_input">
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '14px',
                        }}
                      >
                        <label className="cs-todo_label">
                          {t('creators_note')}
                        </label>
                        <div>
                          {editedWatchlist.note.length}/
                          <span className="cs-accent_color">80</span>
                        </div>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <input
                          ref={noteRef}
                          type="text"
                          className="cs-form_field"
                          placeholder={t('enter_note')}
                          onChange={e =>
                            setEditedWatchlist({
                              ...editedWatchlist,
                              note: e.target.value,
                            })
                          }
                          maxLength={80}
                        />
                      </div>
                    </div>
                    {noteError && (
                      <p className="invalid-feedback">{noteError}</p>
                    )}
                    <Spacing lg="25" md="25" />
                    <ul className="cs-todo_list cs-mp0">
                      {editedWatchlist.ticker.map((item, index) => (
                        <li key={index}>
                          <div className="cs-todo">
                            {item.isUpdate ? (
                              <form onSubmit={bindUpdateTicker}>
                                <input
                                  type="text"
                                  name="title"
                                  className="cs-form_field"
                                  placeholder={t('write_title')}
                                  value={item.title}
                                  onChange={e => {
                                    let ticker = editedWatchlist.ticker;
                                    ticker[index] = {
                                      title: e.target.value,
                                      isUpdate: true,
                                    };
                                    setEditedWatchlist({
                                      ...editedWatchlist,
                                      ticker: ticker,
                                    });
                                  }}
                                  required
                                />
                                <input
                                  type="hidden"
                                  name="index"
                                  value={index}
                                />
                                <button
                                  ref={tickerBindSubmit}
                                  type="submit"
                                  style={{ display: 'none' }}
                                ></button>
                              </form>
                            ) : (
                              <div className="cs-todo_title">{item.title}</div>
                            )}
                            <div className="cs-todo_subtitle">
                              {t('posted_in')} {moment(new Date()).format('LT')}
                            </div>
                            <div className="cs-todo_btns">
                              {item.isUpdate ? (
                                <button
                                  className="cs-success"
                                  onClick={() => {
                                    tickerBindSubmit.current.click();
                                  }}
                                >
                                  <Icon
                                    icon="material-symbols:check"
                                    width="18"
                                    height="18"
                                  />
                                </button>
                              ) : (
                                <button
                                  className="cs-primary_color"
                                  onClick={() => setUpdateTicker(index)}
                                >
                                  <Icon icon="material-symbols:edit-square-outline-rounded" />
                                </button>
                              )}

                              <button
                                onClick={() => removeTicker(item)}
                                className="cs-danger"
                                style={{ marginTop: '2px' }}
                              >
                                <Icon icon="material-symbols:delete-outline" />
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}

                      <li>
                        <form onSubmit={handleAddNew} className="cs-todo">
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('write_title')}
                            onChange={e => setTickerTitle(e.target.value)}
                            required
                          />
                          <div className="cs-todo_subtitle">
                            {t('posted_in')} {moment(new Date()).format('LT')}
                          </div>
                          <div className="cs-todo_btns"></div>
                          <button
                            ref={submitRef}
                            type="submit"
                            style={{ display: 'none' }}
                          ></button>
                          <input
                            type="reset"
                            ref={resetRef}
                            style={{ display: 'none' }}
                          ></input>
                        </form>
                      </li>
                      <li>
                        <button
                          className="cs-todo_btn"
                          onClick={() => submitRef.current.click()}
                        >
                          + {t('add_new')}
                        </button>
                      </li>
                      <li>
                        <button
                          className={`cs-todo_btn cs-color1 ${
                            pubLoading ? 'cs-loading_wrap' : ''
                          }`}
                          disabled={pubLoading}
                          onClick={() => {
                            // creator note validation
                            if (!editedWatchlist.note_date) {
                              setDateError('The date field is required');
                            } else {
                              setDateError('');
                            }
                            // watchlist date validate
                            if (!editedWatchlist.note) {
                              setNoteError(
                                'The creator note field is required',
                              );
                            } else {
                              setNoteError('');
                            }
                            // ticker validation
                            if (!editedWatchlist.ticker.length) {
                              setTickerError('The ticker field is required');
                            } else {
                              setTickerError('');
                            }
                            // set publish modal
                            if (
                              editedWatchlist.note &&
                              editedWatchlist.ticker.length &&
                              editedWatchlist.note_date
                            )
                              handlePublish();
                          }}
                        >
                          {pubLoading && (
                            <span className="cs-btn_loading">
                              <Icon icon="la:spinner" />
                              {t('loading')}
                            </span>
                          )}
                          {t('update')}
                        </button>
                      </li>
                    </ul>
                    {tickerError && (
                      <p className="invalid-feedback">{tickerError}</p>
                    )}
                  </div>
                </div>
                <Spacing lg="5" md="5" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal === 'addData' && (
        <div className="cs-modal" style={{ zIndex: '202' }}>
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style3"
                style={{ maxWidth: '410px' }}
              >
                <button
                  className="cs-close_modal"
                  onClick={() => setModal(false)}
                >
                  <Icon icon="mdi:close-circle-outline" />
                </button>
                <div className="cs-modal_box_in">
                  <h2 style={{ fontSize: '30px' }}>{t('add_date')}</h2>
                  <h3
                    className="cs-semibold cs-m0"
                    style={{ fontSize: '18px' }}
                  >
                    {t('choose_a_date')}
                  </h3>
                  <Spacing lg="30" md="20" />
                  <Calendar onChange={onChange} value={value} />
                  <Spacing lg="30" md="20" />
                  <div className="cs-text_center">
                    <button
                      className="cs-btn cs-primary_font cs-color1"
                      style={{ width: '100%', maxWidth: '200px' }}
                      onClick={() => {
                        setEditedWatchlist({
                          ...editedWatchlist,
                          note_date: value,
                        });
                        setModal(false);
                      }}
                    >
                      {t('add')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
