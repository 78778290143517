import {
  createWatchlistUrl,
  deleteBroadcastUrl,
  deleteWatchlistUrl,
  getWatchlistUrl,
  updateWatchlistUrl,
} from "../../const";
import axios from "../../lib/axios";

// create watchlist
export const createWatchlist = async (data) => {
  return await axios.post(createWatchlistUrl, data);
};

// get all watchlist
export const getWatchlist = async (
  page = 1,
  limit = 5,
  search = "",
  from = "",
  to = "",
  post_id = ""
) => {
  return await axios.get(getWatchlistUrl, {
    params: { page, limit, search, from, to, post_id },
  });
};

// update watchlist
export const updateWatchlist = async (data) => {
  return await axios.put(updateWatchlistUrl, data);
};

// delete watchlist
export const deleteWatchlist = async (post_id) => {
  return await axios.delete(deleteWatchlistUrl, { params: { post_id } });
};
