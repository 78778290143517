import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'
import Card3 from '../Card/Card3'
import MembershipTire from '../MembershipTire'
import Spacing from '../Spacing'

const ReadMore = ({ count, children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div>
      <p>{isReadMore ? text.slice(0, count) : text}</p>
      <span onClick={toggleReadMore} className='cs-coninue_reading'>
        {isReadMore ? ' Read More...' : ' Show Less'}
      </span>
    </div>
  );
};

export default function CreatorPublicView() {
  return (
    <div style={{position:'relative'}}>
      <Spacing lg='30' md='30'/>
      <Link to='/my-creators' className='cs-bak_list_btn cs-type1 cs-semibold'>
        <>
          <Icon icon="ph:arrow-left-duotone" />
          <span>Back to Lists</span>
        </>
      </Link>
      <div className='cs-contaienr_sm'>
        <Card3 />
        <div id='membership_tire'>
          <Spacing lg='80' md='60'/>
          <MembershipTire title='Select Membership Tier'/>
        </div>
        <Spacing lg='80' md='60'/>
        <div className="cs-subscription_notice">
          <h2>
            <svg width={28} height={30} viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.9464 24.5223C23.7583 23.0825 23.637 16.9026 23.6347 16.7467V12.6007C23.6347 8.6104 21.1963 5.17917 17.7314 3.71819C17.7239 1.66667 16.053 0 14 0C11.9469 0 10.2761 1.66677 10.2686 3.71809C6.80375 5.17908 4.36537 8.61031 4.36537 12.6006V16.7466C4.36311 16.9025 4.24174 23.0824 1.05355 24.5222C0.690359 24.6863 0.490751 25.0816 0.574722 25.4712C0.658594 25.8609 1.00315 26.1392 1.40173 26.1392H9.4841C9.64979 27.0506 10.0808 27.8986 10.7399 28.5854C11.6154 29.4975 12.7732 29.9999 13.9998 29.9999C15.2266 29.9999 16.3844 29.4975 17.2598 28.5854C17.919 27.8985 18.35 27.0505 18.5156 26.1392H26.598C26.9965 26.1392 27.341 25.8609 27.425 25.4712C27.5091 25.0816 27.3096 24.6863 26.9464 24.5223ZM22.7467 21.5618C23.0819 22.5778 23.5564 23.5982 24.2294 24.4473H3.77051C4.44336 23.5983 4.91792 22.578 5.25306 21.5618H22.7467ZM14 1.69191C14.9287 1.69191 15.7139 2.31594 15.9596 3.16652C15.3266 3.03525 14.6712 2.96603 14 2.96603C13.3288 2.96603 12.6734 3.0352 12.0404 3.16652C12.2862 2.31598 13.0714 1.69191 14 1.69191ZM6.05728 16.7518V12.6006C6.05728 8.22099 9.62033 4.65795 14 4.65795C18.3797 4.65795 21.9427 8.22099 21.9427 12.6006V16.7587C21.9438 16.886 21.9636 18.2234 22.3007 19.8697H5.69909C6.03635 18.2218 6.05625 16.882 6.05728 16.7518ZM14 28.3081C12.7088 28.3081 11.5818 27.3772 11.2201 26.1392H16.7798C16.418 27.3772 15.2913 28.3081 14 28.3081Z" fill="currentColor" />
              <path d="M14.0003 5.84473C10.3605 5.84473 7.39941 8.80576 7.39941 12.4454C7.39941 12.9126 7.77819 13.2914 8.2454 13.2914C8.7126 13.2914 9.09138 12.9126 9.09138 12.4454C9.09138 9.7387 11.2935 7.53664 14.0003 7.53664C14.4675 7.53664 14.8463 7.15787 14.8463 6.69066C14.8462 6.2234 14.4675 5.84473 14.0003 5.84473Z" fill="currentColor" />
            </svg>
            Subscription information:
          </h2>
          <p>*Subscription renewal automatically on 1st day of the month at 12am Pacific Time</p>
          <p>You can cancel auto-renew at any time or edit your payment. By making this payment, you agree to Gorudo’s Terms of Use.</p>
          <p>Depending on your location your bank might charge an additional foreign transaction fee for your membership to this Creator.</p>
        </div>
        <Spacing lg='30' md='30'/>
        <div className="cs-text_box">
          <h2>About Junaice Arora Ho</h2>
          <p>Welcome and look forwarding to you to join us as a team.</p>
          <p>We are a leading flow traders team. <br />Our team specialisted specialized in Exchange Traded Products (ETPs). We also aim to provide flow traders trading and offers training in dealing with market and liquidity in Digital Assets, Fixed Income, Commodities and Foreign Exchange (FX). We started in 2020 and continuously growing our team and ensuring you have all the relevant information and have able to receive our real time boardcast and alerts via Gorudo platform.</p>
          <p>The informaiton shared will be invaluable and believe this could change your trading and profit and loss forever. Flow trading is niche and we are a few people that willing to share this this tactic and mentor you and also demostrate in real time to let you master this live time skillset.</p>
        </div>
        <Spacing lg='80' md='60'/>
        <h2 style={{fontSize:'30px', textAlign:'center', marginBottom:'0'}}>Recent Newsletters</h2>
        <Spacing lg='20' md='20'/>
        <div className="cs-card cs-style4">
          <div className="cs-card_meta">
            <img src="/images/user2.png" alt="User" />
            <div style={{color:'#353535'}}>
              <p className='cs-m0' style={{textTransform:'uppercase', letterSpacing:'0.05em'}}>Mika MAtikainen</p>
              <p className='cs-m0'>10:32pm ; 31 Jul 2022</p>
            </div>
          </div>
          <div className="cs-card_info">
            <div className="cs-card_thumb">
              <img src="/images/thumb_1.jpeg" alt="" />
              <div className="cs-card_overlay">
                <svg width="30" height="45" viewBox="0 0 30 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z" fill="white"/>
                </svg>
                <h3>Subscribe to unlock post</h3>
                <Link to='/' className='cs-card_btn'>Subscribe now</Link>
              </div>
            </div>
            <h3>A few words about this blog platform, Ghost, and how this site was made</h3>
            <p>The informaiton shared will be invaluable and believe this could change your trading and profit and loss forever. Flow trading is niche and we are a few people that willing to share this this tactic and mentor you and also demostrate in real time to let you master this live time skillset.</p>
            <div className="cs-tags">
              <Link to='/' className='cs-tag'>All</Link>
              <Link to='/' className='cs-tag'>Photography</Link>
              <Link to='/' className='cs-tag'>Career</Link>
              <Link to='/' className='cs-tag'>Branding</Link>
            </div>
          </div>
        </div>
        <Spacing lg='25' md='25'/>
        <div className="cs-card cs-style4">
          <div className="cs-card_meta">
            <img src="/images/user2.png" alt="User" />
            <div style={{color:'#353535'}}>
              <p className='cs-m0' style={{textTransform:'uppercase', letterSpacing:'0.05em'}}>Mika MAtikainen</p>
              <p className='cs-m0'>10:32pm ; 31 Jul 2022</p>
            </div>
          </div>
          <div className="cs-card_info">
            <div className="cs-card_thumb">
              <div className="cs-card_overlay">
                <svg width="30" height="45" viewBox="0 0 30 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z" fill="white"/>
                </svg>
                <h3>Subscribe to unlock post</h3>
                <ScrollLink to="membership_tire" spy={true} smooth={true} offset={-75} duration={500} className='cs-card_btn'>Subscribe now</ScrollLink>
              </div>
            </div>
            <h3>A few words about this blog platform, Ghost, and how this site was made</h3>
            <p>The informaiton shared will be invaluable and believe this could change your trading and profit and loss forever. Flow trading is niche and we are a few people that willing to share this this tactic and mentor you and also demostrate in real time to let you master this live time skillset.</p>
            <ReadMore count={250}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
              Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
              natoque penatibus et magnis dis parturient montes,
              nascetur ridiculus mus. Donec quam felis, ultricies nec,
              pellentesque eu, pretium quis, sem. Nulla consequat massa
              quis enim. Donec pede justo, fringilla vel, aliquet nec,
              vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet
              a, venenatis vitae, justo. Nullam dictum felis eu pede
              mollis pretium. Integer tincidunt. Cras dapibus. Vivamus
              elementum semper nisi. Aenean vulputate eleifend tellus.
              Aenean leo ligula, porttitor eu, consequat vitae, eleifend
              ac, enim. Aliquam lorem ante, dapibus in, viverra quis,
              feugiat a, tellus. Phasellus viverra nulla ut metus varius
              laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies
              nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam
              eget dui. Etiam rhoncus. Maecenas tempus, tellus eget
              condimentum rhoncus, sem quam semper libero, sit amet
              adipiscing sem neque sed ipsum. Nam quam nunc, blandit
              vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec
              odio et ante tincidunt tempus. Donec vitae sapien ut
              libero venenatis faucibus. Nullam quis ante. Etiam sit
              amet orci eget eros faucibus tincidunt. Duis leo. Sed
              fringilla mauris sit amet nibh. Donec sodales sagittis
              magna. Sed consequat, leo eget bibendum sodales, augue
              velit cursus nunc, quis gravida magna mi a libero. Fusce
              vulputate eleifend sapien. Vestibulum purus quam,
              scelerisque ut, mollis sed, nonummy id, metus. Nullam
              accumsan lorem in dui. Cras ultricies mi eu turpis
              hendrerit fringilla. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia Curae; In
              ac dui quis mi consectetuer lacinia. Nam pretium turpis et
              arcu. Duis arcu tortor, suscipit eget, imperdiet nec,
              imperdiet iaculis, ipsum. Sed aliquam ultrices mauris.
              Integer ante arcu, accumsan a, consectetuer eget, posuere
              ut, mauris. Praesent adipiscing. Phasellus ullamcorper
              ipsum rutrum nunc. Nunc nonummy metus. Vestib
            </ReadMore>
            <div className="cs-tags">
              <Link to='/' className='cs-tag'>All</Link>
              <Link to='/' className='cs-tag'>Photography</Link>
              <Link to='/' className='cs-tag'>Career</Link>
              <Link to='/' className='cs-tag'>Branding</Link>
            </div>
          </div>
        </div>
        <Spacing lg='25' md='25'/>
        <div className="cs-card cs-style4">
          <div className="cs-card_meta">
            <img src="/images/user2.png" alt="User" />
            <div style={{color:'#353535'}}>
              <p className='cs-m0' style={{textTransform:'uppercase', letterSpacing:'0.05em'}}>Mika MAtikainen</p>
              <p className='cs-m0'>10:32pm ; 31 Jul 2022</p>
            </div>
          </div>
          <div className="cs-card_info">
            <div className="cs-card_thumb">
              <img src="/images/thumb_1.jpeg" alt="" />
              <div className="cs-card_overlay">
                <svg width="30" height="45" viewBox="0 0 30 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.375 18.3019V9.375C24.375 4.19813 20.1788 0 15 0C9.81938 0 5.625 4.19813 5.625 9.375V18.3019C2.19937 21.0506 0 25.2656 0 30C0 38.2838 6.71625 45 15 45C23.2838 45 30 38.2838 30 30C30 25.2656 27.8006 21.0506 24.375 18.3019ZM9.375 9.375C9.375 6.27375 11.8987 3.75 15 3.75C18.1013 3.75 20.625 6.27375 20.625 9.375V16.1006C18.8869 15.3956 16.9913 15 15 15C13.0087 15 11.1131 15.3956 9.375 16.1006V9.375ZM15 41.25C8.79562 41.25 3.75 36.2044 3.75 30C3.75 23.7956 8.79562 18.75 15 18.75C21.2044 18.75 26.25 23.7956 26.25 30C26.25 36.2044 21.2044 41.25 15 41.25ZM18.75 30C18.75 32.07 17.07 33.75 15 33.75C12.93 33.75 11.25 32.07 11.25 30C11.25 27.93 12.93 26.25 15 26.25C17.07 26.25 18.75 27.93 18.75 30Z" fill="white"/>
                </svg>
                <h3>Subscribe to unlock post</h3>
                <ScrollLink to="membership_tire" spy={true} smooth={true} offset={-75} duration={500} className='cs-card_btn'>Subscribe now</ScrollLink>
              </div>
            </div>
            <h3>A few words about this blog platform, Ghost, and how this site was made</h3>
            <p>The informaiton shared will be invaluable and believe this could change your trading and profit and loss forever. Flow trading is niche and we are a few people that willing to share this this tactic and mentor you and also demostrate in real time to let you master this live time skillset.</p>
            <div className="cs-tags">
              <Link to='/' className='cs-tag'>All</Link>
              <Link to='/' className='cs-tag'>Photography</Link>
              <Link to='/' className='cs-tag'>Career</Link>
              <Link to='/' className='cs-tag'>Branding</Link>
            </div>
          </div>
        </div>
        <Spacing lg='25' md='25'/>
        <Spacing lg='35' md='35'/>
      </div>
    </div>
  )
}
