import { Icon } from '@iconify/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteWebinar,
  getWebinarList,
  updateWebinarPinPost,
} from '../../../../api/services/webinarVideo';
import LoadingSpinner from '../../../../components/Common/LoadingSpinner';
import DotOption from '../../../../components/DotOption';
import Spacing from '../../../../components/Spacing';
import VideoModal from '../../../../components/VideoModal';
import { removeHtml } from '../../../../utils/removeHtml';
import { tagStringToArray } from '../../../../utils/tagStringToArray';
import { textLimit } from '../../../../utils/TextLimit';
import { v4 as uuidv4 } from 'uuid';
import PaginateButton from '../../../../components/Common/PaginateButton';
import { defaultHasMoreCount } from '../../../../config/const';
import EmptyData from '../../../../components/Common/EmptyData';
import { useTranslation } from 'react-i18next';

export default function PublishedWebinar({ refreshPin }) {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [webinars, setWebinars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [count, setCount] = useState(0);
  const selectedTag = searchParams.get('tag') ?? '';
  const searchQuery = searchParams.get('search') ?? '';

  // get webinar
  const getWebinar = async () => {
    setLoading(true);
    const res = await getWebinarList(1, 5, searchQuery, 0, '', selectedTag);
    setLoading(false);
    setWebinars(tagStringToArray(res.data.data));
    setCount(res.data.count);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getWebinarList(page, 5, searchQuery, 0, '', selectedTag);
    setWebinars([...webinars, ...tagStringToArray(res.data.data)]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // handle delete
  const handleDelete = async post_id => {
    Swal.fire({
      title: t('are_you_sure'),
      text: t('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_delete_it'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await deleteWebinar(post_id);
          if (res.data.success === 1) {
            Swal.fire(t('deleted'), t('your_post_has_been_deleted'), 'success');
            getWebinar();
            setPage(2);
          }
        } catch (e) {}
      }
    });
  };

  // handle update pin
  const updatePinPost = async (post_id, is_pinned) => {
    const pinType = is_pinned === 1 ? 0 : 1;
    const data = {
      post_id,
      is_pinned: pinType,
    };
    await updateWebinarPinPost(data);
    getWebinar();
    setPage(2);
    refreshPin();
  };

  useEffect(() => {
    getWebinar();
  }, [searchParams]);
  return (
    <>
      <div className={`cs-card_8_wrap ${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {webinars.map((item, index) => (
          <div key={index} className="cs-card cs-style8">
            <ul className="cs-card_meta cs-mp0">
              <li>
                <Icon icon="fa6-solid:flag" />
                <span>
                  {t('due_to')} {moment(item.created_at).format('DD MMM Y')}
                </span>
              </li>
              <li>
                <Icon icon="fa6-solid:clock" />
                <span>{moment(item.created_at).format('LT')}</span>
              </li>
            </ul>
            <div className="cs-video_thumb">
              <img
                style={{ width: '100%' }}
                src={item.image === '' ? '/images/thumb_3.jpeg' : item.image}
                alt="Thumb"
              />
              <VideoModal videoSrc={item.video} />
            </div>
            <h2 className="cs-card_title cs-break-all">{item.title}</h2>
            <div className="cs-card_desc cs-break-all">
              {textLimit(removeHtml(item.detail), 435)}
            </div>
            <Link to={`/webinar-video/${item.id}/view`} className="cs-card_btn">
              {t('continue_reading')}
            </Link>
            <Spacing lg="15" md="15" />
            <div className="cs-tags">
              {item.tag.map(tag => (
                <Link
                  to={`/webinar-video?tag=${tag}`}
                  className={`cs-tag ${selectedTag === tag ? 'active' : ''}`}
                  key={uuidv4()}
                >
                  {tag}
                </Link>
              ))}
            </div>
            <DotOption
              url={`/dashboard/${item.userId}/webinar-video/${item.id}`}
            >
              <Link to={`/webinar-video/${item.id}/edit`}>
                <Icon icon="material-symbols:edit" /> {t('edit')}
              </Link>
              <button onClick={() => handleDelete(item.id)}>
                <Icon icon="material-symbols:delete-rounded" /> {t('delete')}
              </button>
            </DotOption>
            <button
              className={`cs-icon_btn cs-pit_btn ${
                item.is_pinned === 1 ? 'active' : ''
              }`}
              onClick={() => updatePinPost(item.id, item.is_pinned)}
            >
              <svg
                width={9}
                height={13}
                viewBox="0 0 9 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        ))}
        {!webinars.length && !loading ? <EmptyData /> : null}
      </div>
      <Spacing lg="20" md="20" />
      {count > defaultHasMoreCount && (
        <PaginateButton
          loading={paginateLoading}
          hasMore={hasMore}
          onClick={paginate}
        />
      )}
    </>
  );
}
