import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import Spacing from '../../components/Spacing';

export default function EmailConfirmation() {
  const { t } = useTranslation();
  return (
    <>
      <Header starter />
      <Spacing lg="90" md="90" />
      <div className="cs-display_center">
        <div className="cs-container_md">
          <div className="cs-text_center">
            <img src="/images/icon/envlop.png" alt="Icon" />
            <Spacing lg="30" md="30" />
            <h2 className="cs-m0" style={{ fontSize: '25px' }}>
              {t('email_confirmation_text_1')}
            </h2>
            <Spacing lg="15" md="15" />
            <div
              className="cs-bold"
              style={{
                fontSize: '18px',
                lineHeight: '1.6em',
                color: '#808080',
              }}
            >
              {t('email_confirmation_text_2')}
            </div>
          </div>
          <Spacing lg="100" md="70" />
        </div>
      </div>
    </>
  );
}
