import {
  createResourceUrl,
  getSingleResourceUrl,
  resourceDeleteUrl,
  resourceUrl,
  updateResourcePinUrl,
  updateResourceUrl,
  uploadResourceImageUrl,
} from "../../const";
import axios from "../../lib/axios";

// create resource

export const createResource = async (data) => {
  return await axios.post(createResourceUrl, data);
};

// upload resource image
export const uploadResourceImage = async (data) => {
  return await axios.post(uploadResourceImageUrl, data);
};

// get single resource
export const getSingleResource = async (post_id) => {
  return await axios.get(getSingleResourceUrl, { params: { post_id } });
};

// update resource
export const updateResource = async (data) => {
  return await axios.put(updateResourceUrl, data);
};

// get resource
export const getResource = async (
  page = 1,
  limit = 5,
  search = "",
  is_draft = 0,
  pin = "",
  tag = "",
  post_id = ""
) => {
  return await axios.get(resourceUrl, {
    params: { page, limit, search, is_draft, pin, tag, post_id },
  });
};

// update resource pin
export const updateResourcePin = async (data) => {
  return await axios.post(updateResourcePinUrl, data);
};

// delete resource
export const deleteResource = async (post_id) => {
  return await axios.delete(resourceDeleteUrl, { params: { post_id } });
};
