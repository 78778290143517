import React, { useContext, useEffect, useRef, useState } from "react";
import LoadingSpinner from "../Common/LoadingSpinner";
import { v4 as uuidv4 } from "uuid";
import Spacing from "../Spacing";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import TierContext from "../../context/tier/tier-context";
import {
  createTradeDiary,
  createTradeDiaryTemplate,
  updateTradeDiary,
} from "../../api/services/tradeDiary";
import { useTranslation } from "react-i18next";
import { getUserTierPermission } from "../../api/services/auth/user";

export default function TradeDairyCrud({
  title,
  editTradeDairy,
  typeLoading,
  tradeDiaryTypes,
  refreshTradeDiary,
  updatedTradeDiary,
  closeModal,
  setFilterId,
  filterId,
  refreshTemplate,
}) {
  const { t } = useTranslation();
  const [filterToggle, setFilterToggle] = useState(false);
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(1);
  const [showTierWarning, setShowTierWarning] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectText, setSelectText] = useState("1");
  const [open, setOpen] = useState(false);
  const refOne = useRef(null);
  const [creatorTier, setCreatorTier] = useState([]);
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [isSaveTemplate, setIsSaveTemplate] = useState(false);
  const [selectedTiers, setSelectedTiers] = useState([]);

  const formSchema = Yup.object().shape({
    tradeDairyTypeId: Yup.number(),
    stock_name: Yup.string().required("The ticker name field is required"),
    stock_price: Yup.string().nullable(),
    note: Yup.string().nullable(),
    stop_loss: Yup.string(),
    visibility: Yup.string(),
    tiers: Yup.mixed(),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  const getUserTier = async () => {
    const res = await getUserTierPermission(2);
    setCreatorTier(res.data.data);
  };

  const handleTierCheckboxChange = (e, tierId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedTiers([...selectedTiers, tierId]); // Add the selected tier to the array
    } else {
      setSelectedTiers(selectedTiers.filter((id) => id !== tierId)); // Remove the deselected tier from the array
    }
  };

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };
  // select filter
  const selectFilter = (id) => {
    const exists = filterId.find((element) => element === id);
    if (exists) {
      const array = [...filterId];
      var filteredArray = array.filter((e) => e !== id);
      setFilterId(filteredArray);
    } else {
      setFilterId([...filterId, id]);
    }
  };

  // handle publish
  const handlePublish = async (data) => {
    if (selectText === "3" && selectedTiers.length === 0) {
      setShowTierWarning(true);
    } else {
      setShowTierWarning(false);
      setLoading(true);
      data.tiers = selectedTiers ? selectedTiers.join(", ") : "";
      if (!data.stock_price) {
        data.stock_price = "Now";
      }
      if (editTradeDairy) {
        const updateData = { ...data, ...{ post_id: updatedTradeDiary.id } };
        await updateTradeDiary(updateData);
        closeModal();
        refreshTradeDiary();
      } else {
        if (isSaveTemplate) {
          const templateData = { ...data, ...{ template_name: templateName } };
          await createTradeDiaryTemplate(templateData);
          setIsSaveTemplate(false);
          setModal(false);
          refreshTemplate();
        } else {
          await createTradeDiary(data);
          refreshTradeDiary();
        }
      }
      reset();
      setActive(1);
      setValue("visibility", "1");
      setValue("tradeDairyTypeId", "1");
      setLoading(false);
    }
  };

  // handle select all
  useEffect(() => {
    if (isSelectAll) {
      setFilterId([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
    } else {
      setFilterId([]);
    }
  }, [isSelectAll]);

  useEffect(() => {
    getUserTier();
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    // setDefault value
    setValue("note", updatedTradeDiary.note ?? "");
    setValue("stock_name", updatedTradeDiary.stock_name ?? "");
    setValue("stock_price", updatedTradeDiary.stock_price ?? "");
    setValue("stop_loss", updatedTradeDiary.stop_loss ?? "");
    setValue("tradeDairyTypeId", updatedTradeDiary.tradeDairyTypeId ?? 1);
    // setValue(
    //   "tiers",
    //   updatedTradeDiary.tiers ? updatedTradeDiary.tiers.split(", ") : []
    // );
    setSelectedTiers(
      updatedTradeDiary && updatedTradeDiary.tiers
        ? updatedTradeDiary.tiers.split(", ").map((str) => parseInt(str))
        : []
    );
    setActive(updatedTradeDiary.tradeDairyTypeId ?? 1);
    setValue("visibility", updatedTradeDiary.visibility ?? "1");
    setSelectText(updatedTradeDiary.visibility ?? "1");
  }, [updatedTradeDiary]);

  return (
    <form onSubmit={handleSubmit(handlePublish)}>
      <div className="cs-section_heading_5">
        <h2 className="cs-m0">{title}</h2>
        {!editTradeDairy && (
          <div
            className={filterToggle ? `dairy_filter active` : `dairy_filter`}
          >
            <div
              className="dairy_filter_btn"
              onClick={() => setFilterToggle(!filterToggle)}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2070_1025)">
                  <path
                    d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0391 16 14.5304 16 14V2C16 1.46957 15.7893 0.960859 15.4142 0.585786C15.0391 0.210714 14.5304 0 14 0L2 0ZM2.5 5H13.5C13.6326 5 13.7598 5.05268 13.8536 5.14645C13.9473 5.24021 14 5.36739 14 5.5C14 5.63261 13.9473 5.75979 13.8536 5.85355C13.7598 5.94732 13.6326 6 13.5 6H2.5C2.36739 6 2.24021 5.94732 2.14645 5.85355C2.05268 5.75979 2 5.63261 2 5.5C2 5.36739 2.05268 5.24021 2.14645 5.14645C2.24021 5.05268 2.36739 5 2.5 5ZM4 8.5C4 8.36739 4.05268 8.24021 4.14645 8.14645C4.24021 8.05268 4.36739 8 4.5 8H11.5C11.6326 8 11.7598 8.05268 11.8536 8.14645C11.9473 8.24021 12 8.36739 12 8.5C12 8.63261 11.9473 8.75979 11.8536 8.85355C11.7598 8.94732 11.6326 9 11.5 9H4.5C4.36739 9 4.24021 8.94732 4.14645 8.85355C4.05268 8.75979 4 8.63261 4 8.5ZM6 11.5C6 11.3674 6.05268 11.2402 6.14645 11.1464C6.24021 11.0527 6.36739 11 6.5 11H9.5C9.63261 11 9.75979 11.0527 9.85355 11.1464C9.94732 11.2402 10 11.3674 10 11.5C10 11.6326 9.94732 11.7598 9.85355 11.8536C9.75979 11.9473 9.63261 12 9.5 12H6.5C6.36739 12 6.24021 11.9473 6.14645 11.8536C6.05268 11.7598 6 11.6326 6 11.5Z"
                    fill="#4B9DDC"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2070_1025">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              {t("filter")}
            </div>
            <div className="dairy_filter_body">
              <h3>
                Filtered {filterId.length} types
                <span
                  className="filter_close"
                  onClick={() => setFilterToggle(!filterToggle)}
                >
                  <svg
                    width="9"
                    height="9"
                    viewBox="0 0 9 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L8 8" stroke="currentColor" />
                    <path d="M8 1L1 8" stroke="currentColor" />
                  </svg>
                </span>
              </h3>
              <ul>
                <li>
                  <div className="cs-check">
                    <input
                      id="select-all"
                      type="checkbox"
                      // value={item.id}
                      onChange={() => setIsSelectAll(!isSelectAll)}
                    />
                    <label htmlFor="select-all">SELECT ALL</label>
                  </div>
                </li>
                {tradeDiaryTypes.map((item, index) => (
                  <li key={index}>
                    <div className="cs-check">
                      <input
                        id={item.id}
                        type="checkbox"
                        value={item.id}
                        checked={filterId.indexOf(item.id) !== -1}
                        onChange={() => selectFilter(item.id)}
                      />
                      <label htmlFor={item.id}>{item.name}</label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <Spacing lg="20" md="20" />
      <div
        className={`cs-card cs-style11 ${typeLoading ? "cs-loading_wrap" : ""}`}
      >
        {typeLoading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <div className="cs-card_labels">
          {tradeDiaryTypes.map((item) => (
            <div className="cs-card_label" key={uuidv4()}>
              <span
                style={{
                  backgroundColor:
                    active === item.id ? item.color_background : "",
                  color: active === item.id ? item.color_text : "",
                }}
                onClick={() => {
                  setActive(item.id);
                  setValue("tradeDairyTypeId", item.id);
                }}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
        <div className="cs-row">
          <div className="cs-col_6">
            <label>{t("stock_name")} *</label>
            <input
              type="text"
              placeholder={t("enter_stock_name")}
              className="cs-form_field"
              {...register("stock_name")}
            />
            {errors.stock_name && (
              <p className="invalid-feedback">{errors.stock_name?.message}</p>
            )}
            <Spacing lg="20" md="20" />
          </div>
          <div className="cs-col_6">
            <label>{t("price")} </label>
            <input
              type="text"
              placeholder={t("now")}
              className="cs-form_field"
              step="any"
              {...register("stock_price")}
            />
            {errors.stock_price && (
              <p className="invalid-feedback">{errors.stock_price?.message}</p>
            )}
            <Spacing lg="20" md="20" />
          </div>
          <div className="cs-col_6">
            <label>{t("note")}</label>
            <input
              type="text"
              placeholder={t("enter_text")}
              className="cs-form_field"
              {...register("note")}
            />
            <Spacing lg="20" md="20" />
          </div>
          <div className="cs-col_6">
            <label>{t("stop_loss")}</label>
            <input
              type="number"
              placeholder={t("enter_value")}
              className="cs-form_field"
              step="any"
              {...register("stop_loss")}
            />
            <Spacing lg="20" md="20" />
          </div>
          <div className="cs-col_6">
            <div style={{ maxWidth: "200px" }}>
              <div className="cs-dropdown_wrap_2" ref={refOne}>
                <div
                  className="cs-dropdown_btn_2"
                  onClick={() => setOpen(!open)}
                >
                  <span>
                    {selectText === "1"
                      ? `${t("all_tiers")}`
                      : selectText === "2"
                      ? `${t("all_paid_members")}`
                      : selectText === "3"
                      ? `${t("select_tiers_to_share")}`
                      : ""}
                  </span>
                  <button type="button" className="cs-dropdown_btn_arrow">
                    <Icon icon="ic:round-keyboard-arrow-down" />
                  </button>
                </div>
                {open && (
                  <ul className="cs-dropdown_2 cs-mp0">
                    <li className={`${selectText === "1" ? "active" : ""}`}>
                      <span
                        onClick={() => {
                          setSelectText("1");
                          setValue("visibility", "1");
                        }}
                      >
                        {t("all_tiers")} <br />({t("included_free_tier")})
                      </span>
                    </li>
                    <li className={`${selectText === "2" ? "active" : ""}`}>
                      <span
                        onClick={() => {
                          setSelectText("2");
                          setValue("visibility", "2");
                        }}
                      >
                        {t("all_paid_members")}
                      </span>
                    </li>
                    <li className={`${selectText === "3" ? "active" : ""}`}>
                      <span
                        onClick={() => {
                          setSelectText("3");
                          setValue("visibility", "3");
                        }}
                      >
                        {t("select_tiers_to_share")}
                      </span>
                      <ul className="cs-mp0">
                        {creatorTier.map((item, index) => (
                          <li key={`tier-${index}`}>
                            <div className="cs-check">
                              <input
                                type="checkbox"
                                value={item.id}
                                checked={selectedTiers?.includes(item.id)} // Check if the item's id is in the selectedTiers array
                                onChange={(e) =>
                                  handleTierCheckboxChange(e, item.id)
                                } // Call a function to update the selected tiers
                              />
                              <label>{item.title}</label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                )}
              </div>
              {showTierWarning && (
                <span className="cs-danger">{t("tier_warning_text")}</span>
              )}
            </div>
          </div>
        </div>
        <Spacing lg="30" md="30" />
        <div className="cs-card_btns">
          <div>
            {!editTradeDairy && (
              <button
                type="button"
                className="cs-card_btn"
                onClick={() => {
                  setModal(!modal);
                  setIsSaveTemplate(true);
                }}
                style={{ textTransform: "uppercase" }}
              >
                {t("save_as_template")}
              </button>
            )}
          </div>
          <div>
            <button
              type="submit"
              className={`cs-card_btn cs-color1 ${
                loading ? "cs-loading_wrap" : ""
              }`}
              loading={loading}
              style={{ textTransform: "uppercase" }}
            >
              {loading && (
                <span className="cs-btn_loading">
                  <Icon icon="la:spinner" />
                  {t("loading")}
                </span>
              )}
              {t("publish")}
            </button>
          </div>
        </div>
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => {
              setModal(false);
              setIsSaveTemplate(false);
            }}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style1"
                style={{ maxWidth: "540px" }}
              >
                <Spacing lg="50" md="50" />
                <div className="cs-modal_box_in">
                  <h2
                    className="cs-m0"
                    style={{ fontSize: "26px", fontWeight: "600" }}
                  >
                    {t("save_as_template")}
                  </h2>

                  {errors.stock_name && (
                    <p className="invalid-feedback">
                      {errors.stock_name?.message}
                    </p>
                  )}
                  {errors.stock_price && (
                    <p className="invalid-feedback">
                      {errors.stock_price?.message}
                    </p>
                  )}
                  <Spacing lg="35" md="25" />
                  <h2
                    className="cs-m0"
                    style={{ fontSize: "21px", fontWeight: "600" }}
                  >
                    {t("set_template_name")}
                  </h2>
                  <Spacing lg="25" md="25" />
                  <label>{t("name")} *</label>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        right: "0",
                        bottom: "7px",
                      }}
                    >
                      {templateName.length}/
                      <span className="cs-accent_color">30</span>
                    </span>
                    <input
                      type="text"
                      maxLength="30"
                      placeholder={t("enter_name")}
                      className="cs-form_field"
                      style={{ paddingRight: "60px" }}
                      required
                      onChange={(e) => {
                        setTemplateName(e.target.value);
                      }}
                    />
                  </div>
                  <Spacing lg="30" md="30" />
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className={`cs-modal_btn cs-color1 ${
                        loading ? "cs-loading_wrap" : ""
                      }`}
                      style={{
                        width: "100%",
                        maxWidth: "250px",
                        borderRadius: "5px",
                      }}
                    >
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.6924 0H0.5V20H20.5V2.80762L17.6924 0ZM11.5 1.22449H12.7245V4.89797H11.5V1.22449ZM5.82652 1.22449H10.2755V6.12246H13.949V1.22449H15.2143V7.34695H5.82652V1.22449ZM16.8265 18.7755H4.17348V13.4694H16.8265V18.7755ZM16.8265 12.2449H4.17348V11.0204H16.8265V12.2449ZM19.2755 18.7755H18.051V9.7959H2.94898V18.7755H1.72449V1.22449H4.60203V8.57145H16.4388V1.22449H17.1852L19.2755 3.3148V18.7755H19.2755Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span style={{ marginLeft: "10px" }}>
                        {loading && (
                          <span className="cs-btn_loading">
                            <Icon icon="la:spinner" />
                            {t("loading")}
                          </span>
                        )}
                        {t("save_as_template")}
                      </span>
                    </button>
                  </div>
                </div>
                <Spacing lg="50" md="50" />
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
