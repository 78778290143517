import React, { useState } from 'react';
import Spacing from '../../components/Spacing';
import { useTranslation } from 'react-i18next';

export default function MembershipTiersEmpty() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  return (
    <>
      <div className="cs-text_center">
        <img src="/images/empty_tires.svg" alt="" />
        <div
          style={{ fontSize: '18px', color: '#2C2C2C', lineHeight: '1.6em' }}
        >
          {t('there_is_no_membership_tiers')}
        </div>
      </div>
      <Spacing lg="30" md="20" />
      <div className="cs-row">
        <div className="cs-col_6">
          <button
            className="cs-section_btn cs-color1"
            onClick={() => setModal(true)}
            style={{ width: '100%' }}
          >
            {t('create_free_tier')}
          </button>
        </div>
        <div className="cs-col_6">
          <Spacing lg="0" md="10" />
          <button
            className="cs-section_btn cs-filed"
            onClick={() => setModal2(true)}
            style={{ width: '100%' }}
          >
            {t('add_more_paid_tiers')}
          </button>
        </div>
      </div>
      <Spacing lg="30" md="30" />
      <div className="cs-text_center">
        <button className="cs-text_btn">{t('skip_for_now')}</button>
      </div>
      <Spacing lg="60" md="60" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="50" md="50" />
                <div className="cs-modal_box_in">
                  <h2
                    className="cs-m0"
                    style={{ fontSize: '21px', fontWeight: '600' }}
                  >
                    {t('create_free_membership_tier')}
                  </h2>
                  <Spacing lg="25" md="25" />
                  <label>
                    {t('onboarding_free_membership_tier_input_1')} *
                  </label>
                  <input
                    type="text"
                    placeholder={t(
                      'onboarding_free_membership_tier_input_placeholder_1',
                    )}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                  <h2
                    className="cs-semibold"
                    style={{ fontSize: '21px', marginBottom: '5px' }}
                  >
                    {t('subscription_features')} *
                  </h2>
                  <h3 className="cs-semibold" style={{ fontSize: '13px' }}>
                    {t('subscription_features_subtitle')} *
                  </h3>
                  <Spacing lg="5" md="5" />
                  <div style={{ paddingLeft: '5%' }}>
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('broadcast')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('trade_diary')}</label>
                    </div>
                  </div>
                  <Spacing lg="25" md="25" />
                  <div className="cs-check">
                    <input type="checkbox" />
                    <label>
                      {t('accept_the')}{' '}
                      <a href="https://gorudo.io/terms-of-use" target="_blank">
                        {t('terms_of_use')}
                      </a>
                      .{' '}
                    </label>
                  </div>
                  <Spacing lg="15" md="15" />
                  <button
                    className="cs-modal_btn cs-color1"
                    style={{ width: '100%', borderRadius: '5px' }}
                  >
                    {t('next')}
                  </button>
                </div>
                <Spacing lg="50" md="50" />
              </div>
            </div>
          </div>
        </div>
      )}
      {modal2 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal2(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style1">
                <Spacing lg="40" md="40" />
                <div className="cs-modal_box_in">
                  <h2
                    className="cs-m0"
                    style={{ fontSize: '21px', fontWeight: '600' }}
                  >
                    {t('create_paid_membership_tier')}
                  </h2>
                  <Spacing lg="25" md="25" />
                  <label>
                    {t('onboarding_paid_membership_tier_input_1')} *
                  </label>
                  <input
                    type="text"
                    placeholder={t(
                      'onboarding_paid_membership_tier_input_placeholder_1',
                    )}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                  <label>{t('onboarding_paid_membership_tier_input_2')}</label>
                  <input
                    type="text"
                    placeholder={t(
                      'onboarding_paid_membership_tier_input_placeholder_2',
                    )}
                    className="cs-form_field"
                  />
                  <Spacing lg="20" md="20" />
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <label>
                      {t('onboarding_paid_membership_tier_input_3')} *
                    </label>
                    <span>
                      00/<span className="cs-accent_color">80</span>
                    </span>
                  </div>
                  <textarea
                    cols="30"
                    rows="2"
                    className="cs-form_field"
                    placeholder={t(
                      'onboarding_paid_membership_tier_input_placeholder_3',
                    )}
                  ></textarea>
                  <Spacing lg="20" md="20" />
                  <h2
                    className="cs-semibold"
                    style={{ fontSize: '21px', marginBottom: '5px' }}
                  >
                    {t('subscription_features')} *{' '}
                  </h2>
                  <h3 className="cs-semibold" style={{ fontSize: '13px' }}>
                    ({t('subscription_features_subtitle')})
                  </h3>
                  <div style={{ paddingLeft: '5%' }}>
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('broadcast')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('trade_diary')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('watchlist')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('open_position')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('newsletter')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('webinar_video')}</label>
                    </div>
                    <Spacing lg="10" md="10" />
                    <div className="cs-check">
                      <input type="checkbox" />
                      <label>{t('resources_faq')}</label>
                    </div>
                  </div>
                  <Spacing lg="20" md="20" />
                  <label>{t('tier_price')} *</label>
                  <div className="cs-with_info">
                    <input
                      type="text"
                      placeholder={t('monthly_subscription_fee_Min')}
                      className="cs-form_field"
                    />
                    <div className="cs-info_text">
                      USD
                      <span className="cs-info_text_in">
                        ?
                        <div className="cs-info_tooltip">
                          2.9% Stripe Charge
                        </div>
                      </span>
                    </div>
                  </div>
                  <Spacing lg="20" md="20" />
                  <div className="cs-check">
                    <input type="checkbox" />
                    <label>
                      {t('onboarding_paid_membership_tier_input_5')}{' '}
                    </label>
                  </div>
                  <input
                    type="text"
                    placeholder={t(
                      'onboarding_paid_membership_tier_input_placeholder_5',
                    )}
                    className="cs-form_field"
                    style={{ marginLeft: '28px' }}
                  />
                  <Spacing lg="25" md="25" />
                  <div className="cs-check">
                    <input type="checkbox" />
                    <label>
                      {t('accept_the')}{' '}
                      <a href="https://gorudo.io/terms-of-use" target="_blank">
                        {t('terms_of_use')}
                      </a>
                      .{' '}
                    </label>
                  </div>
                  <Spacing lg="15" md="15" />
                  <button
                    className="cs-modal_btn cs-color1"
                    style={{ width: '100%', borderRadius: '5px' }}
                  >
                    {t('next')}
                  </button>
                </div>
                <Spacing lg="40" md="40" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
