export default function checkpassword(password) {
  var strength = 0;
  if (password.match(/[a-z]+/)) {
    strength += 1;
  }
  if (password.match(/[A-Z]+/) && password.length >= 6) {
    strength += 1;
  }
  if (password.match(/[0-9]+/) && password.length >= 6) {
    strength += 1;
  }
  if (password.match(/[$@#&!]+/) && password.length >= 6) {
    strength += 1;
  }

  switch (strength) {
    case 0:
      return <span style={{ color: "red" }}>too week</span>;

    case 1:
      return <span style={{ color: "red" }}>too week</span>;

    case 2:
      return <span style={{ color: "orange" }}>medium</span>;

    case 3:
      return <span style={{ color: "green" }}>strong</span>;

    case 4:
      return <span style={{ color: "green" }}>very strong</span>;
  }
}
