import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  deleteNewsletter,
  getNewsletters,
  updateNewsletterPinPost,
} from '../../../../api/services/newsletter';
import LoadingSpinner from '../../../../components/Common/LoadingSpinner';
import DotOption from '../../../../components/DotOption';
import Spacing from '../../../../components/Spacing';
import UserContext from '../../../../context/user/user-context';
import { removeHtml } from '../../../../utils/removeHtml';
import { tagStringToArray } from '../../../../utils/tagStringToArray';
import { textLimit } from '../../../../utils/TextLimit';
import { v4 as uuidv4 } from 'uuid';
import PaginateButton from '../../../../components/Common/PaginateButton';
import { defaultHasMoreCount } from '../../../../config/const';
import Swal from 'sweetalert2';
import EmptyData from '../../../../components/Common/EmptyData';
import { useTranslation } from 'react-i18next';

export default function PublishedNewsletter({ refreshPin }) {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [count, setCount] = useState(0);
  const { user } = useContext(UserContext);
  const selectedTag = searchParams.get('tag') ?? '';
  const searchQuery = searchParams.get('search') ?? '';

  // get newsletter
  const getNewsletterList = async () => {
    setLoading(true);
    const res = await getNewsletters(1, 5, searchQuery, 0, '', '', selectedTag);
    setLoading(false);
    setNewsletters(tagStringToArray(res.data.data));
    setCount(res.data.count);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getNewsletters(
      page,
      5,
      searchQuery,
      0,
      '',
      '',
      selectedTag,
    );
    setNewsletters([...newsletters, ...tagStringToArray(res.data.data)]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // handle delete
  const handleDelete = async post_id => {
    Swal.fire({
      title: t('are_you_sure'),
      text: t('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_delete_it'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await deleteNewsletter(post_id);
          if (res.data.success === 1) {
            Swal.fire(t('deleted'), t('your_post_has_been_deleted'), 'success');
            getNewsletterList();
            setPage(2);
          }
          //   await deletePhysician(id);
          //   refresh();
        } catch (e) {}
      }
    });
  };

  // handle update pin
  const updatePinPost = async (post_id, is_pinned) => {
    const pinType = is_pinned === 1 ? 0 : 1;
    const data = {
      post_id,
      is_pinned: pinType,
    };
    await updateNewsletterPinPost(data);
    getNewsletterList();
    setPage(2);
    refreshPin();
  };

  useEffect(() => {
    getNewsletterList();
  }, [searchParams]);
  return (
    <>
      <div className={`cs-card_8_wrap ${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {newsletters.map(item => (
          <div className="cs-card cs-style4" key={uuidv4()}>
            <div className="cs-card_meta">
              <img src={user.image} alt="User" />
              <div style={{ color: '#353535' }}>
                <p
                  className="cs-m0"
                  style={{
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em',
                  }}
                >
                  {user.name_show}
                </p>
                <p className="cs-m0">
                  {moment(item.created_at).format('LT')} ;{' '}
                  {moment(item.created_at).format('DD MMM Y')}
                </p>
              </div>
              <DotOption
                isShare={true}
                url={`/dashboard/${item.userId}/newsletter/${item.id}`}
              >
                <Link to={`/newsletter/${item.id}/edit`}>
                  <Icon icon="material-symbols:edit" /> {t('edit')}
                </Link>
                <button onClick={() => handleDelete(item.id)}>
                  <Icon icon="material-symbols:delete-rounded" /> {t('delete')}
                </button>
              </DotOption>
              <button
                className={`cs-icon_btn cs-pit_btn ${
                  item.is_pinned === 1 ? 'active' : ''
                }`}
                onClick={() => updatePinPost(item.id, item.is_pinned)}
              >
                <svg
                  width={9}
                  height={13}
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
            <div className="cs-card_info">
              {item.image && (
                <div className="cs-card_thumb">
                  <img src={item.image} alt="" />
                </div>
              )}
              <h3>{item.title}</h3>
              <p className="cs-break-all">
                {textLimit(removeHtml(item.detail), 435)}
              </p>
              <Link
                to={`/newsletter/${item.id}/view`}
                className="cs-coninue_reading"
              >
                {t('continue_reading')}
              </Link>
              <div className="cs-tags">
                {item.tag.map(tag => (
                  <Link
                    to={`/newsletter?tag=${tag}`}
                    className={`cs-tag ${selectedTag === tag ? 'active' : ''}`}
                    key={uuidv4()}
                  >
                    {tag}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        ))}
        {!newsletters.length && !loading ? <EmptyData /> : null}
      </div>
      <Spacing lg="20" md="20" />
      {count > defaultHasMoreCount && (
        <PaginateButton
          loading={paginateLoading}
          hasMore={hasMore}
          onClick={paginate}
        />
      )}
    </>
  );
}
