import React from "react";
import { useContext } from "react";
import Header from "../../components/Header";
import Spacing from "../../components/Spacing";
import UserContext from "../../context/user/user-context";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyUserEmail } from "../../api/services/auth/signup";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function EmailVerification() {
  const { t } = useTranslation();
  const { user, getUserInformation } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const formSchema = Yup.object().shape({
    code: Yup.string().required("The otp field is required"),
  });
  const navigate = useNavigate();
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(formOptions);

  // handle verify
  const handleVerify = async (data) => {
    setIsLoading(true);
    const res = await verifyUserEmail(data);
    if (res.data.success === 0) {
      setError("code", { type: "custom", message: res.data.message });
    } else {
      getUserInformation().then(() => {
        navigate("/onboarding/personal-info");
      });
    }
    setIsLoading(false);
  };
  return (
    <>
      <Header starter />
      <Spacing lg="90" md="50" />
      <div className="cs-display_center">
        <div className="cs-container_md">
          <div className="cs-text_center">
            <img src="/images/icon/envlop.png" alt="Icon" />
            <Spacing lg="30" md="30" />
            <h2 className="cs-m0" style={{ fontSize: "25px" }}>
              {t("email_verification_title")}
            </h2>
          </div>
          <Spacing lg="15" md="15" />
          <div
            className="cs-bold"
            style={{ fontSize: "18px", lineHeight: "1.6em", color: "#808080" }}
          >
            {t("email_verification_text_1")}
          </div>
          <Spacing lg="15" md="15" />
          <div
            style={{ fontSize: "18px", lineHeight: "1.6em", color: "#535353" }}
          >
            {t("email_verification_text_2")}{" "}
            <span className="cs-accent_color cs-bold">{user.email}</span>
          </div>
          <Spacing lg="40" md="10" />
          <p className="cs-m0">{t("email_verification_text_3")}</p>
          <Spacing lg="5" md="5" />
          <form
            onSubmit={handleSubmit(handleVerify)}
            className="cs-verification_box"
          >
            <input
              type="text"
              {...register("code")}
              className={`${errors.code ? "cs-invalid-input" : ""}`}
            />
            <button
              className={`cs-logout_btn ${isLoading ? "cs-loading_wrap" : ""}`}
              disabled={isLoading}
            >
              {isLoading && (
                <span className="cs-btn_loading">
                  <Icon icon="la:spinner" />
                  {t("loading")}
                </span>
              )}
              {t("continue")}
            </button>
          </form>
          <p className="invalid-feedback">{errors.code?.message}</p>
          <Spacing lg="100" md="50" />
        </div>
      </div>
    </>
  );
}
