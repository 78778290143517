import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateGorudoPlan } from '../../api/services/onboarding/gorudo-plans';
import { getCurrentPlan } from '../../api/services/settings';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import PricingPlan from '../../components/PricingPlan';
import Spacing from '../../components/Spacing';
import PlanContext from '../../context/plan/plan-context';

export default function ChangePlan() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { getPlan: refreshPlan } = useContext(PlanContext);

  const updatePlan = async plan => {
    setIsLoading(true);
    await updateGorudoPlan(plan);
    setIsLoading(false);
    getPlan();
  };

  // get current plan
  const getPlan = async () => {
    setLoading(true);
    const res = await getCurrentPlan();
    setCurrentPlan(res.data.data);
    refreshPlan();
    setLoading(false);
  };
  useEffect(() => {
    getPlan();
  }, []);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-section_heading_3">
        <div className="cs-section_heading_left">
          <h2 className="cs-section_title">{t('change_gorudo_plan')}</h2>
        </div>
      </div>
      <Spacing lg="20" md="20" />
      <div className={`cs-card cs-style10 ${loading ? 'cs-loading_wrap' : ''}`}>
        {loading && <LoadingSpinner />}
        <div className="cs-card_left">
          <h2>
            {currentPlan.plan_name}
            <span className="cs-card_label">Active</span>
          </h2>
          <p>{currentPlan.commission}% commission</p>
        </div>
        <div className="cs-card_right">
          <ul className="pricing_feature">
            {currentPlan.features_name?.map((item, index) => (
              <li key={index}>
                <svg
                  width={14}
                  height={14}
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.12494 9.33356L5.79955 9.71319L6.1893 10.0473L6.513 9.64886L6.12494 9.33356ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5ZM3.75788 7.96319L5.79955 9.71319L6.45034 8.95393L4.40867 7.20393L3.75788 7.96319ZM6.513 9.64886L10.3047 4.98219L9.52855 4.3516L5.73689 9.01826L6.513 9.64886Z"
                    fill="currentColor"
                  />
                </svg>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Spacing lg="40" md="40" />
      <h3 className="cs-medium" style={{ fontSize: '16px' }}>
        {t('change_to_other_premium_plan')}
      </h3>
      <Spacing lg="20" md="20" />
      <div className={`${isLoading ? 'cs-loading_wrap' : ''}`}>
        {isLoading && <LoadingSpinner />}
        <PricingPlan setPlan={updatePlan} />
      </div>
      <Spacing lg="60" md="60" />
    </div>
  );
}
