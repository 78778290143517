import {
  changePasswordUrl,
  connectAccountRejectUrl,
  connectAccountRetriveUrl,
  connectAcountLoginUrl,
  getCurrentPlanUrl,
  getEmailSettingsUrl,
  getPushSettingsUrl,
  sendTestEmailUrl,
  sendTestNotificationUrl,
  updateEmailSettingsUrl,
  updatePushSettingsUrl,
} from "../../const";
import axios from "../../lib/axios";

// get currenct plan
export const getCurrentPlan = async () => {
  return await axios.get(getCurrentPlanUrl);
};

// change password
export const changePassword = async (data) => {
  return await axios.put(changePasswordUrl, data);
};

// get push setting
export const getPushSettings = async () => {
  return await axios.get(getPushSettingsUrl);
};

// get email setting
export const getEmailSettings = async () => {
  return await axios.get(getEmailSettingsUrl);
};

// updateEmailNotificationSettings
export const updateEmailNotificationSettings = async (data) => {
  return await axios.post(updateEmailSettingsUrl, data);
};

// updatePushNotificationSettings
export const updatePushNotificationSettings = async (data) => {
  return await axios.post(updatePushSettingsUrl, data);
};

// send test email
export const sendTestEmailNotification = async (email) => {
  return await axios.get(sendTestEmailUrl, { params: { email } });
};

// send test notification
export const sendTestNotification = async (title, body, email) => {
  return await axios.get(sendTestNotificationUrl, {
    params: { title, body, email },
  });
};

// connect account retrive
export const conncetAccountRetrive = async () => {
  return await axios.post(connectAccountRetriveUrl);
};

// connect account login
export const connectAccountLogin = async (data) => {
  return await axios.post(connectAcountLoginUrl, data);
};

// connect account restricted
export const connectAccountRejected = async () => {
  return await axios.post(connectAccountRejectUrl);
};
