import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TermsOfUse() {
  const { t } = useTranslation();
  return (
    <>
      <h2 style={{ fontSize: '34px' }}>{t('disclaimer')}</h2>
      <div className="cs-in_terms">
        <p>{t('disclaimer_content')}</p>
      </div>
    </>
  );
}
