import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import Spacing from '../../components/Spacing';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { pageTitle } from '../../helper';
import { checkUserName, signUp } from '../../api/services/auth/signup';
import TermsOfUse from '../../components/TermsOfUse';
import checkpassword from '../../utils/PasswordStrengthCheck';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import LangauageDropdown from '../../components/Header/LangauageDropdown';

export default function Register() {
  const { t } = useTranslation();
  pageTitle('Register');
  const auth = useAuth();
  const [passwordStrength, setPasswordStrength] = useState('');
  const [modal, setModal] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, SetErrorMessage] = useState('');
  const [languageToggle, setLanguageToggle] = useState(false);
  const handelLanguageToggle = () => {
    setLanguageToggle(!languageToggle);
  };

  const formSchema = Yup.object().shape({
    type: Yup.number(),
    email: Yup.string().required('The email field is required'),
    name_show: Yup.string().required('The display name field is required'),
    password: Yup.string().required('The password field is required'),
    confirmPwd: Yup.string()
      .required('The confirm password field is required')
      .oneOf([Yup.ref('password')], 'Password does not match'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Please agree to all the terms and Conditions before creating account',
    ),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  // handle signup
  const handleSignUp = async data => {
    setIsLoading(true);
    const res = await signUp(data);
    if (res.data.success === 0) {
      SetErrorMessage(res.data.message);
    } else {
      window.location = '/auth/email-verification';
    }
    setIsLoading(false);
  };

  // check username
  const checkDisplayName = async username => {
    const res = await checkUserName(username);
    if (res.data.success === 0) {
      setError('name_show', {
        type: 'custom',
        message: res.data.message,
      });
    } else if (res.data.success === 1) {
      clearErrors('name_show');
    }
  };

  useEffect(() => {
    setValue('type', 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return !auth ? (
    <>
      <div className="cs-header_btns">
        <Link to="/" className="cs-header_btn">
          {t('home')}
        </Link>
        <Link to="/auth/login" className="cs-header_btn cs-color1">
          {t('login')}
        </Link>
        <LangauageDropdown
          handelLanguageToggle={handelLanguageToggle}
          languageToggle={languageToggle}
          close={setLanguageToggle}
        />
      </div>
      <div className="cs-login">
        <div
          className="cs-login_left"
          style={{
            backgroundImage: 'url(/images/register.jpg)',
            backgroundColor: '#dbf1f1',
          }}
        >
          <img src="/images/logo_white.svg" alt="Logo" />
        </div>
        <div className="cs-login_right">
          <div className="cs-login_info cs-primary_font">
            <div>
              <div className="cs-text_center">
                <Link to="/">
                  <img src="/images/icon/icon4.svg" alt="Icon" />
                </Link>
              </div>
              <Spacing lg="25" md="20" />
              <h3>{t('register_title')}</h3>
              <h2>{t('register_subtitle')}</h2>
              <p className="cs-danger">{errorMessage}</p>
            </div>
            <Spacing lg="20" md="20" />
            <form onSubmit={handleSubmit(handleSignUp)}>
              <label>{t('register_input_label_1')}</label>
              <input
                type="text"
                placeholder={t('register_input_placeholder_1')}
                className={`cs-form_field_1 ${
                  errors.email ? 'cs-invalid-input' : ''
                }`}
                {...register('email')}
              />
              <p className="invalid-feedback">{errors.email?.message}</p>
              <Spacing lg="10" md="10" />
              <label>{t('register_input_label_2')}</label>
              <input
                type="text"
                placeholder={t('register_input_placeholder_2')}
                className={`cs-form_field_1 ${
                  errors.name_show ? 'cs-invalid-input' : ''
                }`}
                {...register('name_show')}
                onChange={e => checkDisplayName(e.target.value)}
              />
              <p className="invalid-feedback">{errors.name_show?.message}</p>
              <Spacing lg="10" md="10" />
              <label>{t('register_input_label_3')}</label>
              <div
                className="cs-field_with_icon cs-type1"
                onChange={e => {
                  setPasswordStrength(checkpassword(e.target.value));
                }}
              >
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  placeholder={t('register_input_placeholder_3')}
                  className={`cs-form_field_1 ${
                    errors.password ? 'cs-invalid-input' : ''
                  }`}
                  {...register('password')}
                />
                <span
                  className="cs-field_icon_btn"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <Icon icon="mdi:eye-off" />
                  ) : (
                    <Icon icon="ic:baseline-remove-red-eye" />
                  )}
                </span>
              </div>
              {passwordStrength && <p>Password is {passwordStrength}</p>}
              <p className="invalid-feedback">{errors.password?.message}</p>
              <Spacing lg="10" md="10" />
              <label>{t('register_input_label_4')}</label>
              <div className="cs-field_with_icon cs-type1">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder={t('register_input_placeholder_4')}
                  className={`cs-form_field_1 ${
                    errors.password ? 'cs-invalid-input' : ''
                  }`}
                  {...register('confirmPwd')}
                />
                <span
                  className="cs-field_icon_btn"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                ></span>
                <span
                  className="cs-field_icon_btn"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <Icon icon="mdi:eye-off" />
                  ) : (
                    <Icon icon="ic:baseline-remove-red-eye" />
                  )}
                </span>
              </div>
              <p className="invalid-feedback">{errors.confirmPwd?.message}</p>
              <Spacing lg="25" md="25" />
              <div className="cs-check">
                <input type="checkbox" {...register('acceptTerms')} />
                <label>
                  {t('i_agree_with_the')}{' '}
                  <a href="https://gorudo.io/terms-of-use" target="_blank">
                    {t('terms_of_use')}
                  </a>
                </label>
              </div>
              <p className="invalid-feedback">{errors.acceptTerms?.message}</p>
              <Spacing lg="20" md="20" />
              <button
                className={`cs-general_btn cs-primary_font ${
                  isLoading ? 'cs-loading_wrap' : ''
                }`}
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="cs-btn_loading">
                    <Icon icon="la:spinner" />
                    {t('loading')}
                  </span>
                )}
                {t('sign_up')}
              </button>
            </form>
          </div>
        </div>
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style2"
                style={{ maxWidth: '750px' }}
              >
                <div
                  className="cs-modal_box_in"
                  style={{ textAlign: 'center' }}
                >
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <TermsOfUse />
                  <Spacing lg="20" md="20" />
                  <button
                    className="cs-btn"
                    onClick={() => setModal(false)}
                    style={{ maxWidth: '300px', width: '100%' }}
                  >
                    {t('i_agree')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  ) : (
    <Navigate to="/" />
  );
}
