import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getTradeDiaryList } from "../../../api/services/tradeDiary";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import Spacing from "../../../components/Spacing";

export default function TradeDiaryDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const [tradeDiary, setTradeDiary] = useState([]);
  const [tradeDiaryLoading, setTradeDiaryLoading] = useState(false);

  // get all trade diary
  const getTradeDiary = async () => {
    setTradeDiaryLoading(true);
    const res = await getTradeDiaryList(1, 5, "", "", params.id);
    setTradeDiary(res.data.data);
    setTradeDiaryLoading(false);
  };

  useEffect(() => {
    getTradeDiary();
  }, [params]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
            {t("trade_diary")}
          </h2>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_9_wrap ${
              tradeDiaryLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {tradeDiaryLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            <>
              {tradeDiary.map((item) => (
                <div className="cs-card cs-style9">
                  <div
                    className="cs-card_bar"
                    style={{
                      backgroundColor: item.trade_dairy_color_background,
                    }}
                  ></div>
                  <div className="cs-card_in">
                    <div className="cs-left">
                      <h2 className="ca-card_title">{item.stock_name}</h2>
                      <div className="cs-card_meta">
                        <div>
                          <div className="cs-card_meta_title">{t("price")}</div>
                          <div className="cs-card_meta_price">
                            {item.stock_price !== "Now" && "$"}
                            {item.stock_price}
                          </div>
                        </div>
                        <div>
                          <div className="cs-card_meta_title">
                            {t("stop_loss")}
                          </div>
                          <div className="cs-card_meta_price">
                            ${item.stop_loss}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="cs-right">
                      <div className="cs-card_label">
                        <span
                          style={{
                            backgroundColor: item.trade_dairy_color_background,
                          }}
                        ></span>
                        {item.trade_dairy_name}
                      </div>
                      <div className="cs-card_date">
                        {moment(item.created_at).format("DD-MMM-Y")}{" "}
                        {moment(item.created_at).format("LT")}
                      </div>
                    </div>
                  </div>
                  {item.note && (
                    <>
                      <div
                        className="cs-card_note_title"
                        style={{ textTransform: "uppercase" }}
                      >
                        <Icon icon="mdi:tick-circle-outline" />
                        {t("notes")}:
                      </div>
                      <div className="cs-card_text cs-break_all">
                        {item.note}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
