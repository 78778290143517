import { cancelMemberSubscribtionUrl, getMemberUrl } from "../../const";
import axios from "../../lib/axios";

// get member
export const getAllMember = async (page = 1, limit = 5, search = "") => {
  return await axios.get(getMemberUrl, { params: { page, limit, search } });
};

// cancel subscribtion
export const cancelMemberSubscribtion = async (data) => {
  return await axios.post(cancelMemberSubscribtionUrl, data);
};
