import {
  allNotificationUrl,
  notificationDeleteUrl,
  notificationMakeSeenUrl,
  unreadNotificationUrl,
} from "../../const";
import axios from "../../lib/axios";

// get all notification
export const allNotification = async () => {
  return await axios.get(allNotificationUrl);
};

// get unread notification
export const unreadNotification = async () => {
  return await axios.get(unreadNotificationUrl);
};

// notification seen update
export const notificationSeenUpdate = async (post_id) => {
  return await axios.put(notificationMakeSeenUrl + `?post_id=${post_id}`);
};

// notification delete
export const deleteNotification = async (post_id) => {
  return await axios.get(notificationDeleteUrl, {
    params: { post_id },
  });
};
