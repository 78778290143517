import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createNewsletter } from "../../../api/services/newsletter";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import Spacing from "../../../components/Spacing";

export default function NewsletterCreate() {
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const templeteType = searchParams.get("type") ?? "";

  // handle create newsletter
  const handleCreateNewsletter = async () => {
    const data = {
      title: "",
      sub_title: "",
      short_desc: "",
      detail: "",
      image: "",
      files: "",
      tag: "",
      visibility: "1",
      tiers: "",
      teaser: "",
      schedule_date: "",
      schedule_time: "",
      schedule_datetime: "",
      is_schedule: "0",
      is_draft: "1",
      template_type: templeteType,
    };
    localStorage.setItem("templateType", templeteType);
    setLoading(true);
    const res = await createNewsletter(data);
    navigate(`/newsletter/${res.data.data.id}/edit`);
    setLoading(false);
  };

  useEffect(() => {
    handleCreateNewsletter();
  }, []);

  return (
    <div className={`cs-container_md ${loading ? "cs-loading_wrap" : ""}`}>
      {loading && (
        <>
          <Spacing lg="40" md="40" />
          <LoadingSpinner />
        </>
      )}
    </div>
  );
}
