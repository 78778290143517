import {
  createWebinarUrl,
  deleteWebinarUrl,
  getSingleWebinarUrl,
  updateWebinarUrl,
  uploadWebinarImageUrl,
  webinarPinUpdateUrl,
} from "../../const";
import axios from "../../lib/axios";

// create webinar
export const createWebinarVideo = async (data) => {
  return await axios.post(createWebinarUrl, data);
};

// upload webinar image
export const uploadWebinarImage = async (data) => {
  return axios.post(uploadWebinarImageUrl, data);
};

// get single webinar
export const getSingleWebinar = async (post_id) => {
  return await axios.get(getSingleWebinarUrl, { params: { post_id } });
};

// update webinar
export const updateWebinar = async (data) => {
  return await axios.put(updateWebinarUrl, data);
};

// get webinar
export const getWebinarList = async (
  page = 1,
  limit = 5,
  search = "",
  is_draft = 0,
  pin = "",
  tag = "",
  post_id = ""
) => {
  return await axios.get(createWebinarUrl, {
    params: { page, limit, is_draft, search, tag, pin, post_id },
  });
};

// delete webinar
export const deleteWebinar = async (post_id) => {
  return await axios.delete(deleteWebinarUrl, { params: { post_id } });
};

// update webinar pin post
export const updateWebinarPinPost = async (data) => {
  return await axios.post(webinarPinUpdateUrl, data);
};
