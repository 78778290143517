import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getUserPlan } from "../../../api/services/auth/user";
import {
  deleteMyTier,
  getMembershipTiers,
  myTierUpdate,
} from "../../../api/services/onboarding/membership-tiers";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import Spacing from "../../../components/Spacing";
import CreateFreeMembershipTier from "./components/CreateFreeMembershipTier";
import CreatePaidMembershipTier from "./components/CreatePaidMembershipTier";
import { useTranslation } from "react-i18next";

export default function MembershipTiers() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tiers, setTiers] = useState([]);

  // craetor tier
  const getCreatorTiers = async () => {
    setIsLoading(true);
    const response = await getMembershipTiers();
    setTiers(response.data.data);
    setIsLoading(false);
  };

  // update pin
  const updateTierPin = async (tier_id, is_popular) => {
    const data = {
      post_id: tier_id,
      is_popular: is_popular === "1" ? 0 : 1,
      status: 1,
    };
    setIsLoading(true);
    await myTierUpdate(data);
    setIsLoading(false);
    getCreatorTiers();
  };

  // delete tier
  const deleteTier = async (tierId) => {
    Swal.fire({
      title: t("are_you_sure"),
      text: t("you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes_delete_it"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        await deleteMyTier(tierId);
        setIsLoading(false);
        getCreatorTiers();
      }
    });
  };

  useEffect(() => {
    //user plan
    const userTierAndPlan = async () => {
      const res = await getUserPlan();
      setFeatures(res.data.data.features);
    };
    userTierAndPlan();
    getCreatorTiers();
  }, []);
  return (
    <>
      {tiers.length && !isLoading ? (
        <>
          <div className="cs-section_heading_3">
            <div className="cs-section_heading_left">
              <h2 className="cs-section_title">{t("membership_tiers")}</h2>
            </div>
            <div className="cs-section_heading_right">
              <button
                className="cs-section_btn cs-color1"
                onClick={() => setModal(true)}
              >
                {t("create_free_tier")}
              </button>
              <button
                className="cs-section_btn cs-filed"
                onClick={() => setModal2(true)}
              >
                {t("add_more_paid_tiers")}
              </button>
            </div>
          </div>
          <Spacing lg="60" md="40" />
          <div
            className={`cs-tires cs-style1 ${
              isLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {isLoading && <LoadingSpinner />}

            {tiers.map((item) => (
              <div key={item.id}>
                <div
                  className={`cs-tire ${
                    item.is_popular === "1" ? "active" : ""
                  }`}
                >
                  <div className="cs-tire_controll">
                    <div className="cs-tire_controll_titel"></div>
                    <div className="cs-tire_controll_btns">
                      <button
                        className="cs-edit_tag"
                        onClick={() => {
                          updateTierPin(item.id, item.is_popular);
                        }}
                      >
                        <svg
                          width={10}
                          height={13}
                          viewBox="0 0 10 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.294922 13L4.79492 9.20833L9.29492 13V1.08333C9.29492 0.484792 8.79148 0 8.16992 0H1.41992C0.798359 0 0.294922 0.484792 0.294922 1.08333V13Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      <button onClick={() => deleteTier(item.id)}>
                        <svg
                          width={18}
                          height={17}
                          viewBox="0 0 18 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.12084 0.25C9.85424 0.249903 10.5612 0.506305 11.1029 0.96886C11.6446 1.43142 11.9819 2.06671 12.0485 2.75H16.6031C16.8062 2.75006 17.0017 2.82224 17.1501 2.95197C17.2986 3.0817 17.3888 3.2593 17.4027 3.44888C17.4165 3.63846 17.3529 3.82589 17.2247 3.9733C17.0965 4.12071 16.9133 4.2171 16.7121 4.243L16.6031 4.25H15.9799L14.6256 15.23C14.5653 15.7174 14.3156 16.167 13.924 16.4934C13.5324 16.8198 13.0262 17.0001 12.5017 17H5.73993C5.21547 17.0001 4.70929 16.8198 4.31768 16.4934C3.92607 16.167 3.67635 15.7174 3.61604 15.23L2.26068 4.25H1.63858C1.44486 4.24999 1.25769 4.18436 1.11169 4.06523C0.965695 3.94611 0.870743 3.78155 0.844396 3.602L0.836914 3.5C0.836922 3.31876 0.907079 3.14366 1.03441 3.00707C1.16174 2.87048 1.33763 2.78165 1.52956 2.757L1.63858 2.75H6.19314C6.25981 2.06671 6.59711 1.43142 7.1388 0.96886C7.68049 0.506305 8.38744 0.249903 9.12084 0.25V0.25ZM7.5175 6.5C7.25562 6.5 7.0365 6.655 6.9916 6.859L6.98305 6.938V13.063L6.9916 13.141C7.0365 13.345 7.25562 13.5 7.5175 13.5C7.77938 13.5 7.9985 13.345 8.04339 13.141L8.05195 13.062V6.939L8.04339 6.859C7.9985 6.656 7.77938 6.5 7.5175 6.5ZM10.7242 6.5C10.4623 6.5 10.2432 6.655 10.1983 6.859L10.1897 6.938V13.063L10.1983 13.141C10.2432 13.345 10.4623 13.5 10.7242 13.5C10.9861 13.5 11.2052 13.345 11.2501 13.141L11.2586 13.062V6.939L11.2501 6.859C11.2052 6.656 10.9861 6.501 10.7242 6.501V6.5ZM9.12084 1.75C8.47416 1.75 7.93437 2.18 7.81144 2.75H10.4302C10.3062 2.18 9.76752 1.75 9.12084 1.75V1.75Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <h3 className="cs-tire_title">{item.title}</h3>
                  <ul className="cs-tire_list">
                    {item.features.map((feature) => (
                      <li key={feature.features_name}>
                        <Icon icon="charm:tick" />
                        {feature.features_name}
                      </li>
                    ))}
                  </ul>
                  <Spacing lg="15" md="15" />
                  <div className="cs-tire_price">
                    <span style={{ fontWeight: "400", fontSize: "17px" }}>
                      $
                    </span>
                    <span style={{ fontWeight: "700", fontSize: "25px" }}>
                      {item.price}
                    </span>
                    <span style={{ opacity: "0.68" }}>/month*</span>
                    {item.invitation_code && (
                      <>
                        <br />
                        <span>Invitation code: {item.invitation_code}</span>
                      </>
                    )}
                  </div>
                  <Spacing lg="15" md="15" />
                  {item.price !== "0" ? (
                    <div className="cs-tire_info">
                      {item.member_qouta} {t("seat_left")}
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
          <Spacing lg="30" md="30" />
          <div className="cs-row">
            <div className="cs-col_6"></div>
            <div className="cs-col_6">
              <Link
                to="/onboarding/withdrawal-method"
                className={`cs-logout_btn cs-type1`}
              >
                {t("next")}
              </Link>
            </div>
          </div>
          <Spacing lg="60" md="30" />
        </>
      ) : (
        <>
          <div className={`${isLoading ? "cs-loading_wrap" : ""}`}>
            {isLoading && <LoadingSpinner />}
            <div className="cs-text_center">
              <img src="/images/empty_tires.svg" alt="" />
              <div
                style={{
                  fontSize: "18px",
                  color: "#2C2C2C",
                  lineHeight: "1.6em",
                }}
              >
                {t("there_is_no_membership_tiers")}
              </div>
            </div>
            <Spacing lg="30" md="20" />
            <div className="cs-row">
              <div className="cs-col_6">
                <button
                  className="cs-section_btn cs-color1"
                  onClick={() => setModal(true)}
                  style={{ width: "100%" }}
                >
                  {t("create_free_tier")}
                </button>
              </div>
              <div className="cs-col_6">
                <button
                  className="cs-section_btn cs-filed"
                  onClick={() => setModal2(true)}
                  style={{ width: "100%" }}
                >
                  {t("add_more_paid_tiers")}
                </button>
              </div>
            </div>
            <Spacing lg="30" md="30" />
            <div className="cs-text_center">
              <Link to="/onboarding/withdrawal-method" className="cs-text_btn">
                {t("skip_for_now")}
              </Link>
            </div>
            <Spacing lg="60" md="60" />
          </div>
        </>
      )}

      {modal && (
        <CreateFreeMembershipTier
          setModal={setModal}
          refresh={getCreatorTiers}
        />
      )}
      {modal2 && (
        <CreatePaidMembershipTier
          setModal2={setModal2}
          features={features}
          refresh={getCreatorTiers}
        />
      )}
    </>
  );
}
