import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Spacing from "../../components/Spacing";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../api/services/auth/forgotPassword";
import checkpassword from "../../utils/PasswordStrengthCheck";

export default function UpdatePassword() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const formSchema = Yup.object().shape({
    code: Yup.string(),
    password: Yup.string().required("The password field is required"),
    password_c: Yup.string()
      .required("The confirm password field is required")
      .oneOf([Yup.ref("password")], "Password does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  // handle password update
  const handlePasswordUpdate = async (data) => {
    setLoading(true);
    const res = await resetPassword(data);
    if (res.data.success === 1) {
      setSuccessMessage(res.data.message);
      setTimeout(() => {
        navigate("/auth/login");
      }, [2000]);
    } else {
      setErrorMessage(res.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValue("code", searchParams.get("code"));
  }, [searchParams]);
  return (
    <>
      <Header starter />
      <form
        onSubmit={handleSubmit(handlePasswordUpdate)}
        className="cs-contaienr_sm"
      >
        <Spacing lg="90" md="90" />
        <Spacing lg="60" md="60" />
        <div className="cs-reset_password_box">
          <h2>{t("update_password_title")}</h2>
          <Spacing lg="30" md="30" />
          {errorMessage && <p className="cs-danger">{errorMessage}</p>}
          {successMessage && <p className="cs-success">{successMessage}</p>}
          <label>{t("update_password_input_1")}</label>
          <div
            className="cs-field_with_icon"
            onChange={(e) => {
              setPasswordStrength(checkpassword(e.target.value));
            }}
          >
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder={t("update_password_placeholder_1")}
              className="cs-form_field"
              {...register("password")}
            />
            <span
              className="cs-field_icon_btn"
              onClick={() => setShowNewPassword(!showNewPassword)}
            >
              {showNewPassword ? (
                <Icon icon="mdi:eye-off" />
              ) : (
                <Icon icon="ic:baseline-remove-red-eye" />
              )}
            </span>
          </div>
          {passwordStrength && <p>Password is {passwordStrength}</p>}
          <p className="invalid-feedback">{errors.password?.message}</p>
          <Spacing lg="25" md="25" />
          <label>{t("update_password_input_2")}</label>
          <div className="cs-field_with_icon">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder={t("update_password_placeholder_2")}
              className="cs-form_field"
              {...register("password_c")}
            />
            <span
              className="cs-field_icon_btn"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            ></span>
            <span
              className="cs-field_icon_btn"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? (
                <Icon icon="mdi:eye-off" />
              ) : (
                <Icon icon="ic:baseline-remove-red-eye" />
              )}
            </span>
          </div>
          <p className="invalid-feedback">{errors.password_c?.message}</p>
          <Spacing lg="25" md="25" />
          <button
            className={`cs-logout_btn cs-type1 ${loading && "cs-loading_wrap"}`}
            disabled={loading}
          >
            {loading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t("loading")}
              </span>
            )}
            {t("reset_now")}
          </button>
        </div>
        <Spacing lg="60" md="60" />
      </form>
    </>
  );
}
