import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PlanContext from '../../context/plan/plan-context';
import UserContext from '../../context/user/user-context';
import useAuth from '../../hooks/useAuth';
import LoadingSpinner from '../Common/LoadingSpinner';
import Header from '../Header';
import SettingsSideNav from '../MainSideNav/SettingsSideNav';

export default function SettingsLayout({ showSearch }) {
  const { user, isLoading } = useContext(UserContext);
  const { features, isLoading: planLoading } = useContext(PlanContext);
  // deside what to render
  let content = '';
  if (isLoading)
    content = (
      <div
        className="cs-loading_wrap"
        style={{ width: '100%', height: '100vh' }}
      >
        <LoadingSpinner />
      </div>
    );
  if (!isLoading && user.varified === 0) {
    content = <Navigate to="/auth/email-verification" />;
  }
  if (!isLoading && user.varified && user.dob === null) {
    content = <Navigate to="/onboarding/personal-info" />;
  }
  if (
    !isLoading &&
    user.varified &&
    user.dob &&
    !planLoading &&
    !features.length
  ) {
    content = <Navigate to="/onboarding/choose-plan" />;
  }
  if (
    !isLoading &&
    user.varified &&
    user.dob &&
    features.length &&
    user.stripe_acc === ''
  ) {
    content = <Navigate to="/onboarding/withdrawal-method" />;
  }
  if (
    !isLoading &&
    user.varified &&
    user.dob &&
    features.length &&
    user.stripe_acc !== ''
  ) {
    content = (
      <>
        <Header showSearch={showSearch} />
        <SettingsSideNav />
        <div className="cs-with_sidebar_main">
          <Outlet />
        </div>
      </>
    );
  }

  const auth = useAuth();
  return auth ? <>{content}</> : <Navigate to="/auth/login" />;
}
