import { Icon } from '@iconify/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Spacing from '../Spacing';

export default function MembershipTire({ title }) {
  const { t } = useTranslation();
  return (
    <>
      <h2 style={{ fontSize: '30px', textAlign: 'center', marginBottom: '0' }}>
        {title}
      </h2>
      <Spacing lg="40" md="40" />
      <div className="cs-tires">
        <div>
          <div className="cs-tire">
            <h3 className="cs-tire_title">{t('intro')}</h3>
            <ul className="cs-tire_list">
              <li>
                <Icon icon="charm:tick" />
                {t('broadcast')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('trade_diary')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('open_position')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('resources')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('newsletter')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('watchlist')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('webinar_video')}
              </li>
            </ul>
            <Spacing lg="15" md="15" />
            <div className="cs-promo_code">
              <div>{t('invitation_code')}</div>
              <input type="text" placeholder={t('enter_code')} />
            </div>
            <Spacing lg="20" md="20" />
            <div className="cs-tire_info">2 {t('seat_left')}</div>
            <Spacing lg="10" md="10" />
            <div className="cs-tire_price">
              <span style={{ fontWeight: '400', fontSize: '17px' }}>$</span>
              <span style={{ fontWeight: '700', fontSize: '25px' }}>123</span>
              <span style={{ opacity: '0.68' }}>/month*</span>
            </div>
            <Spacing lg="20" md="20" />
            <button className="cs-tire_btn">{t('choose')}</button>
          </div>
        </div>
        <div>
          <div className="cs-tire">
            <h3 className="cs-tire_title">{t('intro')}</h3>
            <ul className="cs-tire_list">
              <li>
                <Icon icon="charm:tick" />
                {t('broadcast')}
              </li>
              <li>
                <Icon icon="charm:tick" />
                {t('trade_diary')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('open_position')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('resources')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('newsletter')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('watchlist')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('webinar_video')}
              </li>
            </ul>
            <Spacing lg="15" md="15" />
            <div className="cs-promo_code">
              <div>{t('invitation_code')}</div>
              <input type="text" placeholder={t('enter_code')} />
            </div>
            <Spacing lg="20" md="20" />
            <div className="cs-tire_info">2 {t('seat_left')}</div>
            <Spacing lg="10" md="10" />
            <div className="cs-tire_price">
              <span style={{ fontWeight: '400', fontSize: '17px' }}>$</span>
              <span style={{ fontWeight: '700', fontSize: '25px' }}>123</span>
              <span style={{ opacity: '0.68' }}>/month*</span>
            </div>
            <Spacing lg="20" md="20" />
            <button className="cs-tire_btn">{t('choose')}</button>
          </div>
        </div>
        <div>
          <div className="cs-tire active">
            <h3 className="cs-tire_title">{t('intro')}</h3>
            <ul className="cs-tire_list">
              <li>
                <Icon icon="charm:tick" />
                {t('broadcast')}
              </li>
              <li>
                <Icon icon="charm:tick" />
                {t('trade_diary')}
              </li>
              <li>
                <Icon icon="charm:tick" />
                {t('open_position')}
              </li>
              <li>
                <Icon icon="charm:tick" />
                {t('resources')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('newsletter')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('watchlist')}
              </li>
              <li className="cs-disabled">
                <Icon icon="charm:tick" />
                {t('webinar_video')}
              </li>
            </ul>
            <Spacing lg="15" md="15" />
            <div className="cs-promo_code">
              <div>{t('invitation_code')}</div>
              <input type="text" placeholder={t('enter_code')} />
            </div>
            <Spacing lg="20" md="20" />
            <div className="cs-tire_info">2 {t('seat_left')}</div>
            <Spacing lg="10" md="10" />
            <div className="cs-tire_price">
              <span style={{ fontWeight: '400', fontSize: '17px' }}>$</span>
              <span style={{ fontWeight: '700', fontSize: '25px' }}>123</span>
              <span style={{ opacity: '0.68' }}>/month*</span>
            </div>
            <Spacing lg="20" md="20" />
            <button className="cs-tire_btn">{t('choose')}</button>
          </div>
        </div>
      </div>
    </>
  );
}
