import { getStripeCountryListUrl, withdrawalMethodUrl } from "../../const";
import axios from "../../lib/axios";

export const setupStripeConnect = async (data, selectedCountry) => {
  return await axios.post(withdrawalMethodUrl, data, {
    params: {
      country: selectedCountry,
    },
  });
};

export const getStripeCountry = async () => {
  return await axios.get(getStripeCountryListUrl);
};
