import { Icon } from '@iconify/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DotOption from '../../components/DotOption';
import Spacing from '../../components/Spacing';

export default function SearchResourcesFaq() {
  const { t } = useTranslation();
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: '24px', marginBottom: '0px' }}>
            Junaice’s Resources & FAQ
          </h2>
          <Spacing lg="30" md="30" />
          <div className="cs-card_8_wrap">
            <div className="cs-card cs-style8">
              <ul className="cs-card_meta cs-mp0">
                <li>
                  <Icon icon="fa6-solid:flag" />
                  <span>{t('due_to')} 15 July 2021</span>
                </li>
                <li>
                  <Icon icon="fa6-solid:clock" />
                  <span>10:00 AM</span>
                </li>
              </ul>
              <h2 className="cs-card_title">
                A few words about this blog platform, Ghost, and how this site
                was made Ghost, and how this site was made
              </h2>
              <div className="cs-card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu
                velit tempus erat egestas efficitur. In hac habitasse platea
                dictumst. Fusce a nunc eget ligula suscipit finibus. Aenean
                pharetra quis lacus at viverra. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Duis eu velit tempus erat egestas
                efficitur. In hac habitasse platea dictumst. Fusce a nunc eget
                ligula suscipit finibus. Aenean pharetra quis lacus at viverra.
              </div>
              <Link to="/dashboard/resources-faq/id" className="cs-card_btn">
                {t('continue_reading')}
              </Link>
              <Spacing lg="15" md="15" />
              <div className="cs-tags">
                <Link
                  to="/dashboard/resources-faq/hashtag/all"
                  className="cs-tag"
                >
                  {t('all')}
                </Link>
                <Link
                  to="/dashboard/resources-faq/hashtag/photography"
                  className="cs-tag"
                >
                  Photography
                </Link>
                <Link
                  to="/dashboard/resources-faq/hashtag/career"
                  className="cs-tag"
                >
                  Career
                </Link>
                <Link
                  to="/dashboard/resources-faq/hashtag/branding"
                  className="cs-tag"
                >
                  Branding
                </Link>
              </div>
              <DotOption />
            </div>
            <div className="cs-card cs-style8">
              <ul className="cs-card_meta cs-mp0">
                <li>
                  <Icon icon="fa6-solid:flag" />
                  <span>{t('due_to')} 15 July 2021</span>
                </li>
                <li>
                  <Icon icon="fa6-solid:clock" />
                  <span>10:00 AM</span>
                </li>
              </ul>
              <img src="/images/thumb_2.jpeg" alt="Thumb" />
              <h2 className="cs-card_title">
                A few words about this blog platform, Ghost, and how this site
                was made Ghost, and how this site was made
              </h2>
              <div className="cs-card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu
                velit tempus erat egestas efficitur. In hac habitasse platea
                dictumst. Fusce a nunc eget ligula suscipit finibus. Aenean
                pharetra quis lacus at viverra. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Duis eu velit tempus erat egestas
                efficitur. In hac habitasse platea dictumst. Fusce a nunc eget
                ligula suscipit finibus. Aenean pharetra quis lacus at viverra.
              </div>
              <Link to="/dashboard/resources-faq/id" className="cs-card_btn">
                {t('continue_reading')}
              </Link>
              <Spacing lg="15" md="15" />
              <div className="cs-tags">
                <Link
                  to="/dashboard/resources-faq/hashtag/all"
                  className="cs-tag"
                >
                  {t('all')}
                </Link>
                <Link
                  to="/dashboard/resources-faq/hashtag/photography"
                  className="cs-tag"
                >
                  Photography
                </Link>
                <Link
                  to="/dashboard/resources-faq/hashtag/career"
                  className="cs-tag"
                >
                  Career
                </Link>
                <Link
                  to="/dashboard/resources-faq/hashtag/branding"
                  className="cs-tag"
                >
                  Branding
                </Link>
              </div>
              <DotOption />
            </div>
          </div>
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">Pinned By Creator</h2>
            <div className="cs-section_heading_right">
              <button className="cs-icon_btn">
                <svg
                  width={9}
                  height={13}
                  viewBox="0 0 9 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <Spacing lg="30" md="30" />
          <div className="cs-card_8_wrap">
            <div className="cs-card cs-style4 cs-type1">
              <div className="cs-card_thumb">
                <img src="/images/thumb_1.jpeg" alt="" />
              </div>
              <div className="cs-card_info">
                <div className="cs-csra_info">
                  <div className="cs-csra_date">
                    <span>10:32pm</span>
                    <span>31 Jul 2022</span>
                  </div>
                  <DotOption />
                </div>
                <h3>Introduction to Laravel 8.0</h3>
                <p>
                  Laravel is a PHP based web desig framework for building high-
                  synt syntaxes.
                </p>
                <Link
                  to="/dashboard/resources-faq/id"
                  className="cs-coninue_reading"
                >
                  {t('continue_reading')}
                </Link>
                <div className="cs-tags">
                  <Link
                    to="/dashboard/resources-faq/hashtag/all"
                    className="cs-tag"
                  >
                    {t('all')}
                  </Link>
                  <Link
                    to="/dashboard/resources-faq/hashtag/photography"
                    className="cs-tag"
                  >
                    Photography
                  </Link>
                  <Link
                    to="/dashboard/resources-faq/hashtag/career"
                    className="cs-tag"
                  >
                    Career
                  </Link>
                </div>
              </div>
            </div>
            <div className="cs-card cs-style4 cs-type1">
              <div className="cs-card_info">
                <div className="cs-csra_info">
                  <div className="cs-csra_date">
                    <span>10:32pm</span>
                    <span>31 Jul 2022</span>
                  </div>
                  <DotOption />
                </div>
                <h3>Introduction to Laravel 8.0</h3>
                <p>
                  Laravel is a PHP based web desig framework for building high-
                  synt syntaxes.
                </p>
                <Link
                  to="/dashboard/resources-faq/id"
                  className="cs-coninue_reading"
                >
                  {t('continue_reading')}
                </Link>
                <div className="cs-tags">
                  <Link
                    to="/dashboard/resources-faq/hashtag/all"
                    className="cs-tag"
                  >
                    {t('all')}
                  </Link>
                  <Link
                    to="/dashboard/resources-faq/hashtag/photography"
                    className="cs-tag"
                  >
                    Photography
                  </Link>
                  <Link
                    to="/dashboard/resources-faq/hashtag/career"
                    className="cs-tag"
                  >
                    Career
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
