import React from "react";
import { Link } from "react-router-dom";
import NoticeDotOption from "../DotOption/NoticeDotOption";

export default function Notification({
  id,
  title,
  detail,
  name,
  tier,
  type,
  date,
  postId,
  seen,
  updateSeen,
  deleteNotification,
  makeNotificationSeen,
}) {
  // generate link
  let link = "";
  switch (type) {
    case "1":
      link = `/broadcasts/${postId}`;
      break;
    case "2":
      link = `/trade-diary/${postId}`;
      break;
    case "3":
      link = `/watchlists/${postId}`;
      break;
    case "4":
      link = `/open-positions?post_ids=${postId}`;
      break;
    case "99":
      link = "/members";
      break;
    case "6":
      link = `/newsletter/${postId}/view`;
      break;
    case "7":
      link = `/webinar-video/${postId}/view`;
      break;
    case "5":
      link = `resources-faq/${postId}`;
      break;
  }
  // deside what to render in detils content
  let content = null;
  if (type === "99" || type === "101") {
    content = (
      <div className="cs-notification_subtitle cs-break-all">
        <b>Name: </b>{" "}
        <span style={{ textTransform: "capitalize" }}>{name}</span> <br />
        <b>Tier:</b> {tier}
      </div>
    );
  } else if (type === "1") {
    content = detail.map((item, index) => (
      <div key={index} className="cs-notification_subtitle cs-break-all">
        {item}
      </div>
    ));
  } else {
    content = detail.map((item, index) => (
      <div key={index} className="cs-notification_subtitle cs-break-all">
        <b>Title: </b>
        {item}
      </div>
    ));
  }
  return (
    <div className={`cs-notification ${seen !== 1 ? "active" : ""}`}>
      <Link
        onClick={() => makeNotificationSeen(id)}
        to={link}
        className="cs-notification_right"
      >
        {title && (
          <h2 className="cs-notification_title cs-break-all">{title}</h2>
        )}
        {content ?? ""}
        {date && <div className="cs-notification_date">{date}</div>}
      </Link>
      <NoticeDotOption
        postId={id}
        seen={seen}
        updateSeen={updateSeen}
        deleteNotifications={deleteNotification}
      />
    </div>
  );
}
