import {
  broadcastListUrl,
  broadcastPinUrl,
  createBroadcastUrl,
  deleteBroadcastUrl,
  updateBroadcastUrl,
} from "../../const";
import axios from "../../lib/axios";
// create broadcast
export const createBroadcast = async (data) => {
  return axios.post(createBroadcastUrl, data);
};

// update broadcast
export const updateBroadcast = async (data) => {
  return axios.put(updateBroadcastUrl, data);
};

// get broadcast list
export const getBroadcastList = async (
  page = 1,
  limit = 5,
  search = "",
  pin = "",
  tag = "",
  post_id = ""
) => {
  return await axios.get(broadcastListUrl, {
    params: {
      page,
      limit,
      search,
      pin,
      tag,
      post_id,
    },
  });
};

// update broadcast pin
export const updateBroadcastPin = async (data) => {
  return await axios.put(broadcastPinUrl, data);
};

// delete broadcast
export const deleteBroadcast = async (post_id) => {
  return axios.delete(deleteBroadcastUrl, { params: { post_id } });
};
