import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createWebinarVideo } from '../../../api/services/webinarVideo';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import Spacing from '../../../components/Spacing';

export default function WebinarCreate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // handle create newsletter
  const handleWebinarCreate = async () => {
    const data = {
      title: '',
      short_desc: '',
      detail: '',
      video: '',
      image: '',
      files: '',
      tag: '',
      is_draft: '1',
    };
    setLoading(true);
    const res = await createWebinarVideo(data);
    navigate(`/webinar-video/${res.data.data.id}/edit`);
    setLoading(false);
  };

  useEffect(() => {
    handleWebinarCreate();
  }, []);
  return (
    <div className={`cs-container_md ${loading ? 'cs-loading_wrap' : ''}`}>
      {loading && (
        <>
          <Spacing lg="40" md="40" />
          <LoadingSpinner />
        </>
      )}
    </div>
  );
}
