import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import Spacing from '../components/Spacing';
import { UnreadNotification } from '../components/Notification/UnreadNotification';
import { AllNotification } from '../components/Notification/AllNotification';
import { useTranslation } from 'react-i18next';

export default function AllNotifications() {
  const [type, setType] = useState(1);
  const { t } = useTranslation();
  return (
    <div style={{ position: 'relative' }}>
      <div className="cs-container_md">
        <Spacing lg="60" md="60" />
        <div className="cs-notification_section">
          <Link to="/" className="cs-bak_list_btn cs-type2 cs-semibold">
            <>
              <Icon icon="ph:arrow-left-duotone" />
              <span>{t('back_to_home')}</span>
            </>
          </Link>
          <div className="cs-notification_btns">
            <button
              className={`cs-notification_btn ${type === 1 ? 'active' : ''}`}
              onClick={() => setType(1)}
            >
              {t('all')}
            </button>
            <button
              className={`cs-notification_btn ${type === 2 ? 'active' : ''}`}
              onClick={() => setType(2)}
            >
              {t('unread')}
            </button>
          </div>
          <Spacing lg="50" md="30" />
          {type === 1 && <AllNotification closeNotification={() => {}} />}
          {type === 2 && <UnreadNotification closeNotification={() => {}} />}
        </div>
        <Spacing lg="60" md="60" />
      </div>
    </div>
  );
}
