import Spacing from '../../../../components/Spacing';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { createTier } from '../../../../api/services/onboarding/membership-tiers';
import TermsOfUse from '../../../../components/TermsOfUse';
import { useTranslation } from 'react-i18next';
export default function CreateFreeMembershipTier({ setModal, refresh }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formSchema = Yup.object().shape({
    title: Yup.string().required('The tier title field is required'),
    description: Yup.string(),
    invitation_code: Yup.string(),
    member_qouta: Yup.string(),
    is_popular: Yup.string(),
    type: Yup.string(),
    price: Yup.string(),
    features: Yup.array().required('the feature field is required'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Please Accept for subscription terms and condition',
    ),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);
  // handle publish
  const handlePublish = async data => {
    setLoading(true);
    delete data.acceptTerms;
    const res = await createTier(data);
    if (res.data.success === 1) {
      refresh();
      setModal(false);
    } else {
      setErrorMessage(res.data.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    setValue('description', '');
    setValue('member_qouta', 0);
    setValue('is_popular', 0);
    setValue('type', 0);
    setValue('price', 0);
    setValue('invitation_code', '');
    setValue('currency', '');
  }, []);
  const [modal1, setModal1] = useState(false);
  return (
    <>
      <div className="cs-modal">
        <div className="cs-modal_overlay" onClick={() => setModal(false)}></div>
        <div className="cs-modal_in">
          <div className="cs-modal_container">
            <div className="cs-modal_box cs-style1">
              <Spacing lg="50" md="50" />
              <form
                onSubmit={handleSubmit(handlePublish)}
                className="cs-modal_box_in"
              >
                <h2
                  className="cs-m0"
                  style={{ fontSize: '21px', fontWeight: '600' }}
                >
                  {t('create_free_membership_tier')}
                </h2>
                <Spacing lg="25" md="25" />
                <label>{t('onboarding_free_membership_tier_input_1')} *</label>
                <input
                  type="text"
                  placeholder={t(
                    'onboarding_free_membership_tier_input_placeholder_1',
                  )}
                  className="cs-form_field"
                  {...register('title')}
                />
                <span className="invalid-feedback">
                  {errors.title?.message}
                </span>
                <Spacing lg="20" md="20" />
                <h2
                  className="cs-semibold"
                  style={{ fontSize: '21px', marginBottom: '5px' }}
                >
                  {t('subscription_features')} *
                </h2>
                <h3 className="cs-semibold" style={{ fontSize: '13px' }}>
                  ({t('subscription_features_subtitle')})
                </h3>
                <Spacing lg="5" md="5" />
                <div style={{ paddingLeft: '5%' }}>
                  <div className="cs-check">
                    <input
                      {...register('features')}
                      value="1"
                      type="checkbox"
                    />
                    <label>{t('broadcast')}</label>
                  </div>
                  <Spacing lg="10" md="10" />
                  <div className="cs-check">
                    <input
                      {...register('features')}
                      value="2"
                      type="checkbox"
                    />
                    <label>{t('trade_diary')} </label>
                  </div>
                  <Spacing lg="10" md="10" />
                  <div className="cs-check">
                    <input
                      {...register('features')}
                      value="6"
                      type="checkbox"
                    />
                    <label>{t('newsletter')}</label>
                  </div>
                </div>
                {errors.features?.message ? (
                  <span className="invalid-feedback">
                    Select at least one feature
                  </span>
                ) : null}
                <Spacing lg="25" md="25" />
                <div className="cs-check">
                  <input type="checkbox" {...register('acceptTerms')} />
                  <label>
                    {t('accept_the')}{' '}
                    <a href="https://gorudo.io/terms-of-use" target="_blank">
                      {t('terms_of_use')}
                    </a>
                  </label>
                </div>
                <p className="invalid-feedback">
                  {errors.acceptTerms?.message}
                </p>
                <Spacing lg="15" md="15" />
                {errorMessage !== '' && (
                  <>
                    <span className="cs-danger">{errorMessage}</span>
                    <Spacing lg="15" md="15" />
                  </>
                )}
                <button
                  className={`cs-modal_btn cs-color1 ${
                    loading ? 'cs-loading_wrap' : ''
                  }`}
                  style={{ width: '100%', borderRadius: '5px' }}
                  disabled={loading}
                >
                  {loading && (
                    <span className="cs-btn_loading">
                      <Icon icon="la:spinner" />
                      {t('loading')}
                    </span>
                  )}
                  {t('next')}
                </button>
              </form>
              <Spacing lg="50" md="50" />
            </div>
          </div>
        </div>
      </div>
      {modal1 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal1(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style2"
                style={{ maxWidth: '750px' }}
              >
                <div
                  className="cs-modal_box_in"
                  style={{ textAlign: 'center' }}
                >
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal1(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <TermsOfUse />
                  <Spacing lg="20" md="20" />
                  <button
                    className="cs-btn"
                    onClick={() => setModal1(false)}
                    style={{ maxWidth: '300px', width: '100%' }}
                  >
                    {t('i_agree')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
