import moment from "moment";
import { useTranslation } from "react-i18next";
import EmptyData from "../../../../components/Common/EmptyData";
import LoadingSpinner from "../../../../components/Common/LoadingSpinner";
import DotOption from "../../../../components/DotOption";
import Spacing from "../../../../components/Spacing";

export default function Published({ handelTab, openPosition, loading }) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`cs-table_responsive ${loading ? "cs-loading_wrap" : ""}`}
        style={{
          maxHeight: "295px",
          overflow: "auto",
          marginTop: "-1px",
        }}
      >
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <table className="cs-table cs-type1 cs-medium">
          <thead>
            <tr>
              <th className="cs-stycky_table_head">{t("date")}</th>
              <th className="cs-stycky_table_head">{t("ticker")}</th>
              <th className="cs-stycky_table_head">{t("cost")}</th>
              <th className="cs-stycky_table_head">
                {t("stop_loss_optional")}
              </th>
              <th className="cs-stycky_table_head">
                {t("current_return_loss")}
              </th>
              <th className="cs-stycky_table_head">{t("position_size")} %</th>
              <th className="cs-stycky_table_head">{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {openPosition.map((item, index) => (
              <tr key={index}>
                <td>{moment(item.date).format("D MMM Y")}</td>
                <td className="cs-primary_color cs-break-all">{item.ticker}</td>
                <td className="cs-primary_color cs-medium">{item.cost}</td>
                <td className="cs-danger_text cs-medium">
                  {item.stop_loss && "$"} {item.stop_loss}
                </td>
                <td style={{ color: "#858585" }}>{item.current_return} %</td>
                <td className="cs-primary_color cs-medium">
                  {item.position_size}% positioned
                </td>
                <td>
                  <DotOption
                    url={`/dashboard/${item.userId}/open-position/${item.id}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!openPosition.length && !loading ? <EmptyData /> : null}
      </div>
      <Spacing lg="20" md="20" />
      <div className="cs-open_posiiton_btns">
        <div></div>
        <div>
          <button className="cs-btn cs-color3" onClick={() => handelTab(1)}>
            {t("edit")}
          </button>
        </div>
      </div>
    </>
  );
}
