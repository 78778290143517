import {
  getMemberByCountryUrl,
  getMonthlySubscriberTrendingUrl,
  recentlySubscribedUrl,
  subscriberTrendingGraphUrl,
  getMontlyEarningPerTierUrl,
  getAllCountUrl,
  getOnbordingMissingInfoUrl,
} from "../../const";
import axios from "../../lib/axios";

// get dashboard graph
export const getSubscribeTrendingGraph = async () => {
  return await axios.get(subscriberTrendingGraphUrl);
};

// get member by country
export const getMemberByCountry = async () => {
  return await axios.get(getMemberByCountryUrl);
};

// get monthly subscription tranding
export const getMonthlySubscriptionTrending = async () => {
  return await axios.get(getMonthlySubscriberTrendingUrl);
};

// get recently subscribed member
export const getRecentlySubscribedMember = async (page = 1, limit = 5) => {
  return await axios.get(recentlySubscribedUrl, { params: { page, limit } });
};

// get monthly earning per tier
export const getMonthlyEarningPerTier = async () => {
  return await axios.get(getMontlyEarningPerTierUrl);
};

// get all count
export const getAllCount = async () => {
  return await axios.get(getAllCountUrl);
};

// get orboarding missing info
export const getMissingInfo = async () => {
  return await axios.get(getOnbordingMissingInfoUrl);
};
