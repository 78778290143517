import { useState, useEffect } from "react";
import { getMembershipTiers } from "../../api/services/onboarding/membership-tiers";
import TierContext from "./tier-context";

const TierState = (props) => {
  const [tiers, setTiers] = useState([]);

  // get user tiers
  const getTiers = async () => {
    const res = await getMembershipTiers();
    if (res.data.success === 1) {
      setTiers(res.data.data);
    } else {
    }
  };

  useEffect(() => {
    getTiers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TierContext.Provider
      value={{
        tiers,
      }}
    >
      {props.children}
    </TierContext.Provider>
  );
};

export default TierState;
