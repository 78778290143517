import {
  connectEarningSummaryUrl,
  connectEarningUrl,
  connectWithdrawalUrl,
} from "../../const";
import axios from "../../lib/axios";

export const connectEarningSummary = async () => {
  return await axios.get(connectEarningSummaryUrl);
};

export const getEarningHistory = async (page = 1, limit = 5) => {
  return await axios.get(connectEarningUrl, { params: { page, limit } });
};

export const getWithdrawalHistory = async (starting_after = "", limit = 5) => {
  return await axios.get(connectWithdrawalUrl, {
    params: { starting_after, limit },
  });
};
