import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "../components/Common/LoadingSpinner";

export default function RoleSwitch() {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    localStorage.setItem("token", searchParams.get("token"));
    localStorage.setItem("isAuth", true);
    window.location.href = "/";
  }, [searchParams]);

  return (
    <div
      className="cs-loading_wrap"
      style={{ widows: "100%", height: "100vh" }}
    >
      <LoadingSpinner />
    </div>
  );
}
