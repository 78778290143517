import { Icon } from '@iconify/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  cancelMemberSubscribtion,
  getAllMember,
} from '../../api/services/member';
import EmptyData from '../../components/Common/EmptyData';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import PaginateButton from '../../components/Common/PaginateButton';
import RecentlySubscribed from '../../components/Dashboard/RecentlySubscribed';
import Spacing from '../../components/Spacing';
import Statistics from '../../components/Statistics';
import { defaultHasMoreCount } from '../../config/const';
import { useTranslation } from 'react-i18next';

export default function Members() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const searchData = searchParams.get('search') ?? '';
  const [cancelIndex, setCancelIndex] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  // get member
  const getMember = async () => {
    setLoading(true);
    const res = await getAllMember(1, 5, searchData);
    setCount(res.data.count);
    setMembers(res.data.data);
    setLoading(false);
  };

  // handle cancel subscribtion
  const handleCancelSubscribtion = async (tierId, memberId, index) => {
    Swal.fire({
      title: t('are_you_sure'),
      text: t('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_cancel_it'),
    }).then(async result => {
      if (result.isConfirmed) {
        setCancelIndex(index);
        setCancelLoading(true);
        await cancelMemberSubscribtion({
          member_id: memberId,
          tier_id: tierId,
        });
        setCancelLoading(false);
        getMember();
        setCancelIndex('');
      }
    });
  };

  const handleSearch = async e => {
    e.preventDefault();
    setIsSearch(true);
    setLoading(true);
    const res = await getAllMember(1, 5, searchQuery);
    setCount(res.data.count);
    setMembers(res.data.data);
    setLoading(false);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getAllMember(page, 5, searchQuery);
    setMembers([...members, ...res.data.data]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    getMember();
  }, [searchData]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <div className="cs-search_box">
            <h2>{t('search_members')}</h2>
            <form onSubmit={handleSearch} className="cs-search_box_in">
              <input
                type="text"
                placeholder={t('members_search_placeholder')}
                onChange={e => setSearchQuery(e.target.value)}
              />
              <button>
                <Icon icon="material-symbols:search" />
              </button>
            </form>
            {isSearch && !loading && (
              <p className="cs-m0 cs-accent_color">
                {members.length} {t('search_result_found')}
              </p>
            )}
          </div>
          <Spacing lg="20" md="20" />
          <div className="cs-table_responsive">
            <table className="cs-table cs-type1" style={{ color: '#333333' }}>
              <thead>
                <tr>
                  <th
                    style={{
                      color: '#353535',
                      fontWeight: '600',
                      border: 'none',
                    }}
                  >
                    {t('members_table_head_1')}
                  </th>
                  <th
                    style={{
                      color: '#353535',
                      fontWeight: '600',
                      border: 'none',
                    }}
                  >
                    {t('members_table_head_2')}
                  </th>
                  <th
                    style={{
                      color: '#353535',
                      fontWeight: '600',
                      border: 'none',
                    }}
                  >
                    {t('members_table_head_3')}
                  </th>
                </tr>
              </thead>
              <tbody className={`${loading ? 'cs-loading_wrap' : ''}`}>
                {loading && (
                  <>
                    <Spacing lg="50" md="50" />
                    <LoadingSpinner />
                  </>
                )}
                {members.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <div
                        className="cs-primary_color cs-semibold"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {item.name_show}
                      </div>
                      <div
                        className="cs-accent_color"
                        style={{ marginBottom: '10px' }}
                      >
                        {item.membership_tiers_title}
                      </div>
                      <button
                        onClick={() =>
                          handleCancelSubscribtion(
                            item.membershipTireId,
                            item.memberId,
                            index,
                          )
                        }
                        className={`cs-subs_btn cs-accent_btn ${
                          cancelLoading && cancelIndex === index
                            ? 'cs-loading_wrap'
                            : ''
                        }`}
                        disabled={cancelLoading}
                      >
                        {cancelLoading && cancelIndex === index && (
                          <span className="cs-btn_loading">
                            <Icon icon="la:spinner" />
                            {t('loading')}
                          </span>
                        )}
                        {t('cancel_subscription')}
                      </button>
                    </td>
                    <td>{item.country}</td>
                    <td>{moment(item.created_at).format('D-MMM-Y LT')}</td>
                  </tr>
                ))}
                {/* <tr>
                  <td>
                    <div className="cs-primary_color cs-semibold">
                      Cristofer Passaquindici Arcand{" "}
                    </div>
                    <div
                      className="cs-accent_color"
                      style={{ marginBottom: "10px" }}
                    >
                      member ship tier name
                    </div>
                    <button className="cs-subs_btn">KEEP SUBSCRIPTION</button>
                  </td>
                  <td>Corona, Michigan</td>
                  <td>31-Jul-2022 10:32pm </td>
                </tr> */}
              </tbody>
            </table>
            {!members.length && !loading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {count > defaultHasMoreCount && (
            <PaginateButton
              loading={paginateLoading}
              hasMore={hasMore}
              onClick={paginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <Statistics />
          <Spacing lg="20" md="20" />
          <RecentlySubscribed />
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
