import { useState, useEffect } from "react";
import { getCurrentPlan } from "../../api/services/settings";
import PlanContext from "./plan-context";

const PlanState = (props) => {
  const [plan, setPlan] = useState([]);
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get user tiers
  const getPlan = async () => {
    setIsLoading(true);
    const res = await getCurrentPlan();
    setIsLoading(false);
    if (res.data.success === 1) {
      setFeatures(res.data.data ? res.data.data.features_name : []);
      setPlan(res.data.data);
    } else {
    }
  };

  useEffect(() => {
    getPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PlanContext.Provider
      value={{
        features,
        getPlan,
        isLoading,
      }}
    >
      {props.children}
    </PlanContext.Provider>
  );
};

export default PlanState;
