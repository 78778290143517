import { Route, Routes } from "react-router-dom";
import CreatorPublicView from "./components/CreatorPublicView";
import DashboardLayout from "./components/Layout/DashboardLayout";
import SettingsLayout from "./components/Layout/SettingsLayout";
import NewsletterDetailsView from "./pages/dashboard/Newsletter/NewsletterDetailsView";
import ResourcesFaqView from "./components/ResourcesFaqView";
import WebinarVideoView from "./components/WebinarVideoView";
import Broadcasts from "./pages/dashboard/Broadcasts";
import Newsletter from "./pages/dashboard/Newsletter";
import OpenPosition from "./pages/dashboard/OpenPosition";
import ResourcesFaq from "./pages/dashboard/ResourcesFaq";
import TradeDiary from "./pages/dashboard/TradeDiary";
import Watchlists from "./pages/dashboard/Watchlists";
import WebinarVideo from "./pages/dashboard/WebinarVideo";
import EmailVerification from "./pages/auth/EmailVerification";
import EmailConfirmation from "./pages/auth/EmailConfirmation";
import FogotPassword from "./pages/auth/FogotPassword";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import UpdateWithdrawalDetails from "./pages/settings/UpdateWithdrawalDetails";
import ChangePassword from "./pages/settings/ChangePassword";
import ChangePlan from "./pages/settings/ChangePlan";
import NitificationSettings from "./pages/settings/NitificationSettings";
import UpdatePassword from "./pages/auth/UpdatePassword";
import RelatedPages from "./components/RelatedPages";
import SearchResourcesFaq from "./pages/dashboard/SearchResourcesFaq";
import MyProfile from "./pages/settings/MyProfile";
import ModifyMembershipTiers from "./pages/settings/ModifyMembershipTiers";
import EarningDashboard from "./pages/settings/EarningDashboard";
import Home from "./pages/dashboard/Home";
import Members from "./pages/dashboard/Members";
import PersonalInfo from "./pages/onboarding/PersonalInfo";
import ChoosePlan from "./pages/onboarding/ChoosePlan";
import MembershipTiers from "./pages/onboarding/MembershipTiers";
import WithdrawalMethod from "./pages/onboarding/WithdrawalMethod";
import OnboardingLayout from "./components/Layout/OnboardingLayout";
import MembershipTiersEmpty from "./pages/onboarding/MembershipTiersEmpty";
import ResourcesFaqEdit from "./pages/dashboard/ResourcesFaq/ResourcesFaqEdit";
import WebinarVideoEdit from "./pages/dashboard/WebinarVideo/WebinarVideoEdit";
import NewsletterCreate from "./pages/dashboard/Newsletter/Create";
import NewsletterEdit from "./pages/dashboard/Newsletter/Edit";
import NewsletterView from "./pages/dashboard/Newsletter/View";
import OnboardingSuccess from "./pages/onboarding/OnboardingSuccess";
import WebinarCreate from "./pages/dashboard/WebinarVideo/Create";
import WebinarView from "./pages/dashboard/WebinarVideo/View";
import ResourceCreate from "./pages/dashboard/ResourcesFaq/Create";
import ResourceView from "./pages/dashboard/ResourcesFaq/View";
import BoradcastDetails from "./pages/dashboard/Broadcasts/BoradcastDetails";
import TradeDiaryDetails from "./pages/dashboard/TradeDiary/TradeDiaryDetails";
import WatchlistsDetails from "./pages/dashboard/Watchlists/WatchlistDetails";
import MultipleOpenPositionDetails from "./pages/dashboard/OpenPosition/MultipleOpenPositionDetails";
import AllNotifications from "./pages/AllNotifications";
import RoleSwitch from "./pages/RoleSwitch";

function App() {
  return (
    <>
      <Routes>
        <Route path="/pages" element={<RelatedPages />} />
        <Route path="/auth">
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="fogot-password" element={<FogotPassword />} />
          <Route path="email-confirmation" element={<EmailConfirmation />} />
          <Route path="update-password" element={<UpdatePassword />} />
        </Route>
        <Route path="/onboarding" element={<OnboardingLayout />}>
          <Route path="personal-info" element={<PersonalInfo />} />
          <Route path="choose-plan" element={<ChoosePlan />} />
          <Route path="membership-tiers" element={<MembershipTiers />} />
          <Route
            path="membership-tiers-empty"
            element={<MembershipTiersEmpty />}
          />
          <Route path="withdrawal-method" element={<WithdrawalMethod />} />
          <Route path="onboarding-success" element={<OnboardingSuccess />} />
        </Route>
        <Route path="/settings" element={<SettingsLayout />}>
          <Route path="my-profile" element={<MyProfile />} />
          <Route
            path="update-withdrawal-details"
            element={<UpdateWithdrawalDetails />}
          />
          <Route path="earning-dashboard" element={<EarningDashboard />} />
          <Route path="change-plan" element={<ChangePlan />} />
          <Route
            path="modify-membership-tiers"
            element={<ModifyMembershipTiers />}
          />
          <Route
            path="nitification-settings"
            element={<NitificationSettings />}
          />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="/" element={<DashboardLayout showSearch />}>
          <Route path="broadcasts" element={<Broadcasts />} />
          <Route path="broadcasts/:id" element={<BoradcastDetails />} />
          <Route path="trade-diary" element={<TradeDiary />} />
          <Route path="trade-diary/:id" element={<TradeDiaryDetails />} />
          <Route path="watchlists" element={<Watchlists />} />
          <Route path="watchlists/:id" element={<WatchlistsDetails />} />
          <Route path="open-position" element={<OpenPosition />} />
          <Route
            path="open-positions"
            element={<MultipleOpenPositionDetails />}
          />
          <Route path="newsletter" element={<Newsletter />} />
          <Route path="newsletter/new" element={<NewsletterCreate />} />
          <Route path="newsletter/:id/edit" element={<NewsletterEdit />} />
          <Route path="newsletter/:id/view" element={<NewsletterView />} />
          {/* <Route path="newsletter/image" element={<NewsletterImage />} /> */}
          <Route path="newsletter/:id" element={<NewsletterDetailsView />} />
          <Route path="webinar-video" element={<WebinarVideo />} />
          <Route path="webinar-video/create" element={<WebinarCreate />} />
          <Route path="webinar-video/:id/edit" element={<WebinarVideoEdit />} />
          <Route path="webinar-video/:id" element={<WebinarVideoView />} />
          <Route path="webinar-video/:id/view" element={<WebinarView />} />
          <Route path="resources-faq" element={<ResourcesFaq />} />
          <Route path="resources-faq/:id/edit" element={<ResourcesFaqEdit />} />
          <Route path="resources-faq/:id" element={<ResourceView />} />
          <Route
            path="resources-faq/hashtag/:id"
            element={<SearchResourcesFaq />}
          />
          <Route path="resources-faq/:id/view" element={<ResourcesFaqView />} />
          <Route path="resources-faq/create" element={<ResourceCreate />} />
          <Route
            path="creator-public-view/:id"
            element={<CreatorPublicView />}
          />
          <Route path="all-notification" element={<AllNotifications />} />
        </Route>
        <Route path="/" element={<DashboardLayout />}>
          <Route index element={<Home />} />
          <Route path="members" element={<Members />} />
        </Route>
        <Route path="role-switch" element={<RoleSwitch />} />
      </Routes>
    </>
  );
}

export default App;
