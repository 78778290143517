import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Spacing from "../../components/Spacing";
import { getUserToken } from "../../api/services/auth/user";

export default function OnboardingSuccess() {
  useEffect(() => {
    const getToken = async () => {
      const res = await getUserToken();
      localStorage.setItem("token", res.data.token);
    };
    getToken();
  }, []);
  return (
    <div className="cs-modal">
      <Link
        to="/"
        className="cs-modal_overlay"
        style={{ cursor: "w-resize" }}
      ></Link>
      <div className="cs-modal_in">
        <div className="cs-modal_container">
          <div className="cs-modal_box cs-style3" style={{ maxWidth: "600px" }}>
            <div className="cs-modal_box_in">
              <Spacing lg="30" md="30" />
              <div className="" style={{ textAlign: "center" }}>
                <svg
                  width={294}
                  height={141}
                  viewBox="0 0 294 141"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M39.8429 125.347V136.432C39.8429 138.861 37.5823 140.832 34.7981 140.832H22.0878C19.3035 140.832 17.043 138.861 17.043 136.432V125.347C17.043 122.919 19.3035 120.947 22.0878 120.947H34.7981C37.5823 120.947 39.8429 122.919 39.8429 125.347Z"
                    fill="#E3E3E3"
                  />
                  <path
                    d="M192.777 126.022V135.738C192.777 138.564 190.145 140.859 186.905 140.859H58.166C54.9252 140.859 52.2939 138.564 52.2939 135.738V126.022C52.2939 123.195 54.9252 120.9 58.166 120.9H186.905C190.145 120.9 192.777 123.195 192.777 126.022Z"
                    fill="#E3E3E3"
                  />
                  <path
                    d="M39.7791 91.8841V102.911C39.7791 105.326 37.5305 107.287 34.761 107.287H22.1178C19.3482 107.287 17.0996 105.326 17.0996 102.911V91.8841C17.0996 89.4687 19.3482 87.5076 22.1178 87.5076H34.761C37.5305 87.5076 39.7791 89.4687 39.7791 91.8841Z"
                    fill="#64C680"
                  />
                  <path
                    d="M162.674 92.7323V102.205C162.674 105.031 159.78 107.326 156.216 107.326H58.4345C54.8704 107.326 51.9766 105.031 51.9766 102.205V92.7323C51.9766 89.9059 54.8704 87.6111 58.4345 87.6111H156.216C159.78 87.6111 162.674 89.9059 162.674 92.7323Z"
                    fill="#C1E8BA"
                  />
                  <g opacity="0.999999">
                    <path
                      d="M39.7488 58.2727V69.2648C39.7488 71.6726 37.5073 73.6276 34.7465 73.6276H22.143C19.3822 73.6276 17.1406 71.6726 17.1406 69.2648V58.2727C17.1406 55.8649 19.3822 53.9099 22.143 53.9099H34.7465C37.5073 53.9099 39.7488 55.8649 39.7488 58.2727Z"
                      fill="#64C680"
                    />
                    <path
                      d="M250.882 59.0231V68.4065C250.882 71.233 247.729 73.5278 243.845 73.5278H59.0599C55.1765 73.5278 52.0234 71.233 52.0234 68.4065V59.0231C52.0234 56.1967 55.1765 53.9019 59.0599 53.9019H243.845C247.729 53.9019 250.882 56.1967 250.882 59.0231Z"
                      fill="#64C680"
                    />
                  </g>
                  <path
                    d="M278.61 41.1057H14.6749C6.57005 41.1057 0 35.3757 0 28.3071V12.9632C0 5.8043 6.65446 0 14.8635 0H278.421C286.63 0 293.285 5.8043 293.285 12.9632V28.3071C293.285 35.3757 286.716 41.1057 278.61 41.1057Z"
                    fill="url(#paint0_linear_5213_28130)"
                  />
                  <path
                    d="M27.8808 33.7795C19.5183 33.7795 12.7148 27.8465 12.7148 20.5532C12.7148 13.2599 19.5183 7.32568 27.8808 7.32568C36.2432 7.32568 43.0467 13.2599 43.0467 20.5532C43.0467 27.8465 36.2432 33.7795 27.8808 33.7795ZM27.8808 9.24615C20.7323 9.24615 14.9168 14.3188 14.9168 20.5532C14.9168 26.7871 20.7323 31.859 27.8808 31.859C35.0292 31.859 40.8447 26.7871 40.8447 20.5532C40.8447 14.3188 35.0292 9.24615 27.8808 9.24615Z"
                    fill="white"
                  />
                  <path
                    d="M25.8057 26.5128C25.4989 26.5128 25.2053 26.4014 24.9968 26.2043L20.0225 21.5049C19.61 21.115 19.6379 20.5082 20.0849 20.1484C20.5319 19.7886 21.2292 19.8136 21.6402 20.2028L25.7433 24.079L34.0632 14.9542C34.4412 14.5393 35.1348 14.4696 35.6097 14.7999C36.0854 15.1296 36.1646 15.7345 35.7859 16.1493L26.6674 26.1511C26.467 26.3701 26.1675 26.5026 25.846 26.5128C25.8328 26.5135 25.8196 26.5128 25.8057 26.5128Z"
                    fill="white"
                  />
                  <path
                    d="M53.3194 16.5356C53.8706 15.5514 54.623 14.7849 55.5772 14.2345C56.5314 13.6856 57.6016 13.4104 58.787 13.4104C60.1742 13.4104 61.389 13.7738 62.4328 14.4985C63.475 15.2231 64.2046 16.2252 64.6194 17.5055H61.7568C61.472 16.902 61.0719 16.4481 60.5559 16.1459C60.0407 15.8446 59.4446 15.6943 58.7694 15.6943C58.0456 15.6943 57.4027 15.8663 56.8397 16.2103C56.2767 16.5543 55.8385 17.0403 55.5243 17.6678C55.2102 18.2959 55.0531 19.0326 55.0531 19.8783C55.0531 20.7114 55.2102 21.4458 55.5243 22.08C55.8385 22.7141 56.2767 23.2024 56.8397 23.5464C57.4027 23.8912 58.0456 24.0632 58.7694 24.0632C59.4446 24.0632 60.0407 23.9099 60.5559 23.6018C61.0719 23.2937 61.472 22.8375 61.7568 22.2333H64.6194C64.2046 23.5262 63.478 24.5313 62.4408 25.25C61.4037 25.9686 60.1853 26.3284 58.787 26.3284C57.6016 26.3284 56.5314 26.0532 55.5772 25.5035C54.623 24.9538 53.8706 24.1896 53.3194 23.2114C52.7682 22.2333 52.4922 21.122 52.4922 19.8783C52.4922 18.6347 52.7682 17.5205 53.3194 16.5356Z"
                    fill="white"
                  />
                  <path
                    d="M68.567 25.7371C67.8088 25.3093 67.212 24.7021 66.7797 23.9169C66.3467 23.1316 66.1309 22.226 66.1309 21.1993C66.1309 20.1732 66.3533 19.2669 66.7981 18.4817C67.2429 17.6972 67.8499 17.09 68.6206 16.6615C69.3906 16.2322 70.2501 16.0176 71.1991 16.0176C72.1475 16.0176 73.0062 16.2322 73.7769 16.6615C74.5477 17.09 75.1547 17.6972 75.5987 18.4817C76.0435 19.2669 76.2667 20.1732 76.2667 21.1993C76.2667 22.226 76.0384 23.1316 75.5819 23.9169C75.1253 24.7021 74.5087 25.3093 73.7322 25.7371C72.9556 26.1663 72.088 26.381 71.1279 26.381C70.1796 26.381 69.326 26.1663 68.567 25.7371ZM72.3992 23.8354C72.7963 23.6118 73.1134 23.2767 73.3505 22.8295C73.5876 22.3831 73.7058 21.8402 73.7058 21.1993C73.7058 20.245 73.4599 19.5114 72.9681 18.9984C72.4756 18.4847 71.8744 18.2282 71.1632 18.2282C70.4519 18.2282 69.8566 18.4847 69.3759 18.9984C68.8966 19.5114 68.6558 20.245 68.6558 21.1993C68.6558 22.1535 68.89 22.8871 69.3583 23.4001C69.8266 23.9139 70.4167 24.1704 71.1279 24.1704C71.5779 24.1704 72.0014 24.059 72.3992 23.8354Z"
                    fill="white"
                  />
                  <path
                    d="M93.2562 17.1681C93.9969 17.9226 94.3675 18.9778 94.3675 20.3306V26.2175H91.8785V20.6731C91.8785 19.8887 91.6825 19.2874 91.2913 18.8709C90.9001 18.4543 90.3665 18.2457 89.6912 18.2457C89.0152 18.2457 88.4786 18.4543 88.0823 18.8709C87.6844 19.2874 87.4863 19.8887 87.4863 20.6731V26.2175H84.9973V20.6731C84.9973 19.8887 84.8013 19.2874 84.4101 18.8709C84.0188 18.4543 83.4852 18.2457 82.8099 18.2457C82.1222 18.2457 81.5798 18.4543 81.1827 18.8709C80.7856 19.2874 80.5867 19.8887 80.5867 20.6731V26.2175H78.0977V16.1817H80.5867V17.3947C80.9067 16.9722 81.3185 16.6394 81.8227 16.3978C82.3262 16.1563 82.8804 16.0366 83.4852 16.0366C84.2559 16.0366 84.9437 16.2019 85.5485 16.5339C86.1526 16.8667 86.6209 17.3401 86.9526 17.9563C87.2727 17.376 87.738 16.9123 88.3487 16.5616C88.9594 16.2116 89.62 16.0366 90.3313 16.0366C91.5402 16.0366 92.5156 16.4135 93.2562 17.1681Z"
                    fill="white"
                  />
                  <path
                    d="M100.546 16.4797C101.108 16.1716 101.752 16.0183 102.475 16.0183C103.317 16.0183 104.078 16.2299 104.76 16.6525C105.442 17.0757 105.981 17.6762 106.378 18.4555C106.775 19.2347 106.974 20.1366 106.974 21.1634C106.974 22.1894 106.775 23.0987 106.378 23.8899C105.981 24.6804 105.442 25.2936 104.76 25.7281C104.078 26.1633 103.317 26.3817 102.475 26.3817C101.752 26.3817 101.115 26.2299 100.564 25.9278C100.012 25.6256 99.5647 25.2457 99.2204 24.7866V31.0003H96.7314V16.1821H99.2204V17.6306C99.5405 17.1715 99.9823 16.7878 100.546 16.4797ZM104.066 19.5959C103.823 19.1554 103.503 18.8197 103.106 18.5908C102.708 18.3613 102.279 18.2461 101.817 18.2461C101.367 18.2461 100.942 18.365 100.546 18.5998C100.148 18.8354 99.8282 19.1771 99.5852 19.6243C99.3423 20.0708 99.2204 20.595 99.2204 21.1993C99.2204 21.8035 99.3423 22.3285 99.5852 22.7749C99.8282 23.2221 100.148 23.5639 100.546 23.7994C100.942 24.035 101.367 24.1532 101.817 24.1532C102.279 24.1532 102.708 24.032 103.106 23.7897C103.503 23.5482 103.823 23.2049 104.066 22.7585C104.309 22.3113 104.431 21.7788 104.431 21.1634C104.431 20.5591 104.309 20.0371 104.066 19.5959Z"
                    fill="white"
                  />
                  <path
                    d="M111.278 26.219H108.788V12.812H111.278V26.219Z"
                    fill="white"
                  />
                  <path
                    d="M122.817 21.9598H115.615C115.675 22.6844 115.923 23.252 116.362 23.6626C116.8 24.0739 117.341 24.2788 117.981 24.2788C118.905 24.2788 119.563 23.8742 119.954 23.0651H122.639C122.355 24.0313 121.809 24.8255 121.003 25.4469C120.197 26.0691 119.207 26.3802 118.034 26.3802C117.085 26.3802 116.235 26.1663 115.482 25.7371C114.729 25.3086 114.143 24.7013 113.722 23.9169C113.301 23.1316 113.091 22.226 113.091 21.1993C113.091 20.1605 113.298 19.2489 113.713 18.4637C114.128 17.6785 114.709 17.075 115.456 16.6517C116.202 16.2292 117.062 16.0183 118.034 16.0183C118.97 16.0183 119.809 16.224 120.55 16.6345C121.29 17.0451 121.866 17.6269 122.275 18.3822C122.683 19.1367 122.888 20.0042 122.888 20.9824C122.888 21.3451 122.864 21.6704 122.817 21.9598ZM120.31 20.257C120.298 19.6049 120.067 19.0837 119.617 18.6903C119.165 18.2984 118.614 18.101 117.962 18.101C117.346 18.101 116.828 18.2917 116.407 18.6716C115.986 19.0522 115.728 19.581 115.633 20.257H120.31Z"
                    fill="white"
                  />
                  <path
                    d="M127.6 18.2662V23.1211C127.6 23.4591 127.68 23.7036 127.84 23.8547C128 24.0057 128.27 24.0813 128.649 24.0813H129.805V26.2193H128.24C126.141 26.2193 125.092 25.1806 125.092 23.1031V18.2662H123.918V16.1827H125.092V13.7007H127.6V16.1827H129.805V18.2662H127.6Z"
                    fill="white"
                  />
                  <path
                    d="M140.688 21.9598H133.486C133.546 22.6844 133.794 23.252 134.233 23.6626C134.672 24.0739 135.212 24.2788 135.852 24.2788C136.776 24.2788 137.434 23.8742 137.826 23.0651H140.511C140.226 24.0313 139.68 24.8255 138.874 25.4469C138.069 26.0691 137.078 26.3802 135.905 26.3802C134.956 26.3802 134.106 26.1663 133.353 25.7371C132.6 25.3086 132.014 24.7013 131.593 23.9169C131.172 23.1316 130.962 22.226 130.962 21.1993C130.962 20.1605 131.169 19.2489 131.584 18.4637C131.999 17.6785 132.58 17.075 133.327 16.6517C134.073 16.2292 134.933 16.0183 135.905 16.0183C136.841 16.0183 137.68 16.224 138.421 16.6345C139.161 17.0451 139.737 17.6269 140.146 18.3822C140.555 19.1367 140.759 20.0042 140.759 20.9824C140.759 21.3451 140.735 21.6704 140.688 21.9598ZM138.181 20.257C138.169 19.6049 137.938 19.0837 137.488 18.6903C137.037 18.2984 136.485 18.101 135.833 18.101C135.217 18.101 134.699 18.2917 134.278 18.6716C133.857 19.0522 133.599 19.581 133.504 20.257H138.181Z"
                    fill="white"
                  />
                  <path
                    d="M142.529 18.4641C142.926 17.6796 143.469 17.0746 144.157 16.6521C144.845 16.2296 145.609 16.0179 146.451 16.0179C147.091 16.0179 147.701 16.1608 148.282 16.4442C148.863 16.7284 149.324 17.106 149.669 17.5764V12.812H152.193V26.2183H149.669V24.7331C149.36 25.2282 148.928 25.6268 148.371 25.9281C147.814 26.2303 147.168 26.3806 146.433 26.3806C145.603 26.3806 144.845 26.1637 144.157 25.7292C143.469 25.2947 142.926 24.6815 142.529 23.8903C142.132 23.0999 141.935 22.1905 141.935 21.1637C141.935 20.1489 142.132 19.25 142.529 18.4641ZM149.332 19.6142C149.095 19.1738 148.774 18.835 148.371 18.5994C147.969 18.3639 147.535 18.2472 147.073 18.2472C146.61 18.2472 146.184 18.3624 145.792 18.5912C145.401 18.8208 145.085 19.1558 144.842 19.597C144.599 20.0375 144.476 20.5602 144.476 21.1637C144.476 21.768 144.599 22.2952 144.842 22.7484C145.085 23.2008 145.405 23.5493 145.802 23.7908C146.198 24.0324 146.622 24.1528 147.073 24.1528C147.535 24.1528 147.969 24.0346 148.371 23.7991C148.774 23.5635 149.095 23.2262 149.332 22.785C149.568 22.3446 149.687 21.8158 149.687 21.2004C149.687 20.5842 149.568 20.0555 149.332 19.6142Z"
                    fill="white"
                  />
                  <path
                    d="M278.261 23.1104C278.335 22.9542 278.335 22.7775 278.261 22.6213C278.223 22.5425 278.169 22.4721 278.101 22.4132L273.682 18.5582C273.395 18.3085 272.931 18.3085 272.645 18.5582C272.358 18.8085 272.358 19.2137 272.645 19.4634L275.81 22.2257H266.974C265.429 22.2257 264.172 21.1291 264.172 19.7815V14.385C264.172 14.031 263.843 13.7449 263.438 13.7449C263.032 13.7449 262.704 14.031 262.704 14.385V19.7815C262.704 21.8352 264.619 23.506 266.974 23.506H275.81L272.645 26.2683C272.358 26.5186 272.358 26.9238 272.645 27.1734C272.788 27.2983 272.976 27.361 273.164 27.361C273.352 27.361 273.538 27.2983 273.682 27.1734L278.101 23.3191C278.169 23.2595 278.223 23.1891 278.261 23.1104Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_5213_28130"
                      x1="146.946"
                      y1="0.192686"
                      x2="147.117"
                      y2="41.5156"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#95F884" />
                      <stop offset="0.5" stopColor="#89EA78" />
                      <stop offset={1} stopColor="#7CDD6B" />
                    </linearGradient>
                  </defs>
                </svg>
                <Spacing lg="30" md="30" />
                <h2 style={{ fontSize: "18px", fontWeight: "600" }}>
                  Successfully Completed!{" "}
                </h2>
                <Spacing lg="10" md="10" />
                <div style={{ maxWidth: "250px", margin: "auto" }}>
                  <Link to="/" className="cs-logout_btn cs-type1 ">
                    Go to Dashboard
                  </Link>
                </div>
              </div>
              <Spacing lg="30" md="30" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
