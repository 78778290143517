import { Icon } from "@iconify/react";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";

// import DotOption from '../../../components/DotOption';
import Spacing from "../../../components/Spacing";
import { tagStringToArray } from "../../../utils/tagStringToArray";

import { getBroadcastList } from "../../../api/services/broadcast";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import { useTranslation } from "react-i18next";

export default function BoradcastDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const [broadcasts, setBoardcasts] = useState([]);
  const [broadcastLoading, setBroadcastLoading] = useState(false);

  // get broadcast
  const broadcast = async () => {
    setBroadcastLoading(true);
    const res = await getBroadcastList(1, 5, "", "", "", params.id);
    setBroadcastLoading(false);
    setBoardcasts(tagStringToArray(res.data.data));
  };

  useEffect(() => {
    broadcast();
  }, [useParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
            {t("broadcast")}
          </h2>
          <Spacing lg="30" md="30" />
          <div
            className={`cs-card_8_wrap ${
              broadcastLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {broadcastLoading && (
              <>
                <Spacing lg="40" md="40" />
                <LoadingSpinner />
              </>
            )}
            {broadcasts.map((item) => (
              <div key={uuidv4()} className="cs-card cs-style8">
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <Icon icon="fa6-solid:flag" />
                    <span>
                      {t("due_to")} {moment(item.created_at).format("DD-MMM-Y")}
                    </span>
                  </li>
                  <li>
                    <Icon icon="fa6-solid:clock" />
                    <span>{moment(item.created_at).format("LT")}</span>
                  </li>
                </ul>
                <h2 className="cs-card_title">{item.title}</h2>
                {item.image && <img src={item.image} alt="Thumb" />}
                <div className="cs-card_desc cs-break-all">
                  {item.description}
                </div>
                {/* <Link to='/' className="cs-card_btn">Continue Reading...</Link> */}
                <Spacing lg="15" md="15" />
                <div className="cs-tags">
                  {item.tag.map((tag) => (
                    <a key={uuidv4()} className={`cs-tag`}>
                      {tag}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Spacing lg="20" md="20" />
        </div>
      </div>
    </div>
  );
}
