import { useEffect } from 'react';
import { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getSubscribeTrendingGraph } from '../../api/services/dashboard';

export default function SubscribeTrendingGraph() {
  const [memberStatistics, setMemberStatistics] = useState({
    subscribe: [],
    unsubscribe: [],
  });
  const memberstatistics = async () => {
    const res = await getSubscribeTrendingGraph();
    setMemberStatistics(res.data.data);
  };

  const series = [
    {
      name: 'Subscribed',
      data: memberStatistics.subscribe,
    },
    {
      name: 'Unsubscribed',
      data: memberStatistics.unsubscribe,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: memberStatistics.title,
    },
    tooltip: {
      x: {},
    },
  };
  useEffect(() => {
    memberstatistics();
  }, []);
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
}
