import { Icon } from '@iconify/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

export default function NewsletterNav({ type }) {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const templeteType = searchParams.get('type') ?? '';
  return (
    <>
      <div className="cs-catd cs-style12">
        <h2 style={{ fontSize: '24px', marginBottom: '0' }}>
          {t('post_your_newsletter')}
        </h2>
        <p
          style={{
            fontSize: '14px',
            lineHeight: '1.6em',
            marginBottom: '25px',
          }}
        >
          {t('choose_template')}
        </p>
        <div className="cs-card_in">
          <div>
            <Link
              to="/newsletter/new?type=text-only"
              className={
                templeteType === 'text-only'
                  ? 'cs-card_item active'
                  : 'cs-card_item'
              }
            >
              <div className="cs-card_icon">
                <b>A</b>a
              </div>
              <div className="cs-card_title">{t('article')}</div>
            </Link>
          </div>
          <div>
            <Link
              to="/newsletter/new?type=image"
              className={
                templeteType === 'image'
                  ? 'cs-card_item active'
                  : 'cs-card_item'
              }
            >
              <div className="cs-card_icon" style={{ fontSize: '26px' }}>
                <Icon icon="mdi:image-area" />
              </div>
              <div className="cs-card_title">{t('image')}</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
