const baseURL = "https://api.gorudo.io/api/v1/";

// auth url
export const loginUrl = baseURL + "user/login";
export const logoutUrl = baseURL + "user/logout";
export const userTokenUrl = baseURL + "user/gen_token";
export const signUpUrl = baseURL + "user/registration";
export const checkUserNameUrl = baseURL + "user/check_username";
export const getUserUrl = baseURL + "user/get_user_info";
export const getCountryListUrl = baseURL + "user/get_country";
export const updateUserInfoUrl = baseURL + "user/update_user_info";
export const userVerifyUrl = baseURL + "user/user_varify";
export const forgotPassReqUrl = baseURL + "user/forget_password_send_email";
export const resetPasswordUrl = baseURL + "user/forget_password_update";
export const uploadProfileImageUrl = baseURL + "user/upload_profile_image";
export const userPlanUrl = baseURL + "user/get_users_plan";
export const switchRoleUrl = baseURL + "user/role_switch";

// onboarding url
export const updatePlanUrl = baseURL + "user/create_user_plan";
export const createTierUrl = baseURL + "user/create_membership_plan";
export const withdrawalMethodUrl = baseURL + "payment/create";
export const membershipTierUrl = baseURL + "user/get_membership_tier_list";
export const deleteTierUrl = baseURL + "my_tier/disable_tier";
export const myTierUpdateUrl = baseURL + "my_tier/tier";
export const getStripeCountryListUrl = baseURL + "payment/stripe_country";

// dashboad api
export const subscriberTrendingGraphUrl =
  baseURL + "dashboard/get_dashboard_graph";
export const getMemberByCountryUrl = baseURL + "dashboard/get_country_member";
export const getMonthlySubscriberTrendingUrl =
  baseURL + "dashboard/get_recent_statistics";
export const recentlySubscribedUrl = baseURL + "dashboard/get_recent_subscribe";
export const getMontlyEarningPerTierUrl = baseURL + "dashboard/get_tier_status";
export const getAllCountUrl = baseURL + "dashboard/get_all_count";
export const getOnbordingMissingInfoUrl =
  baseURL + "dashboard/get_creator_tier_info";

// notification url
export const allNotificationUrl = baseURL + "notification/creator_notification";
export const unreadNotificationUrl =
  baseURL + "notification/creator_notification_unreed";
export const notificationMakeSeenUrl =
  baseURL + "notification/creator_notification_seen";
export const notificationDeleteUrl =
  baseURL + "notification/creator_notification_delete";

// member api
export const getMemberUrl = baseURL + "member/get_all_members";
export const cancelMemberSubscribtionUrl =
  baseURL + "member/cancel_subscribe_member";

// newsletter api
export const newsletterUrl = baseURL + "newsletter/newsletter";
export const newsletterImageUrl = baseURL + "newsletter/post_image";
export const newsletterListUrl = baseURL + "newsletter/newsletter_list";
export const newsletterPinUrl = baseURL + "newsletter/newsletter_pin";

// broadcast api
export const createBroadcastUrl = baseURL + "broadcast/create_broadcast";
export const broadcastListUrl = baseURL + "broadcast/get_broadcast_list";
export const deleteBroadcastUrl = baseURL + "broadcast/delete_broadcast_by_id";
export const broadcastPinUrl = baseURL + "broadcast/create_broadcast_pin";
export const updateBroadcastUrl = baseURL + "broadcast/update_broadcast";

// trade diary api
export const tradeDiaryListUrl = baseURL + "trade_diary/get_trade_diary_type";
export const createTradeDiaryUrl = baseURL + "trade_diary/create_trader_diary";
export const createTradeDiaryTemplateUrl =
  baseURL + "trade_diary/create_trader_diary_template";
export const getTradeDiaryListUrl =
  baseURL + "trade_diary/get_trader_diary_list";
export const updateTradeDiaryPinUrl =
  baseURL + "trade_diary/create_trader_diary_pin";
export const tradeDiaryPinListUrl =
  baseURL + "trade_diary/get_trader_diary_pin_list";
export const tradeDiaryDeleteUrl = baseURL + "trade_diary/delete_trader_diary";
export const tradeDiaryUpdateUrl = baseURL + "trade_diary/update_trader_diary";
export const tradeDiaryTemplateListUrl =
  baseURL + "trade_diary/get_trader_diary_template_list";
export const tradeDiaryTemplateDeleteUrl =
  baseURL + "trade_diary/delete_trader_diary_template";
export const tradeDiaryTemplateUpdateUrl =
  baseURL + "trade_diary/update_trader_diary_template";

// watchlist api
export const getWatchlistUrl = baseURL + "watchlist/get_watchlist_list";
export const createWatchlistUrl =
  baseURL + "watchlist/create_watchlist_with_ticker";
export const updateWatchlistUrl =
  baseURL + "watchlist/update_watchlist_with_ticker";
export const deleteWatchlistUrl = baseURL + "watchlist/delete_watchlist";

// open position url
export const createOpenPositionUrl = baseURL + "open_position/open_position";
export const openPositionListUrl = baseURL + "open_position/open_position";
export const multipleOpenPositionUrl =
  baseURL + "open_position/open_postion_multiple";
export const deleteOpenPositionUrl = openPositionListUrl;
export const updateOpenPositionUrl = openPositionListUrl;

// webinar video url
export const createWebinarUrl = baseURL + "webinar/webinar";
export const uploadWebinarImageUrl = baseURL + "webinar/post_image";
export const getSingleWebinarUrl = baseURL + "webinar/get_webinar_by_id";
export const updateWebinarUrl = createWebinarUrl;
export const deleteWebinarUrl = createWebinarUrl;
export const webinarPinUpdateUrl = baseURL + "webinar/webinar_pin";

// resource url
export const resourceUrl = baseURL + "resources/resources";
export const resourceDeleteUrl = resourceUrl;
export const updateResourcePinUrl = baseURL + "resources/resources_pin";
export const createResourceUrl = baseURL + "resources/resources";
export const uploadResourceImageUrl = baseURL + "resources/post_image";
export const getSingleResourceUrl = baseURL + "resources/get_resources_by_id";
export const updateResourceUrl = baseURL + "resources/resources";

// setting url
export const getCurrentPlanUrl = baseURL + "settings/current_loop_plan";
export const changePasswordUrl = baseURL + "user/update_user_pass";
export const getEmailSettingsUrl = baseURL + "settings/email_settings";
export const getPushSettingsUrl = baseURL + "settings/push_settings";
export const updateEmailSettingsUrl = baseURL + "settings/email_settings";
export const updatePushSettingsUrl = baseURL + "settings/push_settings";
export const sendTestEmailUrl = baseURL + "email/send_email";
export const sendTestNotificationUrl =
  baseURL + "notification/send_notification";

// stripe connect url
export const connectAccountRetriveUrl =
  baseURL + "payment/connect_account_retrive";
export const connectAccountRejectUrl =
  baseURL + "payment/connect_account_reject";
export const connectAcountLoginUrl = baseURL + "payment/connect_account_login";
export const deleteConnectAccountUrl =
  baseURL + "payment/connect_account_delete";

// earning dashboard url
export const connectEarningSummaryUrl =
  baseURL + "payment/connect_earnings_summary";
export const connectWithdrawalUrl = baseURL + "payment/connect_withdrawal";
export const connectEarningUrl = baseURL + "payment/connect_earnings";

export const userTierPermissionUrl = baseURL + "user/tire_permission";
