import moment from "moment/moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getMultipleOpenPossition } from "../../../api/services/openPosition";

import LoadingSpinner from "../../../components/Common/LoadingSpinner";
// import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import OpenPositionGraph from "./components/OpenPositionGraph";

export default function MultipleOpenPositionDetails() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [openPosition, setOpenPosition] = useState([]);
  const [loading, setLoading] = useState(false);

  const post_ids = searchParams.get("post_ids").replace(/,*$/, "");

  // get open position
  const getAllOpenPostion = async () => {
    setLoading(true);
    const res = await getMultipleOpenPossition(post_ids);
    setOpenPosition(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    getAllOpenPostion();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
        {t("open_position")}
      </h2>
      <Spacing lg="30" md="30" />
      <div
        className={`cs-table_responsive ${loading ? "cs-loading_wrap" : ""}`}
        style={{
          overflow: "auto",
          marginTop: "-1px",
        }}
      >
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <table className="cs-table cs-type1 cs-medium">
          <thead>
            <tr>
              <th className="cs-stycky_table_head">{t("date")}</th>
              <th className="cs-stycky_table_head">{t("ticker")}</th>
              <th className="cs-stycky_table_head">{t("cost")}</th>
              <th className="cs-stycky_table_head">
                {t("stop_loss_optional")}
              </th>
              <th className="cs-stycky_table_head">
                {t("current_return_loss")}
              </th>
              <th className="cs-stycky_table_head">{t("position_size")} %</th>
              {/* <th className="cs-stycky_table_head">{t('action')}</th> */}
            </tr>
          </thead>
          <tbody>
            {openPosition.map((item, index) => (
              <tr key={index}>
                <td>{moment(item.date).format("D MMM Y")}</td>
                <td className="cs-primary_color cs-break-all">{item.ticker}</td>
                <td className="cs-primary_color cs-medium">$ {item.cost}</td>
                <td className="cs-danger_text cs-medium">$ {item.stop_loss}</td>
                <td style={{ color: "#858585" }}>{item.current_return} %</td>
                <td className="cs-primary_color cs-medium">
                  {item.position_size}% postioned
                </td>
                <td>
                  {/* <DotOption
                    url={`/dashboard/${item.userId}/open-position/${item.id}`}
                  /> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Spacing lg="50" md="30" />
      <OpenPositionGraph openPosition={openPosition} />
      <Spacing lg="60" md="60" />
    </div>
  );
}
