import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import Spacing from '../../components/Spacing';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from '../../hooks/useFetch';
import { getCountryListUrl } from '../../api/const';
import { useContext } from 'react';
import UserContext from '../../context/user/user-context';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import {
  updateProfilePicture,
  updateUserInfo,
} from '../../api/services/auth/user';
import { useEffect } from 'react';
import { dateConvart } from '../../utils/dateConvart';
import { useTranslation } from 'react-i18next';

export default function PersonalInfo() {
  const { t } = useTranslation();
  const [file, setFile] = useState({
    path: '',
    name: '',
    url: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const { data: countries, loading: fetchLoading } =
    useFetch(getCountryListUrl);
  const { getUserInformation, user } = useContext(UserContext);
  const navigate = useNavigate();
  const [charCount, setCharCount] = useState(0);

  const formSchema = Yup.object().shape({
    name_show: Yup.string(),
    whatsapp: Yup.string(),
    telegram: Yup.string(),
    discord: Yup.string(),
    fullname: Yup.string().required('The full name field required'),
    about_yourself: Yup.string().required('The about yourself field required'),
    dob: Yup.string().required('The date of birth field required'),
    country: Yup.string().required('The loaction field is required'),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  // handle update
  const handleUpdate = async data => {
    setIsLoading(true);
    await uploadProfileImage();
    await updateUserInfo(data);
    await getUserInformation();
    setIsLoading(false);
    navigate('/onboarding/choose-plan');
  };

  // upload profile image
  const uploadProfileImage = async () => {
    const formData = new FormData();
    formData.append('profile_image', file.path);
    if (file.path) {
      const res = await updateProfilePicture(formData);
      if (res.data.success === 1) {
        return res.data.image;
      }
    }
  };

  useEffect(() => {
    setValue('whatsapp', user.whatsapp);
    setValue('telegram', user.telegram);
    setValue('discord', user.discord);
    setValue('fullname', user.fullname ?? 'Name');
    setValue('about_yourself', user.about_yourself);
    setValue('country', user.country);
    setValue('dob', user.dob ? dateConvart(user.dob) : '');
    setFile({
      name: user.image?.split('/').pop(),
      url: user.image,
    });
  }, [countries, user]);

  const handelChange = e => {
    setFile({
      path: e.target.files[0],
      name: e.target.files[0].name,
      url: URL.createObjectURL(e.target.files[0]),
    });
  };
  const handelUploadCross = () => {
    setFile({
      url: '',
      path: '',
      name: '',
    });
  };
  return (
    <>
      <h2 className="cs-medium" style={{ fontSize: '21px' }}>
        {t('onboarding_personal_info_title')}
      </h2>
      <Spacing lg="20" md="20" />
      <form
        onSubmit={handleSubmit(handleUpdate)}
        className={`cs-row ${fetchLoading ? 'cs-loading_wrap' : ''}`}
      >
        {fetchLoading && <LoadingSpinner />}
        <div className="cs-col_6">
          <div className="upload_input">
            <svg
              width={32}
              height={43}
              viewBox="0 0 32 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.2126 17.5305L7.91846 17.5522"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
              />
              <path
                d="M0.197754 2.85498C0.197754 1.75041 1.09318 0.85498 2.19775 0.85498H19.9502C20.4851 0.85498 20.9976 1.0692 21.3734 1.44978L25.9332 6.06785L30.5035 10.6965C30.873 11.0708 31.0803 11.5757 31.0803 12.1017V40.558C31.0803 41.6625 30.1849 42.558 29.0803 42.558H2.19775C1.09318 42.558 0.197754 41.6625 0.197754 40.558V2.85498Z"
                fill="#092C4C"
              />
              <path
                d="M23.3597 34.2173H7.91846"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
              />
              <path
                d="M23.3597 25.877H7.91846"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
              />
            </svg>
            <div className="upload_output_right">
              <h3 className="cs-m0">
                Drag and drop here <br /> Any JPG, PNG or GIF up to 512MB <br />{' '}
                or
              </h3>
              <p className="cs-m0">Browse</p>
            </div>
            <input
              onChange={handelChange}
              type="file"
              // accept="image/*"
            />
          </div>
          {file.url && (
            <div className="upload_output">
              <div className="upload_output_img">
                <img src={file.url} alt="Thumb" />
              </div>
              <h4 className="m0 f18">{file && file.name}</h4>
              <span className="upload_output_close" onClick={handelUploadCross}>
                <Icon icon="gg:close-o" />
              </span>
            </div>
          )}
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6"></div>
        <div className="cs-col_6">
          <label>{t('onboarding_personal_info_input_1')}</label>
          <input
            type="text"
            placeholder={t('onboarding_personal_info_placeholder_1')}
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('fullname')}
          />
          {errors.fullname?.message && (
            <p className="invalid-feedback">{errors.fullname?.message}</p>
          )}
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6">
          <label>{t('onboarding_personal_info_input_2')}</label>
          <input
            type="date"
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('dob')}
          />
          {errors.dob?.message && (
            <p className="invalid-feedback">{errors.dob?.message}</p>
          )}
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6">
          <label>{t('onboarding_personal_info_input_3')}</label>
          <select
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('country')}
          >
            <option value="">Select Location</option>
            {countries.data?.map(country => {
              return (
                <option key={country.id} value={country.nicename}>
                  {country.nicename}
                </option>
              );
            })}
          </select>
          {errors.country?.message && (
            <p className="invalid-feedback">{errors.country?.message}</p>
          )}
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6">
          <label>{t('onboarding_personal_info_input_4')}</label>
          <input
            type="text"
            placeholder={t('onboarding_personal_info_placeholder_4')}
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('whatsapp')}
          />
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6">
          <label>{t('onboarding_personal_info_input_5')}</label>
          <input
            type="text"
            placeholder={t('onboarding_personal_info_placeholder_5')}
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('discord')}
          />
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6">
          <label>{t('onboarding_personal_info_input_6')}</label>
          <input
            type="text"
            placeholder={t('onboarding_personal_info_placeholder_6')}
            className="cs-form_field"
            style={{ fontSize: '14px' }}
            {...register('telegram')}
          />
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_12">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label>{t('onboarding_personal_info_input_7')}</label>
            <span style={{ color: '#A6A6A6' }}>{charCount}/150</span>
          </div>
          <textarea
            className="cs-form_field"
            cols="30"
            rows="4"
            maxLength="150"
            placeholder={t('onboarding_personal_info_placeholder_7')}
            style={{ fontSize: '14px' }}
            {...register('about_yourself')}
            onChange={e => {
              setCharCount(e.target.value.length);
            }}
          ></textarea>
          {errors.about_yourself?.message && (
            <p className="invalid-feedback">{errors.about_yourself?.message}</p>
          )}
          <Spacing lg="35" md="25" />
        </div>
        <div className="cs-col_6"></div>
        <div className="cs-col_6">
          <button
            className={`cs-logout_btn cs-type1 ${
              isLoading ? 'cs-loading_wrap' : ''
            }`}
            disabled={isLoading}
          >
            {isLoading && (
              <span className="cs-btn_loading">
                <Icon icon="la:spinner" />
                {t('loading')}
              </span>
            )}
            {t('next')}
          </button>
        </div>
      </form>
    </>
  );
}
