import { Icon } from '@iconify/react';
import React, { useState, useMemo, useRef } from 'react';
import Spacing from '../Spacing';
import ReactQuill from 'react-quill';
import { uploadNewsletterImage } from '../../api/services/newsletter';
import LoadingSpinner from '../Common/LoadingSpinner';
import { useTranslation } from 'react-i18next';
// import { useEffect } from "react";

export default function TextEditor({
  value,
  setValue,
  register,
  ReactQuillPlaceholder,
}) {
  const { t } = useTranslation();
  const [presentImage, setPresentImage] = useState('');
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const quillRef = useRef();

  const imageHandler = e => {
    // console.log(editor)
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      // convert image
      // const getBase64 = (file) => {
      //   return new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.onload = () => resolve(reader.result);
      //     reader.onabort = (error) => reject(error);
      //     reader.readAsDataURL(file);
      //   });
      // };

      if (/^image\//.test(file.type)) {
        // getBase64(file).then((base64) => {
        //   setPresentImage(base64);
        // });
        let formdata = new FormData();
        formdata.append('image', file);
        setLoading(true);
        const res = await uploadNewsletterImage(formdata);
        setLoading(false);
        const url = res?.data?.data;
        setPresentImage(url);
      } else {
        console.log('You could only upload images.');
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
    }),
    [],
  );

  const handelUpload = () => {
    const editor = quillRef.current.getEditor();
    editor.insertEmbed(editor.getSelection(), 'image', presentImage);
    setPresentImage('');
    setModal(false);
  };

  return (
    <>
      <div className="cs-editor_wrap">
        <input
          type="text"
          className="cs-editor_input"
          placeholder={t('title_required')}
          {...register('title')}
        />
        <button
          type="button"
          className={
            modal
              ? `${
                  presentImage
                    ? 'cs-editor_img_btn active present_img_active'
                    : 'cs-editor_img_btn active'
                }`
              : 'cs-editor_img_btn'
          }
          onClick={() => setModal(true)}
        >
          <img src="/images/icon/camera.png" alt="camera" />
        </button>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          editorHtml=""
          modules={modules}
          // formats={formats}
          placeholder={ReactQuillPlaceholder}
          ref={quillRef}
        />
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style1"
                style={{ maxWidth: '380px' }}
              >
                <div
                  className={`cs-upload_image_modal ${
                    loading ? 'cs-loading_wrap' : ''
                  }`}
                >
                  {loading && <LoadingSpinner />}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '8px',
                    }}
                  >
                    <h2
                      style={{ fontSize: '16px', fontWeight: '600', margin: 0 }}
                    >
                      Upload photo
                    </h2>
                    <Icon
                      icon="gg:close-o"
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                      onClick={() => setModal(false)}
                    />
                  </div>
                  {presentImage ? (
                    <div className="cs-editor_pre_img">
                      <img src={presentImage} alt="Thumb" />
                      <button
                        type="button"
                        className="cs-clear_img"
                        onClick={() => setPresentImage('')}
                      >
                        Clear
                      </button>
                    </div>
                  ) : (
                    <div className="upload_input" style={{ height: '180px' }}>
                      <svg
                        width={32}
                        height={43}
                        viewBox="0 0 32 43"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.2126 17.5305L7.91846 17.5522"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                        <path
                          d="M0.197754 2.85498C0.197754 1.75041 1.09318 0.85498 2.19775 0.85498H19.9502C20.4851 0.85498 20.9976 1.0692 21.3734 1.44978L25.9332 6.06785L30.5035 10.6965C30.873 11.0708 31.0803 11.5757 31.0803 12.1017V40.558C31.0803 41.6625 30.1849 42.558 29.0803 42.558H2.19775C1.09318 42.558 0.197754 41.6625 0.197754 40.558V2.85498Z"
                          fill="#092C4C"
                        />
                        <path
                          d="M23.3597 34.2173H7.91846"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                        <path
                          d="M23.3597 25.877H7.91846"
                          stroke="white"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                      <div className="upload_output_right">
                        <h3 className="cs-m0">
                          Drag and drop here <br /> Any JPG, PNG or GIF up to
                          512MB <br /> or
                        </h3>
                        <p className="cs-m0">Browse</p>
                      </div>
                    </div>
                  )}
                  <Spacing lg="15" md="15" />
                  <button
                    type="button"
                    className={
                      presentImage
                        ? 'cs-btn cs-color4'
                        : 'cs-btn cs-color2 disabled'
                    }
                    style={{ width: '100%' }}
                    onClick={handelUpload}
                  >
                    Upload Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Spacing lg="40" md="40" />
      <hr />
    </>
  );
}
