import {
  newsletterImageUrl,
  newsletterListUrl,
  newsletterPinUrl,
  newsletterUrl,
} from "../../const";
import axios from "../../lib/axios";

// create newsletter
export const createNewsletter = async (data) => {
  return axios.post(newsletterUrl, data);
};

// update newsletter
export const updateNewsletter = async (data) => {
  return axios.put(newsletterUrl, data);
};

// get single newsletter
export const getNewsletter = async (post_id) => {
  return axios.get(newsletterUrl, {
    params: {
      post_id,
    },
  });
};

// get newsletters
export const getNewsletters = async (
  page = 1,
  limit = 5,
  search = "",
  draft = 0,
  schedule = "",
  pin = "",
  tag = ""
) => {
  return await axios.get(newsletterListUrl, {
    params: {
      page,
      limit,
      search,
      draft,
      schedule,
      pin,
      tag,
    },
  });
};

// upload newsletter image
export const uploadNewsletterImage = async (file) => {
  return await axios.post(newsletterImageUrl, file);
};

// delete newsletter
export const deleteNewsletter = async (post_id) => {
  return await axios.delete(newsletterUrl, { params: { post_id } });
};

// update pin post
export const updateNewsletterPinPost = async (data) => {
  return await axios.post(newsletterPinUrl, data);
};
