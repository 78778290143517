import {
  getUserUrl,
  logoutUrl,
  switchRoleUrl,
  updateUserInfoUrl,
  uploadProfileImageUrl,
  userPlanUrl,
  userTierPermissionUrl,
  userTokenUrl,
} from "../../../const";
import axios from "../../../lib/axios";

// get user info
export const getUserInfo = async () => {
  const res = await axios.get(getUserUrl);
  if (res.data.success === 0) {
    localStorage.removeItem("token");
  }
  return res;
};

// update user info
export const updateUserInfo = async (data) => {
  delete data.name_show;
  return await axios.put(updateUserInfoUrl, data);
};

// upload profile image
export const updateProfilePicture = async (data) => {
  return await axios.post(uploadProfileImageUrl, data);
};

// get user plan
export const getUserPlan = async () => {
  return await axios.get(userPlanUrl);
};

// logout url
export const logoutUser = async (data) => {
  return await axios.post(logoutUrl, data);
};

// switch role
export const switchRole = async () => {
  return await axios.get(switchRoleUrl);
};

// get user tier permission
export const getUserTierPermission = async (type) => {
  return await axios.get(userTierPermissionUrl, { params: { type: type } });
};

export const getUserToken = async () => {
  return await axios.get(userTokenUrl);
};
