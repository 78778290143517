import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
// import DotOption from '../../../components/DotOption';
import Spacing from "../../../components/Spacing";
import { v4 as uuidv4 } from "uuid";
import { getWatchlist } from "../../../api/services/watchlist";
import { useTranslation } from "react-i18next";

export default function WatchlistsDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const [watchlists, setWatchlists] = useState([]);
  const [loading, setLoading] = useState(false);

  const watchlist = async () => {
    setLoading(true);
    const res = await getWatchlist(1, 5, "", "", "", params.id);
    setWatchlists(res.data.data);
    setLoading(false);
  };

  useEffect(() => {
    watchlist();
  }, [params]);
  return (
    <div className="cs-container_md" style={{ minHeight: "80vh" }}>
      <Spacing lg="30" md="40" />
      <div className="cs-section_heading_2">
        <div className="cs-section_left">
          <h2 className="cs-section_title">{t("watchlist")}</h2>
        </div>
      </div>
      <Spacing lg="30" md="30" />
      <div className={`${loading ? "cs-loading_wrap" : ""}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        <>
          {watchlists.map((item) => (
            <div key={uuidv4()}>
              <div className="cs-watchlist">
                <div className="cs-watchlist_left">
                  <div className="cs-add_date_heading">
                    <div className="cs-add_date_in">
                      <span>{moment(item.note_date).format("dddd")}</span>
                      {/* <span>22 Jan</span> */}
                      <span>{moment(item.note_date).format("DD MMM")}</span>
                    </div>
                    {/* <DotOption
                      url={`/dashboard/${item.userId}/watchlists/${item.id}`}
                    /> */}
                  </div>
                  {/* <Spacing lg='5' md='5'/>
              <div className="cs-add_date_text">Some of these may need a pullback or some tighte-ning, but this is our focus coming into the week. Not the RED signal - also!</div> */}
                  <Spacing lg="25" md="25" />
                  {/* <button className="cs-add_date_btn">+ Add Date</button> */}
                </div>
                <div className="cs-watchlist_right">
                  <div className="cs-todo_input">
                    <label className="cs-todo_label">
                      {t("creators_note")}
                    </label>
                    <div style={{ marginTop: "10px" }} className="cs-break_all">
                      {item.note}
                    </div>
                    {/* <input type="text" className="cs-form_field" placeholder='Enter note' /> */}
                  </div>
                  <Spacing lg="25" md="25" />
                  <ul className="cs-todo_list cs-mp0">
                    {item.components.map((ticker) => (
                      <li key={uuidv4()}>
                        <div className="cs-todo">
                          <div className="cs-todo_title">{ticker.title}</div>
                          <div className="cs-todo_subtitle">
                            {t("posted_in")}{" "}
                            {moment(ticker.created_at).format("LT")}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Spacing lg="25" md="25" />
            </div>
          ))}
        </>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
