import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LangauageDropdown({
  handelLanguageToggle,
  languageToggle,
  close,
}) {
  const refOne = useRef(null);
  const { i18n } = useTranslation();
  const [chooseLang, setChooseLang] = useState(
    localStorage.getItem('lang') ?? 'en',
  );
  // Hide on outside click
  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      close(false);
    }
  };

  // handle choose lang
  const handleChooseLang = lang => {
    i18n.changeLanguage(lang);
    setChooseLang(lang);
    close(false);
  };

  useEffect(() => {
    // event listeners
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);

  // set language to localstorage
  useEffect(() => {
    localStorage.setItem('lang', chooseLang);
  }, [chooseLang]);
  return (
    <div className="cs-dropdown_wrap cs-language" ref={refOne}>
      <button className="cs-language_btn" onClick={handelLanguageToggle}>
        <span style={{ textTransform: 'uppercase' }}>
          {chooseLang === 'tc' ? '繁中' : chooseLang}
        </span>
        <svg
          style={{
            transform: `${languageToggle ? 'rotate(180deg)' : 'rotate(0deg)'}`,
          }}
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.0752 1L6.40853 6.33333L11.7419 1"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {languageToggle && (
        <div className="cs-dropdown cs-size_sm cs-center_mode">
          <ul className="cs-mp0 cs-semibold" style={{ fontSize: '16px' }}>
            <li style={{ justifyContent: 'center' }}>
              <span onClick={() => handleChooseLang('en')}>EN</span>
            </li>
            <li style={{ justifyContent: 'center' }}>
              <span onClick={() => handleChooseLang('tc')}>繁中</span>
            </li>
            {/* <li style={{ justifyContent: "center" }}>
              <span onClick={() => handleChooseLang("sc")}>SC</span>
            </li>
            <li style={{ justifyContent: "center" }}>
              <span onClick={() => handleChooseLang("ja")}>JA</span>
            </li> */}
          </ul>
        </div>
      )}
    </div>
  );
}
