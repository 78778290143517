import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import NotificationContext from '../../context/notification/notification-context';
import { AllNotification } from '../Notification/AllNotification';
import { UnreadNotification } from '../Notification/UnreadNotification';

import Spacing from '../Spacing';

export default function NotificationComponent({
  closeNotification,
  handelNoticeToggle,
  noticeToggle,
}) {
  const { t } = useTranslation();
  const refOne = useRef(null);
  const [type, setType] = useState(1);
  const { unreadCount } = useContext(NotificationContext);

  useEffect(() => {
    // event listeners
    document.addEventListener('click', hideOnClickOutside, true);
  }, []);
  // Hide on outside click
  const hideOnClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      closeNotification(false);
    }
  };
  return (
    <div className="cs-dropdown_wrap cs-notice" ref={refOne}>
      <button className="cs-notice_btn" onClick={handelNoticeToggle}>
        <svg
          width="11"
          height="15"
          viewBox="0 0 11 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.5 14.4475C5.13524 14.4457 4.78583 14.2989 4.52726 14.0389C4.2687 13.7789 4.12173 13.4266 4.11812 13.058H6.86812C6.86958 13.2437 6.83453 13.4279 6.765 13.5999C6.67609 13.8061 6.53996 13.9879 6.36783 14.1304C6.1957 14.273 5.9925 14.3721 5.775 14.4197H5.74269C5.66281 14.4365 5.58157 14.4458 5.5 14.4475ZM11 12.3633H0V10.9738L1.375 10.279V6.458C1.33878 5.47781 1.55783 4.50518 2.01025 3.63737C2.23289 3.23947 2.53648 2.8938 2.90101 2.62315C3.26554 2.35251 3.68271 2.16304 4.125 2.06726V0.552734H6.875V2.06726C8.64806 2.49383 9.625 4.05282 9.625 6.458V10.279L11 10.9738V12.3633Z"
            fill="currentColor"
          />
        </svg>
        {unreadCount > 0 && <span>{unreadCount}</span>}
      </button>
      {noticeToggle && (
        <div className="cs-dropdown cs-size_xl cs-notice_dropdown">
          <div className="cs-notice_head">
            <div className="cs-notice_head_in">
              <div>
                <h2>{t('notification')}</h2>
              </div>
              <div>
                <Link
                  onClick={() => closeNotification(false)}
                  to="/all-notification"
                  className="cs-notice_see_all"
                >
                  {t('see_all')}
                </Link>
              </div>
            </div>
            <div className="cs-header_notice_btns">
              <button
                className={`cs-header_notice_btn ${type === 1 ? 'active' : ''}`}
                onClick={() => setType(1)}
              >
                {t('all')}
              </button>
              <button
                className={`cs-header_notice_btn ${type === 2 ? 'active' : ''}`}
                onClick={() => setType(2)}
              >
                {t('unread')}
              </button>
            </div>
            <Spacing lg="10" md="10" />
          </div>
          {type === 1 && (
            <AllNotification closeNotification={closeNotification} />
          )}
          {type === 2 && (
            <UnreadNotification closeNotification={closeNotification} />
          )}
          {/* <ul className="cs-notification_list cs-mp0">
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="“CTT has a breakout watch on TSLA 530.8. Stop loss 498.2”"
                note="Note: Suggest 2% max risk on trades. To be clear I amm basing this on 1 stock (trade) not your entire account..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Be careful here: $SNOW Upgrade working.  Do not see that happen much in this market. While many are and..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
            <li>
              <Notification
                thumb="/images/user6.png"
                title="Scott Smith has wrote a new Trade Dairy."
                subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eu velit tempus erat egestas efficitur. In hac..."
                date="10:32pm ; 31 Jul 2022"
              />
            </li>
          </ul> */}
        </div>
      )}
    </div>
  );
}
