export const notificationDetailsStringToArray = (data) => {
  let newArray = [];
  data.map((item) => {
    var details = item.detail ? item.detail.split("\n") : [];
    delete item.details;
    newArray.push({ ...item, detail: details });
  });

  return newArray;
};
