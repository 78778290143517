import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteTradeDiary,
  getTradeDiaryList,
  getTradeDiaryPinList,
  getTradeDiaryTemplateList,
  getTradeDiaryType,
  updateTradeTradeDiaryPin,
} from '../../../api/services/tradeDiary';
import { v4 as uuidv4 } from 'uuid';
import EmptyData from '../../../components/Common/EmptyData';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import PaginateButton from '../../../components/Common/PaginateButton';
import TradeDairyCrud from '../../../components/crud/TradeDairyCrud';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import { defaultHasMoreCount } from '../../../config/const';
import UsualTemplates from './UsualTemplates';
import { useTranslation } from 'react-i18next';

export default function TradeDiary() {
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const [templateLoading, setTemaplateLoading] = useState(false);
  const [tradeDiaryTemplate, setTradeDiaryTemplate] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [filterId, setFilterId] = useState([]);
  const [updatedTradeDiary, setUpdatedTradeDiary] = useState({});
  const [typeLoading, setTypeLoading] = useState(false);
  const [tradeDiaryTypes, setTradeDiaryTypes] = useState([]);
  const [tradeDiary, setTradeDiary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [pinnedTradeDiary, setPinnedTradeDiary] = useState([]);
  const [pinnedCount, setPinnedCount] = useState(0);
  const [pinnedLoading, setPinnedLoading] = useState(false);
  const [pinnedHasMore, setPinnedHasMore] = useState(true);
  const [pinnedPage, setPinnedPage] = useState(2);
  const [pinnedPaginateLoading, setPinnedPaginateLoading] = useState(false);

  const searchQuery = searchParams.get('search') ?? '';
  // get trade diary type
  const tradeDiaryType = async () => {
    setTypeLoading(true);
    const res = await getTradeDiaryType();
    setTradeDiaryTypes(res.data.data);
    setTypeLoading(false);
  };

  // get Trade diary list
  const getTradeDiary = async () => {
    setLoading(true);
    const res = await getTradeDiaryList(1, 5, searchQuery, filterId);
    setTradeDiary(res.data.data);
    setCount(res.data.count);
    setLoading(false);
  };

  // pinned trade diary list
  const pinnedTradeDiaryList = async () => {
    setPinnedLoading(true);
    const res = await getTradeDiaryPinList();
    setPinnedTradeDiary(res.data.data);
    setPinnedCount(res.data.count);
    setPinnedLoading(false);
  };

  // trade diary template list
  const getTradeDiaryTemplate = async () => {
    setTemaplateLoading(true);
    const res = await getTradeDiaryTemplateList();
    setTradeDiaryTemplate(res.data.data);
    setTemaplateLoading(false);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getTradeDiaryList(page, 5, searchQuery, filterId);
    setTradeDiary([...tradeDiary, ...res.data.data]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // pinned paginate
  const pinnedPaginate = async () => {
    setPinnedPaginateLoading(true);
    const res = await getTradeDiaryPinList(page, 5);
    setPinnedTradeDiary([...pinnedTradeDiary, ...res.data.data]);
    setPinnedPaginateLoading(false);
    setPinnedPage(pinnedPage + 1);
    if (!res.data.data.length) {
      setPinnedHasMore(false);
    }
  };

  // handle update pin
  const updatePinPost = async (post_id, is_pinned) => {
    const pinType = is_pinned === 1 ? 0 : 1;
    const data = {
      post_id,
      is_pinned: pinType,
    };
    await updateTradeTradeDiaryPin(data);
    setPage(2);
    getTradeDiary();
    pinnedTradeDiaryList();
  };

  // handle delete
  const handleDelete = async post_id => {
    Swal.fire({
      title: t('are_you_sure'),
      text: t('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_delete_it'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await deleteTradeDiary(post_id);
          if (res.data.success === 1) {
            Swal.fire(t('deleted'), t('your_post_has_been_deleted'), 'success');
            getTradeDiary();
            pinnedTradeDiaryList();
            setPage(2);
            setPinnedPage(2);
          }
        } catch (e) {}
      }
    });
  };

  useEffect(() => {
    tradeDiaryType();
    pinnedTradeDiaryList();
    getTradeDiaryTemplate();
  }, []);
  useEffect(() => {
    getTradeDiary();
  }, [searchParams, filterId]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <TradeDairyCrud
            updatedTradeDiary={updatedTradeDiary}
            setFilterId={setFilterId}
            filterId={filterId}
            tradeDiaryTypes={tradeDiaryTypes}
            typeLoading={typeLoading}
            refreshTradeDiary={getTradeDiary}
            title={t('post_your_trade_dairy')}
            refreshTemplate={getTradeDiaryTemplate}
          />
          <Spacing lg="30" md="30" />
          <div className={`cs-card_9_wrap ${loading ? 'cs-loading_wrap' : ''}`}>
            {loading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {tradeDiary.map(item => (
              <div className="cs-card cs-style9" key={uuidv4()}>
                <div
                  className="cs-card_bar"
                  style={{ backgroundColor: item.trade_dairy_color_background }}
                ></div>
                <div className="cs-card_in">
                  <div className="cs-left">
                    <h2 className="ca-card_title">{item.stock_name}</h2>
                    <div className="cs-card_meta">
                      <div>
                        <div className="cs-card_meta_title">{t('price')}</div>
                        <div className="cs-card_meta_price">
                          {item.stock_price !== 'Now' && '$'}
                          {item.stock_price}
                        </div>
                      </div>
                      <div>
                        <div className="cs-card_meta_title">
                          {t('stop_loss')}
                        </div>
                        <div className="cs-card_meta_price">
                          ${item.stop_loss}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cs-right">
                    <div className="cs-card_label">
                      <span
                        style={{
                          backgroundColor: item.trade_dairy_color_background,
                        }}
                      ></span>
                      {item.trade_dairy_name}
                    </div>
                    <div className="cs-card_date">
                      {moment(item.created_at).format('DD-MMM-Y')}{' '}
                      {moment(item.created_at).format('LT')}
                    </div>
                  </div>
                </div>
                {item.note !== '' && (
                  <>
                    <div
                      className="cs-card_note_title"
                      style={{ textTransform: 'uppercase' }}
                    >
                      <Icon icon="mdi:tick-circle-outline" />
                      {t('notes')}:
                    </div>
                    <div className="cs-card_text cs-break-all">{item.note}</div>
                  </>
                )}
                <DotOption
                  url={`/dashboard/${item.userId}/trade-diary/${item.id}`}
                >
                  <button
                    onClick={() => {
                      setUpdatedTradeDiary(item);
                      setModal(true);
                    }}
                  >
                    <Icon icon="material-symbols:edit" /> {t('edit')}
                  </button>
                  <button onClick={() => handleDelete(item.id)}>
                    <Icon icon="material-symbols:delete-rounded" />{' '}
                    {t('delete')}
                  </button>
                </DotOption>
                <button
                  className={`cs-icon_btn cs-pit_btn ${
                    item.is_pinned ? 'active' : ''
                  }`}
                  onClick={() => updatePinPost(item.id, item.is_pinned)}
                >
                  <svg
                    width={9}
                    height={13}
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            ))}
            {!tradeDiary.length && !loading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {count > defaultHasMoreCount && (
            <PaginateButton
              loading={paginateLoading}
              hasMore={hasMore}
              onClick={paginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t('my_pin')}</h2>
          </div>
          <Spacing lg="20" md="20" />
          <div
            className={`cs-card_1_list ${
              pinnedLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {pinnedLoading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {pinnedTradeDiary.map(item => (
              <div className="cs-card cs-style1" key={uuidv4()}>
                <DotOption
                  url={`/dashboard/${item.userId}/trade-diary/${item.id}`}
                >
                  <button
                    onClick={() => {
                      setModal(true);
                      setUpdatedTradeDiary(item);
                    }}
                  >
                    <Icon icon="material-symbols:edit" /> {t('edit')}
                  </button>
                  <button onClick={() => handleDelete(item.id)}>
                    <Icon icon="material-symbols:delete-rounded" />{' '}
                    {t('delete')}
                  </button>
                </DotOption>
                <button
                  className="cs-icon_btn cs-pit_btn active"
                  onClick={() => updatePinPost(item.id, item.is_pinned)}
                >
                  <svg
                    width={9}
                    height={13}
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
                <h2>{item.stock_name}</h2>
                <span
                  className="cs-card_label"
                  style={{
                    color: item.trade_dairy_color_text,
                    background: item.trade_dairy_color_background,
                  }}
                >
                  {item.trade_dairy_name}
                </span>
                <Spacing lg="10" md="10" />

                <p className="cs-semibold">
                  <span
                    className="cs-info_color"
                    style={{ marginRight: '5px' }}
                  >
                    {t('price')}:
                  </span>
                  <span className="cs-accent_color">
                    {item.stock_price} {item.stock_price !== 'Now' && 'USD'}
                  </span>
                </p>
                <Spacing lg="5" md="5" />
                <p className="cs-semibold">
                  <span
                    className="cs-info_color"
                    style={{ marginRight: '5px' }}
                  >
                    {t('stop_loss')}:
                  </span>
                  <span className="cs-primary_color">{item.stop_loss} USD</span>
                </p>
                <Spacing lg="10" md="5" />
                <div className="cs-card_top_date cs-info_color">
                  <span>{moment(item.created_at).format('DD-MMM-Y')}</span>{' '}
                  <span>{moment(item.created_at).format('LT')}</span>
                </div>
                <Spacing lg="10" md="10" />
                {item.note !== '' && (
                  <p className="cs-primary_color cs-break-all">
                    <span style={{ textTransform: 'uppercase' }}>
                      {t('note')}
                    </span>
                    : {item.note}
                  </p>
                )}
              </div>
            ))}
            {!pinnedTradeDiary.length && !pinnedLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {pinnedCount > defaultHasMoreCount && (
            <PaginateButton
              loading={pinnedPaginateLoading}
              hasMore={pinnedHasMore}
              onClick={pinnedPaginate}
            />
          )}
          <Spacing lg="40" md="40" />
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t('my_usual_template')}</h2>
          </div>
          <Spacing lg="20" md="20" />
          <div
            style={{
              overflow: 'auto',
              maxHeight: '416px',
              boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
            }}
          >
            <div className="cs-usual_card_sm_wrap">
              <div className="cs-usual_card_sm_head">
                <button type="button" onClick={() => setModal2(true)}>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 8L21 3M21 3H16M21 3V8M8 8L3 3M3 3L3 8M3 3L8 3M8 16L3 21M3 21H8M3 21L3 16M16 16L21 21M21 21V16M21 21H16"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>{t('view_expand')}</span>
                </button>
              </div>
              <ul
                className={`cs-usual_card_sm_list cs-mp0 ${
                  templateLoading ? 'cs-loading_wrap' : ''
                }`}
              >
                {templateLoading && (
                  <>
                    <Spacing lg="50" md="50" />
                    <LoadingSpinner />
                  </>
                )}
                {tradeDiaryTemplate.map(item => (
                  <li onClick={() => setUpdatedTradeDiary(item)}>
                    <div className="cs-usual_card_sm">
                      <div className="cs-usual_card_sm_title">
                        <Icon icon="mingcute:message-4-line" />
                        {item.template_name}
                      </div>
                      <span
                        className="cs-card_label_template"
                        style={{
                          background: item.trade_dairy_color_background,
                          color: item.trade_dairy_color_text,
                        }}
                      >
                        {item.trade_dairy_name}
                      </span>
                      <Spacing lg="10" md="10" />
                      {/* <button className="cs-card_btn" type="button">
                        <Icon icon="mingcute:delete-2-line" />
                      </button> */}
                      <div className="cs-row">
                        <div className="cs-col_6">
                          <label
                            style={{
                              fontSize: '12px',
                              display: 'block',
                              lineHeight: '1.2em',
                            }}
                          >
                            {t('stock_name')} *
                          </label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_stock_name')}
                            value={item.stock_name}
                            style={{ fontSize: '12px', minHeight: 'initial' }}
                            disabled
                          />
                          <Spacing lg="15" md="15" />
                        </div>
                        <div className="cs-col_6">
                          <label
                            style={{
                              fontSize: '12px',
                              display: 'block',
                              lineHeight: '1.2em',
                            }}
                          >
                            {t('stock_price')}
                          </label>
                          <input
                            type="text"
                            className="cs-form_field"
                            value={item.stock_price}
                            placeholder={t('enter_stock_price')}
                            style={{ fontSize: '12px', minHeight: 'initial' }}
                            disabled
                          />
                          <Spacing lg="15" md="15" />
                        </div>
                        <div className="cs-col_6">
                          <label
                            style={{
                              fontSize: '12px',
                              display: 'block',
                              lineHeight: '1.2em',
                            }}
                          >
                            {t('note')}
                          </label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_note')}
                            value={item.note}
                            style={{ fontSize: '12px', minHeight: 'initial' }}
                            disabled
                          />
                          <Spacing lg="15" md="15" />
                        </div>
                        <div className="cs-col_6">
                          <label
                            style={{
                              fontSize: '12px',
                              display: 'block',
                              lineHeight: '1.2em',
                            }}
                          >
                            {t('stop_loss')}
                          </label>
                          <input
                            type="text"
                            className="cs-form_field"
                            placeholder={t('enter_stop_loss')}
                            value={item.stop_loss}
                            style={{ fontSize: '12px', minHeight: 'initial' }}
                            disabled
                          />
                          <Spacing lg="15" md="15" />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="50" md="50" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style3">
                <button
                  className="cs-close_modal"
                  onClick={() => setModal(false)}
                >
                  <Icon icon="mdi:close-circle-outline" />
                </button>
                <div className="cs-modal_box_in">
                  <TradeDairyCrud
                    tradeDiaryTypes={tradeDiaryTypes}
                    typeLoading={typeLoading}
                    refreshTradeDiary={getTradeDiary}
                    updatedTradeDiary={updatedTradeDiary}
                    editTradeDairy
                    setFilterId={setFilterId}
                    closeModal={setModal}
                    title="Edit Your Trade Dairy "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal2 && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal2(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style3"
                style={{ maxWidth: '1030px' }}
              >
                <button
                  className="cs-close_modal"
                  onClick={() => setModal2(false)}
                >
                  <Icon icon="mdi:close-circle-outline" />
                </button>
                <div className="cs-modal_box_in">
                  <UsualTemplates
                    setUpdatedTradeDiary={setUpdatedTradeDiary}
                    setModal2={setModal2}
                    refresh={getTradeDiaryTemplate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
