import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  getMemberByCountry,
  getMonthlyEarningPerTier,
} from '../../api/services/dashboard';
import EmptyData from '../../components/Common/EmptyData';
import LoadingSpinner from '../../components/Common/LoadingSpinner';
import SubscribeTrendingGraph from '../../components/Dashboard/SubscribeTrendingGraph';
import ProgressBar from '../../components/ProgressBar';
import Spacing from '../../components/Spacing';
import Statistics from '../../components/Statistics';
import { v4 as uuidv4 } from 'uuid';
import RecentlySubscribed from '../../components/Dashboard/RecentlySubscribed';
import TierStatus from '../../components/Dashboard/TierStatus';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t } = useTranslation();
  const [countryMember, setCountryMember] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [tierStatus, setTierStatus] = useState({
    graph_title: ['', '', ''],
    graph_total_amount_percent: [0, 0, 0],
    graph_total_amount: [0, 0, 0],
  });
  // country member
  const getCountryMember = async () => {
    setCountryLoading(true);
    const res = await getMemberByCountry();
    setCountryMember(res.data.data);
    setCountryLoading(false);
  };
  const monthlyEarningPerTier = async () => {
    setStatusLoading(true);
    const res = await getMonthlyEarningPerTier();
    setTierStatus(res.data.data);
    setStatusLoading(false);
  };
  useEffect(() => {
    getCountryMember();
    monthlyEarningPerTier();
  }, []);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          <h2 style={{ fontSize: '24px' }}>
            {t('annual_subscriber_trending')}
          </h2>
          <ul className="chart_bulet cs-mp0">
            <li>
              <span className="color1" />
              {t('unsubscribed')}
            </li>
            <li>
              <span className="color2" />
              {t('subscribed')}
            </li>
          </ul>
          <Spacing lg="20" md="20" />
          <SubscribeTrendingGraph />
          <Spacing lg="25" md="25" />
          <h2 style={{ fontSize: '24px' }}>{t('monthly_earnings_per_tier')}</h2>
          <div
            className={`cs-progress_wrap ${
              statusLoading ? 'cs-loading_wrap' : ''
            }`}
          >
            {statusLoading && <LoadingSpinner />}
            <div className="cs-progress_left">
              {tierStatus.graph_title[0] && (
                <>
                  <div className="progress_item">
                    <h3>{tierStatus.graph_title[0]}</h3>
                    <ProgressBar
                      color="#64C680"
                      progress={tierStatus.graph_total_amount_percent[0]}
                    />
                    <span className="progress_value">
                      ${tierStatus.graph_total_amount[0]}
                    </span>
                  </div>
                  <Spacing lg="20" md="20" />
                </>
              )}
              {tierStatus.graph_title[1] && (
                <>
                  <div className="progress_item">
                    <h3>{tierStatus.graph_title[1]}</h3>
                    <ProgressBar
                      color="#DE6944"
                      progress={tierStatus.graph_total_amount_percent[1]}
                    />
                    <span className="progress_value">
                      ${tierStatus.graph_total_amount[1]}
                    </span>
                  </div>
                  <Spacing lg="20" md="20" />
                </>
              )}
              {tierStatus.graph_title[2] && (
                <div className="progress_item">
                  <h3>{tierStatus.graph_title[2]}</h3>
                  <ProgressBar
                    color="#4680C2"
                    progress={tierStatus.graph_total_amount_percent[2]}
                  />
                  <span className="progress_value">
                    ${tierStatus.graph_total_amount[2]}
                  </span>
                </div>
              )}
            </div>
            <div className="cs-progress_right">
              <TierStatus tierStatus={tierStatus}></TierStatus>
            </div>
          </div>
          <Spacing lg="25" md="25" />
          <div className="cs-text_center">
            <Link
              to="/settings/modify-membership-tiers"
              className="cs-text_btn cs-medium"
            >
              {t('view_all_plans')}
            </Link>
          </div>
          <Spacing lg="30" md="30" />
          <hr />
          <Spacing lg="30" md="30" />
          <div className="cs-list_1_wrap cs-type1">
            <h2 className="cs-semibold" style={{ fontSize: '20px' }}>
              {t('members_by_country')}
            </h2>
            <ul
              className={`cs-list cs-style1 cs-mp0 ${
                countryLoading ? 'cs-loading_wrap' : ''
              }`}
              style={{ maxHeight: '182px' }}
            >
              {countryLoading && (
                <>
                  <Spacing lg="50" md="50" />
                  <LoadingSpinner />
                </>
              )}
              {countryMember.map(item => (
                <li key={uuidv4()}>
                  <b>{item.country}</b>
                  <span style={{ color: '#9A9B9D' }}>
                    {item.count} {t('members')}
                  </span>
                </li>
              ))}
              {!countryMember.length && !countryLoading ? <EmptyData /> : null}
            </ul>
            <Spacing lg="10" md="10" />
            {/* <div className="cs-text_center">
              <button className="cs-list_btn">Load all</button>
            </div> */}
          </div>
        </div>
        <div className="cs-layout_right">
          <Statistics subscriber={502} unsubscriber={502} />
          <Spacing lg="20" md="20" />
          <RecentlySubscribed />
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
