import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import BroadcastsCrud from "../../../components/crud/BroadcastsCrud";
import { v4 as uuidv4 } from "uuid";
import {
  deleteBroadcast,
  getBroadcastList,
  updateBroadcastPin,
} from "../../../api/services/broadcast";
import { tagStringToArray } from "../../../utils/tagStringToArray";
import Swal from "sweetalert2";
import UserContext from "../../../context/user/user-context";
import moment from "moment/moment";
import PaginateButton from "../../../components/Common/PaginateButton";
import { defaultHasMoreCount } from "../../../config/const";
import EmptyData from "../../../components/Common/EmptyData";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import { useTranslation } from "react-i18next";

export default function Broadcasts() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [updatedBroadcast, setUpdateBroadcast] = useState({});
  let [searchParams] = useSearchParams();
  let { user } = useContext(UserContext);
  const [broadcasts, setBroadcast] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [pinnedBroadcast, setPinnedBroadcast] = useState([]);
  const [pinnedCount, setPinnedCount] = useState(0);
  const [pinnedLoading, setPinnedLoading] = useState(false);
  const [pinnedHasMore, setPinnedHasMore] = useState(true);
  const [pinnedPage, setPinnedPage] = useState(2);
  const [pinnedPaginateLoading, setPinnedPaginateLoading] = useState(false);
  const selectedTag = searchParams.get("tag") ?? "";
  const searchQuery = searchParams.get("search") ?? "";

  // get broadcast
  const getBroadcast = async () => {
    setLoading(true);
    const res = await getBroadcastList(1, 5, searchQuery, "", selectedTag);
    setBroadcast(tagStringToArray(res.data.data));
    setCount(res.data.count);
    setLoading(false);
  };

  // get pinned broadcast
  const getPinnedBroadcast = async () => {
    setPinnedLoading(true);
    const res = await getBroadcastList(1, 5, searchQuery, 1, selectedTag);
    setPinnedBroadcast(tagStringToArray(res.data.data));
    setPinnedCount(res.data.count);
    setPinnedLoading(false);
  };

  // handle delete
  const handleDelete = async (post_id) => {
    Swal.fire({
      title: t("are_you_sure"),
      text: t("you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes_delete_it"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await deleteBroadcast(post_id);
          if (res.data.success === 1) {
            Swal.fire(t("deleted"), t("your_post_has_been_deleted"), "success");
            getBroadcast();
            getPinnedBroadcast();
            setPage(2);
            setPinnedPage(2);
          }
          //   await deletePhysician(id);
          //   refresh();
        } catch (e) {}
      }
    });
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getBroadcastList(page, 5, searchQuery);
    setBroadcast([...broadcasts, ...tagStringToArray(res.data.data)]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // pinned paginate
  const pinnedPaginate = async () => {
    setPinnedPaginateLoading(true);
    const res = await getBroadcastList(page, 5, searchQuery, 1);
    setPinnedBroadcast([
      ...pinnedBroadcast,
      ...tagStringToArray(res.data.data),
    ]);
    setPinnedPaginateLoading(false);
    setPinnedPage(pinnedPage + 1);
    if (!res.data.data.length) {
      setPinnedHasMore(false);
    }
  };

  // handle update pin
  const updatePinPost = async (post_id, is_pinned) => {
    const pinType = is_pinned === 1 ? 0 : 1;
    const data = {
      post_id,
      is_pinned: pinType,
    };
    await updateBroadcastPin(data);
    getPinnedBroadcast();
    getBroadcast();
    page(2);
    pinnedPage(2);
  };

  useEffect(() => {
    getBroadcast();
    getPinnedBroadcast();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          {!selectedTag && !searchQuery ? (
            <>
              <BroadcastsCrud
                refresh={getBroadcast}
                title={t("new_broadcast")}
              />
              <Spacing lg="30" md="30" />
              <h2 style={{ fontSize: "24px", marginBottom: "15px" }}>
                {t("your_broadcast")}
              </h2>
            </>
          ) : (
            <>
              <h2 style={{ fontSize: "24px", marginBottom: "8px" }}>
                {selectedTag ? "Hashtag" : "Search"}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: "#8E8EA3" }}>
                {t("searching")} “{selectedTag ? selectedTag : searchQuery}”
              </p>
              <Spacing lg="10" md="10" />
            </>
          )}
          <Spacing lg="10" md="10" />

          <div className={`cs-feeds ${loading ? "cs-loading_wrap" : ""}`}>
            {loading && (
              <>
                <Spacing lg="40" md="40" />
                <LoadingSpinner />
              </>
            )}
            {broadcasts.map((item) => (
              <div className="cs-feed" key={uuidv4()}>
                <div className="cs-feed_img">
                  <img src={user.image} alt="User" />
                </div>
                <div className="cs-feed_right">
                  <h3 className="cs-feed_title cs-secondary_font">
                    <a>{user.name_show}</a>
                    <span className="cs-info_color cs-regular">
                      {moment(item.created_at).format("DD-MMM-Y")}
                    </span>
                    <span className="cs-info_color cs-regular">
                      {moment(item.created_at).format("LT")}
                    </span>
                  </h3>
                  <Spacing lg="5" md="5" />
                  <div className="cs-break-all">
                    {/* Be careful here:{' '}
                    <span className="cs-accent_color">$SNOW</span>{' '} */}
                    {item.description}
                  </div>
                  {item.image && <img src={item.image} alt="Feed" />}
                  <Spacing lg="15" md="15" />
                  <div className="cs-tags">
                    {item.tag.map((tag) => (
                      <Link
                        to={`/broadcasts?tag=${tag}`}
                        className={`cs-tag ${
                          selectedTag === tag ? "active" : ""
                        }`}
                        key={uuidv4()}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
                <DotOption
                  url={`/dashboard/${item.userId}/broadcasts/${item.id}`}
                >
                  <button
                    onClick={() => {
                      setModal(true);
                      setUpdateBroadcast(item);
                    }}
                  >
                    <Icon icon="material-symbols:edit" /> {t("edit")}
                  </button>
                  <button onClick={() => handleDelete(item.id)}>
                    <Icon icon="material-symbols:delete-rounded" />{" "}
                    {t("delete")}
                  </button>
                </DotOption>
                <button
                  className={`cs-icon_btn cs-pit_btn ${
                    item.is_pinned === 1 ? "active" : ""
                  }`}
                  onClick={() => updatePinPost(item.id, item.is_pinned)}
                >
                  <svg
                    width={9}
                    height={13}
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            ))}
            {!broadcasts.length && !loading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {count > defaultHasMoreCount && (
            <PaginateButton
              loading={paginateLoading}
              hasMore={hasMore}
              onClick={paginate}
            />
          )}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t("my_pin")}</h2>
          </div>
          <Spacing lg="20" md="20" />
          <div
            className={`cs-card_8_wrap ${
              pinnedLoading ? "cs-loading_wrap" : ""
            }`}
          >
            {pinnedLoading && (
              <>
                <Spacing lg="40" md="40" />
                <LoadingSpinner />
              </>
            )}
            {pinnedBroadcast.map((item) => (
              <div className="cs-card cs-style8 cs-type1" key={uuidv4()}>
                <ul className="cs-card_meta cs-mp0">
                  <li>
                    <span>{moment(item.created_at).format("DD-MMM-Y")}</span>
                  </li>
                  <li>
                    <span>{moment(item.created_at).format("LT")}</span>
                  </li>
                </ul>
                <div className="cs-card_desc cs-break-all">
                  {item.description}
                </div>
                {item.image && <img src={item.image} alt="Thumb" />}
                <div className="cs-tags">
                  <Link
                    to={`/broadcasts?tag=all`}
                    className={`cs-tag ${
                      selectedTag === "all" ? "active" : ""
                    }`}
                  >
                    {t("all")}
                  </Link>
                  {item.tag.map((tag) => (
                    <Link
                      to={`/broadcasts?tag=${tag}`}
                      className={`cs-tag ${
                        selectedTag === tag ? "active" : ""
                      }`}
                      key={uuidv4()}
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
                <DotOption
                  url={`/dashboard/${item.userId}/broadcasts/${item.id}`}
                >
                  <button
                    onClick={() => {
                      setModal(true);
                      setUpdateBroadcast(item);
                    }}
                  >
                    <Icon icon="material-symbols:edit" /> {t("edit")}
                  </button>
                  <button onClick={() => handleDelete(item.id)}>
                    <Icon icon="material-symbols:delete-rounded" />{" "}
                    {t("delete")}
                  </button>
                </DotOption>
                <button
                  className="cs-icon_btn cs-pit_btn active"
                  onClick={() => updatePinPost(item.id, item.is_pinned)}
                >
                  <svg
                    width={9}
                    height={13}
                    viewBox="0 0 9 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            ))}
            {!pinnedBroadcast.length && !pinnedLoading ? <EmptyData /> : null}
          </div>
          <Spacing lg="20" md="20" />
          {pinnedCount > defaultHasMoreCount && (
            <PaginateButton
              loading={pinnedPaginateLoading}
              hasMore={pinnedHasMore}
              onClick={pinnedPaginate}
            />
          )}
        </div>
      </div>
      <Spacing lg="40" md="40" />
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div className="cs-modal_box cs-style3">
                <div className="cs-modal_box_in">
                  <BroadcastsCrud
                    refresh={getBroadcast}
                    closePopUp={setModal}
                    setUpdateBroadcast={setUpdateBroadcast}
                    updatedBroadcast={updatedBroadcast}
                    title={t("edit_your_broadcast")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
