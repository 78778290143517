import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllCount } from '../../api/services/dashboard';
import UserContext from '../../context/user/user-context';

export default function Card3() {
  const { t } = useTranslation();
  const [count, setCount] = useState({});
  const { user } = useContext(UserContext);
  // get all count
  const getCount = async () => {
    const res = await getAllCount();
    setCount(res.data.data);
  };
  useEffect(() => {
    getCount();
  }, []);
  return (
    <div className="cs-card cs-style3">
      <div className="cs-card_img">
        <img src={user.image ?? '/images/user1.png'} alt="User" />
      </div>
      <div className="cs-card_right">
        <div className="cs-card_in">
          <div>
            <div className="cs-card_info">
              <h2
                className="cs-secondary_font cs-semibold"
                style={{ fontSize: '24px', marginBottom: '6px' }}
              >
                {user.name_show}
              </h2>
              <div className="cs-card_info_in">
                <span className="cs-card_label">{t('creator')}</span>
                <div className="cs-card_social">
                  {user.whatsapp && (
                    <a href={user.whatsapp} target="_blank">
                      <img src="/images/icon/whatsapp.png" alt="whatsapp" />
                    </a>
                  )}
                  {user.telegram && (
                    <a href={user.telegram} target="_blank">
                      <img src="/images/icon/telegram.png" alt="telegram" />
                    </a>
                  )}
                  {user.discord && (
                    <a href={user.discord} target="_blank">
                      <img src="/images/icon/radit.png" alt="radit" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="cs-card_meta">
            <div>
              <div className="">{t('members')}</div>
              <div
                className="cs-semibold cs-primary_color"
                style={{ fontSize: '24px', marginTop: '6px' }}
              >
                {count.member_count}
              </div>
            </div>
            <div>
              <div className="">{t('broadcast')}</div>
              <div
                className="cs-semibold cs-primary_color"
                style={{ fontSize: '24px', marginTop: '6px' }}
              >
                {count.broadcasts_count}
              </div>
            </div>
            <div>
              <div className="">{t('trade_diary')}</div>
              <div
                className="cs-semibold cs-primary_color"
                style={{ fontSize: '24px', marginTop: '6px' }}
              >
                {count.trade_dairy_count}
              </div>
            </div>
          </div>
        </div>
        <div className="cs-card_link">
          {`https://gorudo.io/creator-public-view/${user.name_show}`}
          <button
            className="cs-card_link_btn cs-accent_color"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://gorudo.io/creator-public-view/${user.name_show}`,
              );
            }}
          >
            <svg
              width={16}
              height={20}
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.875 0.375H3.35938C3.26484 0.375 3.1875 0.452344 3.1875 0.546875V1.75C3.1875 1.84453 3.26484 1.92188 3.35938 1.92188H14.0156V16.7031C14.0156 16.7977 14.093 16.875 14.1875 16.875H15.3906C15.4852 16.875 15.5625 16.7977 15.5625 16.7031V1.0625C15.5625 0.682227 15.2553 0.375 14.875 0.375ZM12.125 3.125H1.125C0.744727 3.125 0.4375 3.43223 0.4375 3.8125V15.2143C0.4375 15.3969 0.510547 15.5709 0.639453 15.6998L4.3627 19.423C4.40996 19.4703 4.46367 19.509 4.52168 19.5412V19.582H4.61191C4.68711 19.61 4.7666 19.625 4.84824 19.625H12.125C12.5053 19.625 12.8125 19.3178 12.8125 18.9375V3.8125C12.8125 3.43223 12.5053 3.125 12.125 3.125ZM4.51953 17.3949L2.66973 15.543H4.51953V17.3949ZM11.2656 18.0781H5.89453V15.0273C5.89453 14.5525 5.50996 14.168 5.03516 14.168H1.98438V4.67188H11.2656V18.0781Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
