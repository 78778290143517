import React, { useEffect, useRef, useState } from "react";
import DateRangeComp from "../../../components/DateRangeComp";
import DotOption from "../../../components/DotOption";
import Spacing from "../../../components/Spacing";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Icon } from "@iconify/react";
import moment from "moment";
import {
  createWatchlist,
  deleteWatchlist,
  getWatchlist,
} from "../../../api/services/watchlist";
import { dateConvart } from "../../../utils/dateConvart";
import { v4 as uuidv4 } from "uuid";
import LoadingSpinner from "../../../components/Common/LoadingSpinner";
import EmptyData from "../../../components/Common/EmptyData";
import EditModal from "./components/EditModal";
import { useSearchParams } from "react-router-dom";
import { defaultHasMoreCount } from "../../../config/const";
import PaginateButton from "../../../components/Common/PaginateButton";
import Swal from "sweetalert2";
import { subDays, addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import { useBeforeunload } from "react-beforeunload";

export default function Watchlists() {
  const { t } = useTranslation();
  // date state
  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: addDays(new Date(), 365),
      key: "selection",
    },
  ]);

  let [searchParams] = useSearchParams();
  const submitRef = useRef();
  const resetRef = useRef();
  const noteRef = useRef();
  const tickerBindSubmit = useRef();
  const [editedWatchlist, setEditedWatchlist] = useState({
    post_id: "",
    note: "",
    note_date: "",
    ticker: [],
  });
  const [value, onChange] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [tickerTitle, setTickerTitle] = useState("");
  const [dateError, setDateError] = useState("");
  const [noteError, setNoteError] = useState("");
  const [tickerError, setTickerError] = useState("");
  const [pubLoading, setPubLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [watchlist, setWatchlist] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const searchQuery = searchParams.get("search") ?? "";
  const [isChanged, setIsChanged] = useState(false);
  const [newWatchlist, setNewWatchList] = useState({
    note: "",
    note_date: "",
    ticker: [],
  });
  // handle add new
  const handleAddNew = (e) => {
    e.preventDefault();
    setNewWatchList({
      ...newWatchlist,
      ticker: [...newWatchlist.ticker, { title: tickerTitle }],
    });
    resetRef.current.click();
  };
  // remove ticker
  const removeTicker = (e) => {
    const removedWatchList = newWatchlist.ticker.filter((item) => item !== e);
    setNewWatchList({
      ...newWatchlist,
      ticker: [...removedWatchList],
    });
  };

  // set update ticker
  const setUpdateTicker = (index) => {
    let ticker = newWatchlist.ticker;
    ticker[index] = { isUpdate: true, title: ticker[index].title };
    setNewWatchList({ ...newWatchlist, ticker: ticker });
  };

  // bind update ticker
  const bindUpdateTicker = (e) => {
    e.preventDefault();
    let ticker = newWatchlist.ticker;
    ticker[e.target.elements.index.value] = {
      title: e.target.elements.title.value,
    };
    setNewWatchList({ ...newWatchlist, ticker: ticker });
  };

  const handlePublish = async () => {
    setModal(false);
    setPubLoading(true);
    const res = await createWatchlist({
      ...newWatchlist,
      note_date: dateConvart(newWatchlist.note_date),
    });
    setPubLoading(false);
    if (res.data.success === 1) {
      noteRef.current.value = "";
      setPage(2);
      setNewWatchList({
        note: "",
        note_date: "",
        ticker: [],
      });
      getAllWatchList();
      setModal(false);
    }
  };

  // get all watchlist
  const getAllWatchList = async () => {
    setLoading(true);
    const res = await getWatchlist(
      1,
      5,
      searchQuery,
      dateConvart(range[0].startDate),
      dateConvart(range[0].endDate)
    );
    setWatchlist(res.data.data);
    setCount(res.data.count);
    setLoading(false);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getWatchlist(
      page,
      5,
      searchQuery,
      dateConvart(range[0].startDate),
      dateConvart(range[0].endDate)
    );
    setWatchlist([...watchlist, ...res.data.data]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };
  // handle delete
  const handleDelete = async (post_id) => {
    Swal.fire({
      title: t("are_you_sure"),
      text: t("you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("yes_delete_it"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await deleteWatchlist(post_id);
          if (res.data.success === 1) {
            Swal.fire(t("deleted"), t("your_post_has_been_deleted"), "success");
            getAllWatchList();
            setPage(2);
          }
        } catch (e) {}
      }
    });
  };
  // handle browser close
  useBeforeunload((event) => {
    if (isChanged) {
      event.preventDefault();
    }
  });
  useEffect(() => {
    getAllWatchList();
  }, [searchParams, range]);

  // trach if state change or not
  useEffect(() => {
    setIsChanged(true);
  }, [newWatchlist]);

  return (
    <div className="cs-container_md" style={{ minHeight: "80vh" }}>
      <Spacing lg="40" md="40" />
      <div className="cs-section_heading_2">
        <div className="cs-section_left">
          <h2 className="cs-section_title">{t("your_current_watchlist")}</h2>
        </div>
        <div className="cs-section_right">
          <DateRangeComp range={range} setRange={setRange} />
        </div>
      </div>
      <Spacing lg="30" md="30" />
      <div className="cs-watchlist">
        <div className="cs-watchlist_left">
          {newWatchlist.note_date && (
            <>
              <div className="cs-add_date_heading">
                <div className="cs-add_date_in">
                  <span>{moment(newWatchlist.note_date).format("dddd")}</span>
                  <span>{moment(newWatchlist.note_date).format("D MMM")}</span>
                </div>
              </div>
              <Spacing lg="20" md="20" />
            </>
          )}
          <button
            className="cs-logout_btn cs-primary_font"
            style={{ borderRadius: "0" }}
            onClick={() => setModal("addData")}
          >
            + {t("add_date")}
          </button>
          {dateError && <p className="invalid-feedback">{dateError}</p>}
          <Spacing lg="25" md="25" />
        </div>
        <div className="cs-watchlist_right">
          <div className="cs-todo_input">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <label className="cs-todo_label">{t("creators_note")}</label>
              <div>
                {newWatchlist.note.length}/
                <span className="cs-accent_color">80</span>
              </div>
            </div>
            <div style={{ marginTop: "10px" }}>
              <input
                ref={noteRef}
                type="text"
                className="cs-form_field"
                placeholder={t("enter_note")}
                onChange={(e) =>
                  setNewWatchList({ ...newWatchlist, note: e.target.value })
                }
                maxLength={80}
              />
            </div>
          </div>
          {noteError && <p className="invalid-feedback">{noteError}</p>}
          <Spacing lg="25" md="25" />
          <ul className="cs-todo_list cs-mp0">
            {newWatchlist.ticker.map((item, index) => (
              <li key={index}>
                <div className="cs-todo">
                  {item.isUpdate ? (
                    <form onSubmit={bindUpdateTicker}>
                      <input
                        type="text"
                        name="title"
                        className="cs-form_field"
                        placeholder={t("write_title")}
                        value={item.title}
                        onChange={(e) => {
                          let ticker = newWatchlist.ticker;
                          ticker[index] = {
                            title: e.target.value,
                            isUpdate: true,
                          };
                          setNewWatchList({ ...newWatchlist, ticker: ticker });
                        }}
                        required
                      />
                      <input type="hidden" name="index" value={index} />
                      <button
                        ref={tickerBindSubmit}
                        type="submit"
                        style={{ display: "none" }}
                      ></button>
                    </form>
                  ) : (
                    <div className="cs-todo_title">{item.title}</div>
                  )}
                  {/* <div className="cs-todo_subtitle">
                    {t("posted_in")} {moment(new Date()).format("LT")}
                  </div> */}
                  <div className="cs-todo_btns">
                    {item.isUpdate ? (
                      <button
                        className="cs-success"
                        onClick={() => {
                          tickerBindSubmit.current.click();
                        }}
                      >
                        <Icon
                          icon="material-symbols:check"
                          width="18"
                          height="18"
                        />
                      </button>
                    ) : (
                      <button
                        className="cs-primary_color"
                        onClick={() => setUpdateTicker(index)}
                      >
                        <Icon icon="material-symbols:edit-square-outline-rounded" />
                      </button>
                    )}

                    <button
                      onClick={() => removeTicker(item)}
                      className="cs-danger"
                      style={{ marginTop: "2px" }}
                    >
                      <Icon icon="material-symbols:delete-outline" />
                    </button>
                  </div>
                </div>
              </li>
            ))}

            <li>
              <form onSubmit={handleAddNew} className="cs-todo">
                <input
                  type="text"
                  className="cs-form_field"
                  placeholder={t("write_title")}
                  onChange={(e) => setTickerTitle(e.target.value)}
                  required
                />
                {/* <div className="cs-todo_subtitle">
                  {t("posted_in")} {moment(new Date()).format("LT")}
                </div> */}
                <div className="cs-todo_btns"></div>
                <button
                  ref={submitRef}
                  type="submit"
                  style={{ display: "none" }}
                ></button>
                <input
                  type="reset"
                  ref={resetRef}
                  style={{ display: "none" }}
                ></input>
              </form>
            </li>
            <li>
              <button
                className="cs-todo_btn"
                onClick={() => submitRef.current.click()}
              >
                + {t("add_new")}
              </button>
            </li>
            <li>
              <button
                className={`cs-todo_btn cs-color1 ${
                  pubLoading ? "cs-loading_wrap" : ""
                }`}
                disabled={pubLoading}
                onClick={() => {
                  // creator note validation
                  if (!newWatchlist.note_date) {
                    setDateError("The date field is required");
                  } else {
                    setDateError("");
                  }
                  // watchlist date validate
                  // if (!newWatchlist.note) {
                  //   setNoteError("The creator note field is required");
                  // } else {
                  //   setNoteError("");
                  // }
                  // ticker validation
                  if (!newWatchlist.ticker.length) {
                    setTickerError("The ticker field is required");
                  } else {
                    setTickerError("");
                  }
                  // set publish modal
                  if (newWatchlist.ticker.length && newWatchlist.note_date)
                    setModal("publish");
                }}
              >
                {pubLoading && (
                  <span className="cs-btn_loading">
                    <Icon icon="la:spinner" />
                    {t("loading")}
                  </span>
                )}
                {t("publish")}
              </button>
            </li>
          </ul>
          {tickerError && <p className="invalid-feedback">{tickerError}</p>}
        </div>
      </div>
      <Spacing lg="25" md="25" />
      <div className={`${loading ? "cs-loading_wrap" : ""}`}>
        {loading && (
          <>
            <Spacing lg="50" md="50" />
            <LoadingSpinner />
          </>
        )}
        {watchlist.map((item) => (
          <div key={uuidv4()}>
            <div className="cs-watchlist">
              <div className="cs-watchlist_left">
                <div className="cs-add_date_heading">
                  <div className="cs-add_date_in">
                    <span>{moment(item.note_date).format("dddd")}</span>
                    <span>{moment(item.note_date).format("D MMM")}</span>
                  </div>
                  <DotOption
                    url={`/dashboard/${item.userId}/watchlists/${item.id}`}
                  >
                    <button
                      onClick={() => {
                        setEditedWatchlist({
                          post_id: item.id,
                          note: item.note,
                          note_date: item.note_date,
                          ticker: item.components,
                        });
                        setEditModal(true);
                      }}
                    >
                      <Icon icon="material-symbols:edit" /> {t("edit")}
                    </button>
                    <button onClick={() => handleDelete(item.id)}>
                      <Icon icon="material-symbols:delete-rounded" />{" "}
                      {t("delete")}
                    </button>
                  </DotOption>
                </div>
                <Spacing lg="25" md="25" />
              </div>
              <div className="cs-watchlist_right">
                <div className="cs-todo_input">
                  <label className="cs-todo_label">{t("creators_note")}</label>
                  <div className="cs-break-all" style={{ marginTop: "10px" }}>
                    {item.note}
                  </div>
                </div>
                <Spacing lg="25" md="25" />
                <ul className="cs-todo_list cs-mp0">
                  {item.components.map((ticker) => (
                    <li key={uuidv4()}>
                      <div className="cs-todo">
                        <div className="cs-todo_title">{ticker.title}</div>
                        {/* <div className="cs-todo_subtitle">
                          {t("posted_in")}{" "}
                          {moment(ticker.created_at).format("LT")}
                        </div> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Spacing lg="25" md="25" />
          </div>
        ))}
        {!watchlist.length && !loading ? <EmptyData /> : null}
      </div>
      <Spacing lg="20" md="20" />
      {count > defaultHasMoreCount && (
        <PaginateButton
          loading={paginateLoading}
          hasMore={hasMore}
          onClick={paginate}
        />
      )}
      <Spacing lg="25" md="25" />
      {modal === "addData" && (
        <div className="cs-modal" style={{ zIndex: "202" }}>
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style3"
                style={{ maxWidth: "410px" }}
              >
                <button
                  className="cs-close_modal"
                  onClick={() => setModal(false)}
                >
                  <Icon icon="mdi:close-circle-outline" />
                </button>
                <div className="cs-modal_box_in">
                  <h2 style={{ fontSize: "30px" }}>{t("add_date")}</h2>
                  <h3
                    className="cs-semibold cs-m0"
                    style={{ fontSize: "18px" }}
                  >
                    {t("choose_a_date")}
                  </h3>
                  <Spacing lg="30" md="20" />
                  <Calendar onChange={onChange} value={value} />
                  <Spacing lg="30" md="20" />
                  <div className="cs-text_center">
                    <button
                      className="cs-btn cs-primary_font cs-color1"
                      style={{ width: "100%", maxWidth: "200px" }}
                      onClick={() => {
                        setNewWatchList({ ...newWatchlist, note_date: value });
                        setModal(false);
                      }}
                    >
                      {t("add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal === "publish" && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style3"
                style={{ maxWidth: "350px" }}
              >
                <button
                  className="cs-close_modal"
                  onClick={() => setModal(false)}
                >
                  <Icon icon="mdi:close-circle-outline" />
                </button>
                <div className="cs-modal_box_in cs-text_center">
                  <Spacing lg="20" md="20" />
                  <h2
                    className="cs-medium"
                    style={{ fontSize: "16px", marginBottom: "5px" }}
                  >
                    {t("publish_watchlist")}
                  </h2>
                  <p style={{ fontSize: "12px", lineHeight: "1.6em" }}>
                    {t("members_will_receive_notification")}
                  </p>
                  <button
                    className="cs-btn"
                    style={{ padding: "7px 15px", margin: "4px" }}
                    onClick={() => setModal(false)}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    onClick={() => handlePublish()}
                    className="cs-btn cs-color1"
                    style={{ padding: "7px 15px", margin: "4px" }}
                  >
                    {t("confirm")}
                  </button>
                  <Spacing lg="10" md="10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {editModal && (
        <EditModal
          setModal={setModal}
          modal={modal}
          setEditModal={setEditModal}
          setEditedWatchlist={setEditedWatchlist}
          editedWatchlist={editedWatchlist}
          refresh={getAllWatchList}
          setPage={setPage}
        />
      )}
    </div>
  );
}
