import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationSC from "./locales/sc/translation.json";
import translationTC from "./locales/tc/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ja: {
    translation: translationJA,
  },
  sc: {
    translation: translationSC,
  },
  tc: {
    translation: translationTC,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("lang") ?? "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
