import React from 'react'
import { useState } from 'react';
import './videomodal.scss'

export default function VideoModal({videoSrc}) {
  const [iframeSrc, setIframeSrc] = useState('about:blank');
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    const video = videoSrc.split('?v=')[1].trim();
    setIframeSrc(`https://www.youtube.com/embed/${video}`)
    setToggle(!toggle)
  }
  const handelClose = () => {
    setIframeSrc('about:blank')
    setToggle(!toggle)
  }
  return (
    <>
      <span  className="cs-video_block" onClick={handelClick}>
        <svg width={48} height={42} viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M47.0418 36V6C47.0418 2.7 44.5766 0 41.5635 0H5.95482C2.94178 0 0.476562 2.7 0.476562 6V36C0.476562 39.3 2.94178 42 5.95482 42H41.5635C44.5766 42 47.0418 39.3 47.0418 36ZM16.9113 33V9L33.3461 21L16.9113 33Z" fill="white" />
        </svg>
      </span>
      <div className={toggle ? "cs-video_popup active" : "cs-video_popup"}>
        <div className="cs-video_popup_overlay" />
        <div className="cs-video_popup_content">
          <div className="cs-video_popup_layer" />
          <div className="cs-video_popup_container">
            <div className="cs-video_popup_align">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" src={iframeSrc} title='video modal' />
              </div>
            </div>
            <div className="cs-video_popup_close" onClick={handelClose} />
          </div>
        </div>
      </div>
    </>
  )
}
