import Spacing from '../../../../components/Spacing';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from 'react';
import { createTier } from '../../../../api/services/onboarding/membership-tiers';
import { useEffect } from 'react';
import { Icon } from '@iconify/react';
import TermsOfUse from '../../../../components/TermsOfUse';
import { useTranslation } from 'react-i18next';
export default function CreatePaidMembershipTier({
  setModal2,
  features,
  refresh,
}) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('usd');
  const formSchema = Yup.object().shape({
    title: Yup.string().required('The tier title field is required'),
    description: Yup.string().required(
      'The tier description field is required',
    ),
    invitation_code: Yup.string(),
    currency: Yup.string().required('The currency field is required'),
    member_qouta: Yup.string(),
    is_popular: Yup.string(),
    type: Yup.string(),
    price: Yup.number()
      .required()
      .min(5, 'The min tier price is 5 usd')
      .typeError('This tier price input is number only'),
    features: Yup.array().required('the feature field is required'),
    acceptTerms: Yup.bool().oneOf(
      [true],
      'Please Accept for subscription terms and condition',
    ),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  // handle publish
  const handlePublish = async data => {
    setLoading(true);
    delete data.acceptTerms;
    const res = await createTier(data);
    if (res.data.success === 1) {
      refresh();
      setModal2(false);
    } else {
      setErrorMessage(res.data.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    setValue('is_popular', 0);
    setValue('currency', 'usd');
    setValue('type', 1);
  }, []);
  return (
    <>
      <div className="cs-modal">
        <div
          className="cs-modal_overlay"
          onClick={() => setModal2(false)}
        ></div>
        <div className="cs-modal_in">
          <div className="cs-modal_container">
            <form
              onSubmit={handleSubmit(handlePublish)}
              className="cs-modal_box cs-style1"
            >
              <Spacing lg="40" md="40" />
              <div className="cs-modal_box_in">
                <h2
                  className="cs-m0"
                  style={{ fontSize: '21px', fontWeight: '600' }}
                >
                  {t('create_paid_membership_tier')}
                </h2>
                <Spacing lg="25" md="25" />
                <label>{t('onboarding_paid_membership_tier_input_1')} *</label>
                <input
                  type="text"
                  placeholder={t(
                    'onboarding_paid_membership_tier_input_placeholder_1',
                  )}
                  className="cs-form_field"
                  {...register('title')}
                />
                {errors.title?.message && (
                  <span className="invalid-feedback">
                    {errors.title?.message}
                  </span>
                )}

                <Spacing lg="20" md="20" />
                <label>{t('onboarding_paid_membership_tier_input_2')}</label>
                <input
                  type="number"
                  min={1}
                  max={50000}
                  placeholder={t(
                    'onboarding_paid_membership_tier_input_placeholder_2',
                  )}
                  className="cs-form_field"
                  {...register('member_qouta')}
                />
                <span className="invalid-feedback">
                  {errors.member_qouta?.message}
                </span>
                <Spacing lg="20" md="20" />
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <label>
                    {t('onboarding_paid_membership_tier_input_3')} *
                  </label>
                  <span>
                    {charCount}/<span className="cs-accent_color">200</span>
                  </span>
                </div>
                <textarea
                  cols="30"
                  rows="2"
                  maxLength="200"
                  className="cs-form_field"
                  placeholder={t(
                    'onboarding_paid_membership_tier_input_placeholder_3',
                  )}
                  {...register('description')}
                  onChange={e => {
                    setCharCount(e.target.value.length);
                  }}
                ></textarea>
                <span className="invalid-feedback">
                  {errors.description?.message}
                </span>
                <Spacing lg="20" md="20" />
                <h2
                  className="cs-semibold"
                  style={{ fontSize: '21px', marginBottom: '5px' }}
                >
                  {t('subscription_features')} *
                </h2>
                <h3 className="cs-semibold" style={{ fontSize: '13px' }}>
                  ({t('subscription_features_subtitle')})
                </h3>
                <div style={{ paddingLeft: '5%' }}>
                  {features.map(item => (
                    <div key={item.id}>
                      <div className="cs-check">
                        <input
                          id={item.features_name}
                          type="checkbox"
                          value={item.id}
                          {...register('features')}
                        />
                        <label htmlFor={item.features_name}>
                          {item.features_name}
                        </label>
                      </div>
                      <Spacing lg="10" md="10" />
                    </div>
                  ))}
                </div>
                {errors.features?.message ? (
                  <span className="invalid-feedback">
                    Select at least one feature
                  </span>
                ) : null}
                {/* <Spacing lg="20" md="20" />
                <label>Select Currency *</label>
                <div className="cs-with_info">
                  <select
                    name=""
                    id=""
                    className="cs-form_field"
                    {...register("currency")}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                  >
                    <option value="usd">USD</option>
                    <option value="hkd">HKD</option>
                  </select>
                </div> */}
                <Spacing lg="20" md="20" />
                <label>{t('onboarding_paid_membership_tier_input_4')} *</label>
                <div className="cs-with_info">
                  <input
                    type="text"
                    placeholder={t(
                      'onboarding_paid_membership_tier_input_placeholder_4',
                    )}
                    className="cs-form_field"
                    {...register('price')}
                  />
                  <div className="cs-info_text">
                    {/* <span style={{ textTransform: "uppercase" }}>
                      {selectedCurrency}
                    </span> */}
                    <span style={{ textTransform: 'uppercase' }}>USD</span>
                    <span className="cs-info_text_in">
                      <div className="cs-info_tooltip">2.9% Stripe Charge</div>
                    </span>
                  </div>
                </div>
                <p className="invalid-feedback">{errors.price?.message}</p>
                <Spacing lg="20" md="20" />
                <div className="cs-check">
                  <input type="checkbox" />
                  <label>{t('onboarding_paid_membership_tier_input_5')} </label>
                </div>
                <input
                  type="text"
                  placeholder={t(
                    'onboarding_paid_membership_tier_input_placeholder_5',
                  )}
                  className="cs-form_field"
                  {...register('invitation_code')}
                  style={{ marginLeft: '28px' }}
                />
                <Spacing lg="25" md="25" />
                <div className="cs-check">
                  <input type="checkbox" {...register('acceptTerms')} />
                  <label>
                    {t('accept_the')}{' '}
                    <a href="https://gorudo.io/terms-of-use" target="_blank">
                      {t('terms_of_use')}
                    </a>
                  </label>
                </div>
                <span className="invalid-feedback">
                  {errors.acceptTerms?.message}
                </span>
                <Spacing lg="15" md="15" />
                {errorMessage !== '' && (
                  <>
                    <span className="cs-danger">{errorMessage}</span>
                    <Spacing lg="15" md="15" />
                  </>
                )}
                <button
                  className={`cs-modal_btn cs-color1 ${
                    loading ? 'cs-loading_wrap' : ''
                  }`}
                  style={{ width: '100%', borderRadius: '5px' }}
                  disabled={loading}
                >
                  {loading && (
                    <span className="cs-btn_loading">
                      <Icon icon="la:spinner" />
                      {t('loading')}
                    </span>
                  )}
                  {t('next')}
                </button>
              </div>
              <Spacing lg="40" md="40" />
            </form>
          </div>
        </div>
      </div>
      {modal && (
        <div className="cs-modal">
          <div
            className="cs-modal_overlay"
            onClick={() => setModal(false)}
          ></div>
          <div className="cs-modal_in">
            <div className="cs-modal_container">
              <div
                className="cs-modal_box cs-style2"
                style={{ maxWidth: '750px' }}
              >
                <div
                  className="cs-modal_box_in"
                  style={{ textAlign: 'center' }}
                >
                  <button
                    className="cs-close_btn"
                    onClick={() => setModal(false)}
                  >
                    <Icon icon="ri:close-circle-fill" />
                  </button>
                  <TermsOfUse />
                  <Spacing lg="20" md="20" />
                  <button
                    className="cs-btn"
                    onClick={() => setModal(false)}
                    style={{ maxWidth: '300px', width: '100%' }}
                  >
                    {t('i_agree')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
