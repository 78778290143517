import { Icon } from '@iconify/react';
import moment from 'moment/moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteResource,
  getResource,
  updateResourcePin,
} from '../../../api/services/resource';
import EmptyData from '../../../components/Common/EmptyData';
import DotOption from '../../../components/DotOption';
import Spacing from '../../../components/Spacing';
import { removeHtml } from '../../../utils/removeHtml';
import { tagStringToArray } from '../../../utils/tagStringToArray';
import { textLimit } from '../../../utils/TextLimit';
import DraftResource from './Components/Draft';
import PublishedResource from './Components/Published';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../../../components/Common/LoadingSpinner';
import PaginateButton from '../../../components/Common/PaginateButton';
import { defaultHasMoreCount } from '../../../config/const';
import { useTranslation } from 'react-i18next';

export default function ResourcesFaq() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(0);
  let [searchParams] = useSearchParams();
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [paginateLoading, setPaginateLoading] = useState(false);
  const [count, setCount] = useState(0);
  const selectedTag = searchParams.get('tag') ?? '';
  const searchQuery = searchParams.get('search') ?? '';

  const handelTab = index => {
    setSelected(index);
  };
  // get newsletter
  const getAllResource = async () => {
    setLoading(true);
    const res = await getResource(1, 5, searchQuery, 0, 1, selectedTag);
    setLoading(false);
    setResources(tagStringToArray(res.data.data));
    setCount(res.data.count);
  };

  // paginate
  const paginate = async () => {
    setPaginateLoading(true);
    const res = await getResource(page, 5, searchQuery, 0, 1, selectedTag);
    setResources([...resources, ...tagStringToArray(res.data.data)]);
    setPaginateLoading(false);
    setPage(page + 1);
    if (!res.data.data.length) {
      setHasMore(false);
    }
  };

  // handle delete
  const handleDelete = async post_id => {
    Swal.fire({
      title: t('are_you_sure'),
      text: t('you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_delete_it'),
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const res = await deleteResource(post_id);
          if (res.data.success === 1) {
            Swal.fire(t('deleted'), t('your_post_has_been_deleted'), 'success');
            getAllResource();
            setPage(2);
          }
        } catch (e) {}
      }
    });
  };

  // handle update pin
  const updatePinPost = async (post_id, is_pinned) => {
    const pinType = is_pinned === 1 ? 0 : 1;
    const data = {
      post_id,
      is_pinned: pinType,
    };
    await updateResourcePin(data);
    getAllResource();
    setPage(2);
  };

  useEffect(() => {
    getAllResource();
  }, [searchParams]);
  return (
    <div className="cs-container_md">
      <Spacing lg="40" md="40" />
      <div className="cs-layout_1">
        <div className="cs-layout_left">
          {!selectedTag && !searchQuery ? (
            <>
              <h2 style={{ fontSize: '24px', marginBottom: '0px' }}>
                {t('post_your_resources')}
              </h2>
              <Spacing lg="30" md="30" />
              <Link to="/resources-faq/create" className="cs-btn cs-color1">
                {t('create')}
              </Link>
            </>
          ) : (
            <>
              <h2 style={{ fontSize: '24px', marginBottom: '8px' }}>
                {selectedTag ? 'Hashtag' : 'Search'}
              </h2>
              <p className="cs-m0 cs-light" style={{ color: '#8E8EA3' }}>
                {t('searching')} “{selectedTag ? selectedTag : searchQuery}”
              </p>
              <Spacing lg="10" md="10" />
            </>
          )}

          <Spacing lg="30" md="30" />
          <ul className="cs-secondary_nav">
            <li>
              <button
                className={selected === 0 ? 'active' : ''}
                onClick={() => handelTab(0)}
              >
                {t('published')}
              </button>
            </li>
            <li>
              <button
                className={selected === 1 ? 'active' : ''}
                onClick={() => handelTab(1)}
              >
                {t('drafts')}
              </button>
            </li>
          </ul>
          <Spacing lg="20" md="20" />
          {selected === 0 && <PublishedResource refreshPin={getAllResource} />}
          {selected === 1 && <DraftResource />}
        </div>
        <div className="cs-layout_right">
          <div className="cs-section_heading_4">
            <h2 className="cs-section_title">{t('my_pin')}</h2>
          </div>
          <Spacing lg="20" md="20" />
          <div className={`cs-card_8_wrap ${loading ? 'cs-loading_wrap' : ''}`}>
            {loading && (
              <>
                <Spacing lg="50" md="50" />
                <LoadingSpinner />
              </>
            )}
            {resources.map(item => (
              <div className="cs-card cs-style4 cs-type1" key={uuidv4()}>
                {item.image && (
                  <div className="cs-card_thumb">
                    <img style={{ width: '100%' }} src={item.image} alt="" />
                  </div>
                )}
                <div className="cs-card_info">
                  <div className="cs-csra_info">
                    <div className="cs-csra_date">
                      <span>{moment(item.created_at).format('LT')}</span>
                      <span>{moment(item.created_at).format('DD MMM Y')}</span>
                    </div>
                    <DotOption
                      url={`/dashboard/${item.userId}/resources-faq/${item.id}`}
                    >
                      <Link to={`/resources-faq/${item.id}/edit`}>
                        <Icon icon="material-symbols:edit" /> {t('edit')}
                      </Link>
                      <button onClick={() => handleDelete(item.id)}>
                        <Icon icon="material-symbols:delete-rounded" />{' '}
                        {t('delete')}
                      </button>
                    </DotOption>
                    <button
                      className="cs-icon_btn cs-pit_btn active"
                      onClick={() => updatePinPost(item.id, item.is_pinned)}
                    >
                      <svg
                        width={9}
                        height={13}
                        viewBox="0 0 9 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.121661 12.7023L4.50004 9.08404L8.87842 12.7023V1.33066C8.87842 0.759493 8.38859 0.296875 7.78382 0.296875H1.21626C0.611492 0.296875 0.121661 0.759493 0.121661 1.33066V12.7023Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                  <h3 className="cs-break-all">{item.title}</h3>
                  <p>
                    <p className="cs-break-all">
                      {textLimit(removeHtml(item.detail), 100)}
                    </p>
                  </p>
                  <Link
                    to={`/resources-faq/${item.id}`}
                    className="cs-coninue_reading"
                  >
                    {t('continue_reading')}
                  </Link>
                  <div className="cs-tags">
                    {item.tag.map(tag => (
                      <Link
                        to={`/resources-faq?tag=${tag}`}
                        className={`cs-tag ${
                          selectedTag === tag ? 'active' : ''
                        }`}
                        key={uuidv4()}
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {!resources.length && !loading ? <EmptyData /> : null}
          </div>
          {count > defaultHasMoreCount && (
            <PaginateButton
              loading={paginateLoading}
              hasMore={hasMore}
              onClick={paginate}
            />
          )}
        </div>
      </div>
      <Spacing lg="50" md="50" />
    </div>
  );
}
